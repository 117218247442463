/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import NoData from 'assets/no-data-icon.svg';

import { Container } from './styles';

function EmptyGrid() {
  return (
    <Container>
      {/* <Lottie
        autoplay
        options={{
          loop: true,
          autoplay: true,
          animationData: EmpryAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={130}
        width={130}
      /> */}
      <img
        src={NoData}
        style={{ height: 100, opacity: 0.4, marginBottom: 10 }}
      />
      Nenhum resultado
    </Container>
  );
}

export default EmptyGrid;
