export const formatStatus = (data) => {
  if (data === 'pending')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#f1893b' }}>◉</span> Aguardando
        vistoria
      </div>
    );
  if (data === 'awaiting_complementar')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#f1893b' }}>◉</span> Aguardando
        vistoria complementar
      </div>
    );
  if (data === 'started')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#34495e' }}>◉</span> Vistoria
        iniciada
      </div>
    );
  if (data === 'expired')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#e74c3c' }}>◉</span> Expirada
      </div>
    );
  if (data === 'canceled')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#e74c3c' }}>◉</span> Cancelada
      </div>
    );
  if (data === 'finished')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#4ca745' }}>◉</span> Vistoria
        finalizada
      </div>
    );
  if (data === 'awaiting_report')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#f39c12' }}>◉</span> Aguardando
        relatório
      </div>
    );
  if (data === 'activated')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#4ca745' }}>◉</span> Ativado
      </div>
    );
};

export const formatStatusLabel = (data) => {
  if (data === 'pending') return 'Aguardando vistoria';
  if (data === 'awaiting_complementar')
    return 'Aguardando vistoria complementar';
  if (data === 'started') return 'Vistoria iniciada';
  if (data === 'expired') return 'expired';
  if (data === 'canceled') return 'Cancelada';
  if (data === 'finished') return 'Vistoria finalizada';
  if (data === 'awaiting_report') return 'Aguardando relatório';
  if (data === 'activated') return 'Ativado';
};

export const formatComplementarStatus = (data) => {
  if (data === 'pending')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#f1893b' }}>◉</span> Aguardando
        vistoria
      </div>
    );
  if (data === 'started')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#34495e' }}>◉</span> Vistoria
        iniciada
      </div>
    );
  if (data === 'finished')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#4ca745' }}>◉</span> Vistoria
        finalizada
      </div>
    );
  if (data === 'awaiting_report')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#4ca745' }}>◉</span> Vistoria
        finalizada
      </div>
    );
  if (data === 'activated')
    return (
      <div>
        <span style={{ marginRight: 5, color: '#4ca745' }}>◉</span> Ativado
      </div>
    );
};

export const formatInviteStatus = (data) => {
  if (data === 'pending') return 'Pendente';
  if (data === 'canceled') return 'Cancelado';
  if (data === 'activated') return 'Ativado';
};

export const formatApplication = (data) => {
  if (data === 'whatsapp') return 'Whatsapp';
  if (data === 'webapp') return 'Web APP';
  if (data === 'mobileapp') return 'Mobile APP';
  if (data === 'telegram') return 'Telegram APP';
};

export const formatReportStatus = (data) => {
  if (!data || data === '') return;
  if (data === 'approved') return 'Aprovado';
  if (data === 'accepted') return 'Aprovado';
  if (data === 'rejected') return 'Rejeitado';
  if (data === 'pending') return 'Aguardando revisão';
};

export const formatInspectionStatus = (data) => {
  if (!data || data === 'pending')
    return { label: 'Aguardando vistoria', color: '#F3B412' };
  if (data === 'awaiting_complementar')
    return { label: 'Aguardando vistoria complementar', color: '#F3B412' };
  if (data === 'started') return { label: 'Iniciada', color: '#B6CC2E' };
  if (data === 'expired') return { label: 'Expirada', color: '#E74C3C' };
  if (data === 'canceled') return { label: 'Cancelada', color: '#E74C3C' };
  if (data === 'finished') return { label: 'Finalizada', color: '#2ECC71' };
  if (data === 'awaiting_report')
    return { label: 'Aguardando relatório', color: '#F3DD12' };
  if (data === 'activated') return { label: 'Ativada', color: '#2ECC71' };
};
