import React, { useContext } from 'react';
import { StyledButton } from './styles';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';

function Button({
  children,
  width,
  outlined,
  disabled,
  loading,
  danger,
  height,
  ...props
}) {
  const { secondary } = useContext(ThemeContext);

  return (
    <StyledButton
      danger={danger}
      disabled={loading || disabled}
      color={secondary}
      outlined={outlined}
      width={width}
      height={height}
      {...props}>
      {loading ? (
        <ReactLoading type="spin" color="#fff" height={25} width={25} />
      ) : (
        children
      )}
    </StyledButton>
  );
}

export default Button;
