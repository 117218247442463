/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import VideoThumbnail from 'react-video-thumbnail';
import api from 'api';

import {
  SideMenu,
  SectionTitle,
  Input,
  Button,
  Select,
  Switch,
  PdfPreviewer,
} from 'components';
import { Container, ContentArea, TitleArea, SwitchGroup } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';

function Create({ history }) {
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [data, setData] = useState();
  const [objectTypes, setObjectTypes] = useState();
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [photoTypes, setPhotoTypes] = useState();

  const { primary, loadingColor } = useContext(ThemeContext);

  const handleCreate = async () => {
    setLoadingCreate(true);
    try {
      const resp = await api.post('/inspection-type', {
        inspection_type: data?.inspectionType,
        id_object_type: data?.selectedObjectType,
        welcome_message: data?.welcomeMessage,
        conclusion_message: data?.conclusionMessage,
        photos: selectedPhotos.map((i) => i.id),
      });
      setLoadingCreate(false);
      history.push(`/tipos-vistorias/${resp?.data?.id}`);
      toast.success('Tipo de vistoria criado com sucesso!');
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const loadAllObjectTypes = async () => {
    setLoading(true);
    const resp = await api({
      method: 'post',
      url: '/all-object-types',
    });
    setObjectTypes(resp?.data);
    setLoading(false);
  };

  const loadAllPhotoTypes = async () => {
    setLoading(true);
    const resp = await api({
      method: 'post',
      url: '/all-photos',
      headers: {
        objecttype: data?.selectedObjectType,
      },
    });
    setPhotoTypes(resp?.data);
    setLoading(false);
  };

  const tooglePhoto = (photo) => {
    const list = [...selectedPhotos];

    if (list.findIndex((listItem) => listItem.id === photo?.id) > -1) {
      list.splice(
        list.findIndex((listItem) => listItem.id === photo?.id),
        1
      );

      setSelectedPhotos(list);
    } else {
      list.push(photo);
      setSelectedPhotos(list);
    }
  };

  useEffect(() => {
    loadAllObjectTypes();
  }, []);

  useEffect(() => {
    loadAllPhotoTypes();
  }, [data?.selectedObjectType]);

  return (
    <Container>
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <IconButton
            style={{ maerginRight: 5 }}
            onClick={() => history?.goBack()}>
            <BsArrowLeft size={16} />
          </IconButton>
          <h1>Tipo de vistoria</h1>
        </TitleArea>
        <section>
          <SectionTitle color={primary} title="Adicionar novo" />

          <div className="form">
            <Input
              height="35px"
              width="380px"
              label="Tipo de vistoria"
              value={data?.inspectionType}
              onChange={(e) =>
                setData({
                  ...data,
                  inspectionType: e.target.value.toUpperCase(),
                })
              }
            />

            <Select
              height="35px"
              width="380px"
              label="Tipo de objeto"
              disabled={loading}
              value={data?.selectedObjectType}
              onChange={(e) => {
                setSelectedPhotos([]);
                setData({
                  ...data,
                  selectedObjectType:
                    e.target.value === 'all' ? undefined : e.target.value,
                });
              }}>
              <option value="all">Selecione</option>
              {objectTypes?.map((objectType) => (
                <option value={objectType.id}>{objectType.object_type}</option>
              ))}
            </Select>

            {loading && data?.selectedObjectType && (
              <div>
                <ReactLoading
                  type="spin"
                  color={loadingColor}
                  height={25}
                  width={25}
                />
              </div>
            )}

            {!loading && data?.selectedObjectType && (
              <>
                <Input
                  height="35px"
                  width="380px"
                  label="Mensagem de boas-vindas"
                  value={data?.welcomeMessage}
                  onChange={(e) =>
                    setData({
                      ...data,
                      welcomeMessage: e.target.value,
                    })
                  }
                />

                <Input
                  height="35px"
                  width="380px"
                  label="Mensagem de conclusão"
                  value={data?.conclusionMessage}
                  onChange={(e) =>
                    setData({
                      ...data,
                      conclusionMessage: e.target.value,
                    })
                  }
                />

                <p style={{ fontSize: 12 }}>Mídias</p>
                <div>
                  {photoTypes?.map((photo) => (
                    <SwitchGroup width="380px">
                      {photo?.mediatype === 'picture' && (
                        <img
                          src={photo.picture}
                          style={{
                            height: 25,
                            width: 25,
                            borderRadius: 3,
                            objectFit: 'cover',
                            marginRight: 15,
                          }}
                        />
                      )}
                      {photo?.mediatype === 'video' && (
                        <div
                          style={{
                            height: 25,
                            width: 25,
                            objectFit: 'cover',
                            marginRight: 15,
                          }}>
                          <VideoThumbnail
                            videoUrl={photo.picture}
                            width={25}
                            height={25}
                          />
                        </div>
                      )}
                      {photo?.mediatype === 'pdf' && (
                        <div
                          style={{
                            height: 25,
                            width: 25,
                            borderRadius: 3,
                            objectFit: 'cover',
                            marginRight: 15,
                          }}>
                          <PdfPreviewer fileUrl={photo?.picture} />
                        </div>
                      )}
                      <p>{photo.description}</p>
                      <Switch
                        color="primary"
                        onClick={() => tooglePhoto(photo)}
                        selected={
                          selectedPhotos.findIndex(
                            (listItem) => listItem === photo?.id
                          ) > -1
                        }
                        checked={
                          selectedPhotos.findIndex(
                            (listItem) => listItem === photo?.id
                          ) > -1
                        }
                      />
                    </SwitchGroup>
                  ))}
                  {!photoTypes?.length && (
                    <small style={{ paddingBottom: 20 }}>
                      Nenhuma foto encontrada.
                    </small>
                  )}
                </div>
              </>
            )}

            <Button
              loading={loadingCreate}
              onClick={handleCreate}
              height="35px"
              width="380px"
              style={{ marginTop: 30 }}
              disabled={
                loadingCreate ||
                !data?.inspectionType ||
                !data?.selectedObjectType ||
                !selectedPhotos.length
              }>
              Adicionar
            </Button>
          </div>
        </section>
      </ContentArea>
    </Container>
  );
}

export default Create;
