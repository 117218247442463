import React, { useState, useContext } from 'react';
import Cookies from 'js-cookie';
import api from 'api';
import ThemeContext from 'contexts/ThemeContext';
import AuthContext from 'contexts/AuthContext';
import PermissionContext from 'contexts/PermissionContext';
import {
  Container,
  LeftBox,
  RightBox,
  Form,
  FormTitle,
  ForgotText,
  Logo,
  Divider,
} from './styles';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { socket } from 'services/socket';

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { primary, lightLogo } = useContext(ThemeContext);
  const { setToken } = useContext(AuthContext);
  const { loadUserPermissions } = useContext(PermissionContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post('/user/login', { email, password });
      const { token } = response?.data;
      Cookies.set('vistei-authtoken', token);
      setToken(token);
      setLoading(false);
      socket.emit('authToken', { token });
      loadUserPermissions();
      history.push('/dashboard');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  return (
    <Container>
      <RightBox>
        <Form>
          <FormTitle>Login</FormTitle>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="E-mail"
          />
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            password
            type="password"
            placeholder="Senha"
          />
          <Button
            disabled={loading || !email || !password}
            loading={loading}
            style={{ marginTop: 10 }}
            onClick={(e) => handleLogin(e)}>
            Entrar
          </Button>
          <Divider />
          <ForgotText href="/recuperar-senha">Esqueceu a senha?</ForgotText>
        </Form>
      </RightBox>
      <LeftBox color={primary}>
        <Logo src={lightLogo} />
      </LeftBox>
    </Container>
  );
}

export default Login;
