import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: ${theme.pageBackground};
  color: ${theme.pageTitle};
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${theme.scrollbarBackground};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbarColor};
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 20px;
  h1 {
    margin: 0px;
    color: ${theme.pageTitle};
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  label {
    font-size: 12px;
    color: #878787;
    width: 40%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 0px;
  min-width: 500px;

  select {
    margin-top: 15px;
  }
`;
