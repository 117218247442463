import React, { useContext } from 'react';
import { BaseAvatar, Picture, EditArea } from './styles';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';

function Button({ loading, content, editable, src, ...props }) {
  const { loadingColor } = useContext(ThemeContext);

  return (
    <BaseAvatar editable={editable} {...props}>
      {loading && (
        <ReactLoading type="spin" color={loadingColor} height={25} width={25} />
      )}
      {!loading && src && <Picture src={src} />}
      {!loading && !src && content}
      {!loading && editable && <EditArea>Editar</EditArea>}
    </BaseAvatar>
  );
}

export default Button;
