import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

// AUTH
import Login from './screens/Auth/Login';
import RecoverPassword from './screens/Auth/RecoverPassword';
import Register from './screens/Auth/Register';

// MODULES
import AccessProfilesList from './screens/Modules/AccessProfiles/List';
import AccessProfilesCreate from './screens/Modules/AccessProfiles/Create';
import AccessProfilesUpdate from './screens/Modules/AccessProfiles/Update';

import ChecklistsList from './screens/Modules/Checklists/List';
import ChecklistsCreate from './screens/Modules/Checklists/Create';
import ChecklistsUpdate from './screens/Modules/Checklists/Update';

import Company from './screens/Modules/Company';
import Dashboard from './screens/Modules/Dashboard';
import Developer from './screens/Modules/Developer';
import Financial from './screens/Modules/Financial';

import InspectionsList from './screens/Modules/Inspections/List';
import InspectionsCreate from './screens/Modules/Inspections/Create';
import InspectionsDetails from './screens/Modules/Inspections/Details';
import InspectionsReport from './screens/Modules/Inspections/Report';

import UsersList from './screens/Modules/Users/List';
import UsersUpdate from './screens/Modules/Users/Update';

import InvitesList from './screens/Modules/Invites/List';
import InvitesCreate from './screens/Modules/Invites/Create';

import ObjectTypesList from './screens/Modules/ObjectTypes/List';
import ObjectTypesCreate from './screens/Modules/ObjectTypes/Create';
import ObjectTypesUpdate from './screens/Modules/ObjectTypes/Update';

import MediaTypesList from './screens/Modules/MediaTypes/List';
import MediaTypesCreate from './screens/Modules/MediaTypes/Create';
import MediaTypesUpdate from './screens/Modules/MediaTypes/Update';

import InspectionTypesList from './screens/Modules/InspectionTypes/List';
import InspectionTypesCreate from './screens/Modules/InspectionTypes/Create';
import InspectionTypesUpdate from './screens/Modules/InspectionTypes/Update';

import SubcompaniesList from './screens/Modules/Subcompanies/List';
import SubcompaniesCreate from './screens/Modules/Subcompanies/Create';
import SubcompaniesUpdate from './screens/Modules/Subcompanies/Update';

import UserProfile from './screens/Modules/UserProfile';

import Settings from './screens/Modules/Settings';

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Cookies.get('vistei-authtoken') ? (
        <Redirect
          to={{ pathname: '/dashboard', state: { from: props.location } }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={(props) =>
        Cookies.get('vistei-authtoken') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          /> //mudar para '/'
        )
      }
    />
  </>
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* PUBLIC ROUTES */}
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/registrar" component={Register} />
      <PublicRoute exact path="/recuperar-senha" component={RecoverPassword} />

      {/* ACCESS PROFILES */}
      <PrivateRoute
        exact
        path="/perfis-de-acesso"
        component={AccessProfilesList}
      />
      <PrivateRoute
        exact
        path="/perfil-de-acesso/novo"
        component={AccessProfilesCreate}
      />
      <PrivateRoute
        exact
        path="/perfil-de-acesso/:id"
        component={AccessProfilesUpdate}
      />

      {/* CHECKLISTS */}
      <PrivateRoute exact path="/checklists" component={ChecklistsList} />
      <PrivateRoute exact path="/checklist/novo" component={ChecklistsCreate} />
      <PrivateRoute exact path="/checklist/:id" component={ChecklistsUpdate} />

      {/* COMPANY */}
      <PrivateRoute exact path="/minha-empresa" component={Company} />

      {/* DASHBOARD */}
      <PrivateRoute exact path="/dashboard" component={Dashboard} />

      {/* DEVELOPER */}
      <PrivateRoute exact path="/desenvolvedor" component={Developer} />

      {/* FINANCIAL */}
      <PrivateRoute exact path="/financeiro" component={Financial} />

      {/* INSPECTIONS */}
      <PrivateRoute exact path="/vistorias" component={InspectionsList} />
      <PrivateRoute
        exact
        path="/vistorias/nova"
        component={InspectionsCreate}
      />
      <PrivateRoute
        exact
        path="/vistoria/:code"
        component={InspectionsDetails}
      />
      <PrivateRoute
        exact
        path="/relatorio-vistoria/:code"
        component={InspectionsReport}
      />

      {/* USERS */}
      <PrivateRoute exact path="/usuarios" component={UsersList} />
      <PrivateRoute exact path="/usuarios/:id" component={UsersUpdate} />

      {/* INVITES */}
      <PrivateRoute exact path="/convites" component={InvitesList} />
      <PrivateRoute exact path="/convites/novo" component={InvitesCreate} />

      {/* OBJECT TYPES */}
      <PrivateRoute exact path="/tipos-objetos" component={ObjectTypesList} />
      <PrivateRoute
        exact
        path="/tipos-objetos/novo"
        component={ObjectTypesCreate}
      />
      <PrivateRoute
        exact
        path="/tipos-objetos/:id"
        component={ObjectTypesUpdate}
      />

      {/* MEDIA TYPES */}
      <PrivateRoute exact path="/tipos-midias" component={MediaTypesList} />
      <PrivateRoute
        exact
        path="/tipos-midias/novo"
        component={MediaTypesCreate}
      />
      <PrivateRoute
        exact
        path="/tipos-midias/:id"
        component={MediaTypesUpdate}
      />

      {/* INSPECTION TYPES */}
      <PrivateRoute
        exact
        path="/tipos-vistorias"
        component={InspectionTypesList}
      />
      <PrivateRoute
        exact
        path="/tipos-vistorias/novo"
        component={InspectionTypesCreate}
      />
      <PrivateRoute
        exact
        path="/tipos-vistorias/:id"
        component={InspectionTypesUpdate}
      />

      {/* SUBCOMPANIES */}
      <PrivateRoute
        exact
        path="/empresas-parceiras"
        component={SubcompaniesList}
      />
      <PrivateRoute
        exact
        path="/empresa-parceira/nova"
        component={SubcompaniesCreate}
      />
      <PrivateRoute
        exact
        path="/empresa-parceira/:id"
        component={SubcompaniesUpdate}
      />

      {/* USER PROFILE */}
      <PrivateRoute exact path="/meu-perfil" component={UserProfile} />

      {/* SETTINGS */}
      <PrivateRoute exact path="/configuracoes" component={Settings} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
