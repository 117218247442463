import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) =>
    props?.search && !props.darkSearch
      ? theme.inputSearchColor
      : theme.inputColor};
  border: none;
  outline: none;
  margin: 5px 0px 10px 0px;
  border: 0px solid ${theme.inputColor};
  padding-left: ${(props) => (props.search ? '5px' : '10px')};
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 15px;
  border-radius: ${(props) => props.radius || '5px'};
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width || '300px'};

  input {
    border: none;
    height: 85%;
    width: 80%;
    outline: none;
    background-color: transparent;
    transition: all ease-in-out 0.3s;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    &::placeholder {
      color: #b5bbc4;
    }
    &:focus {
      color: ${(props) => props.color};
    }
    &:disabled {
      opacity: 0.4;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    height: 90%;
    width: 10%;
    background-color: transparent;
    color: #b5bbc4;
  }
`;

export const StyledLabel = styled.p`
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${theme.inputLabel};
  margin: 0px;
  margin-left: 2px;
  margin-top: 20px;
  width: 100%;
  text-align: left;
`;
