import React from 'react';

function Checkbox({ label, labelStyle, style, onChange, checked, ...props }) {
  const id =
    Date.now().toString() + '-' + Math.random().toString().substring(2, 8);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        ...style,
      }}>
      <input
        id={id}
        type="checkbox"
        {...props}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} style={{ marginLeft: 5, ...labelStyle }}>
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
