/* eslint-disable jsx-a11y/alt-text */
import moment from 'moment';

export const dataColumns = [
  {
    name: '',
    selector: (row) => (
      <img
        style={{ height: 25, width: 25, borderRadius: 3, objectFit: 'cover' }}
        src={JSON.parse(row?.company_logo)?.iconLogo}
      />
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Nome da empresa',
    selector: (row) => row?.name?.toUpperCase(),
    width: '25%',
    sortable: true,
  },
  {
    name: 'Cores',
    selector: (row) => (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            height: 15,
            width: 15,
            borderRadius: 2,
            marginRight: 5,
            backgroundColor: JSON.parse(row?.company_theme)?.primary,
          }}
        />
        <div
          style={{
            height: 15,
            width: 15,
            borderRadius: 2,
            marginRight: 5,
            backgroundColor: JSON.parse(row?.company_theme)?.secondary,
          }}
        />
        <div
          style={{
            height: 15,
            width: 15,
            borderRadius: 2,
            marginRight: 5,
            backgroundColor: JSON.parse(row?.company_theme)?.loadingColor,
          }}
        />
      </div>
    ),
    width: '20%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '20%',
    sortable: true,
  },
];
