/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ReactStreetview from 'react-streetview';
import ThemeContext from 'contexts/ThemeContext';
import Viewer from 'react-viewer';
import Dialog from '@mui/material/Dialog';
import api from 'api';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@mui/material';

import {
  SideMenu,
  SectionTitle,
  Button,
  InfoText,
  ConfirmDialog,
  TextArea,
  Checkbox,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  InspectionImage,
  InspectionVideo,
  InspectionPdf,
  MediaReview,
  DialogArea,
} from './styles';
import {
  BsClipboardCheck,
  BsClipboardX,
  BsClipboardData,
  BsArrowLeft,
} from 'react-icons/bs';
import { RiRadarLine } from 'react-icons/ri';
import { formatStatusLabel } from 'utils/formatInspection';
import { toast } from 'react-toastify';
import { formatCPF, formatPlate } from 'react-data-formatter';
import { formatReportStatus } from 'utils/formatInspection';
import { TbZoomInAreaFilled } from 'react-icons/tb';

function Report({ history, location }) {
  const dataId = location.pathname.split('/relatorio-vistoria/')[1];
  const [loading, setLoading] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingComplementar, setLoadingComplementar] = useState(false);

  const [data, setData] = useState();
  const [imageViewer, setImageViewer] = useState();
  const [telemetryData, setTelemetryData] = useState();
  const [complementarPhotos, setComplementarPhotos] = useState([]);
  const [checklists, setChechlists] = useState([]);

  const [openFinishDialog, setOpenFinishDialog] = useState();
  const [openComplementar, setOpenComplementar] = useState(false);
  const [openDialog, setOpenDialog] = useState();
  const [agree, setAgree] = useState(false);

  const { primary, loadingColor } = useContext(ThemeContext);

  const hasComplementar =
    data?.inspectioPhotos?.filter((i) => i.complementar)?.length > 0;

  const allApproved = hasComplementar
    ? true
    : data?.inspectioPhotos?.every(
        (photo) => photo.photo_status === 'approved'
      );

  const hasPending = data?.inspectioPhotos?.some(
    (photo) => photo.photo_status === 'pending'
  );

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/inspection/${dataId}`);
    setData(resp?.data);
    setLoading(false);
  };

  const handleFinishInspection = async () => {
    setLoadingFinish(true);
    await api({
      method: 'put',
      url: `/force-finish-inspection/${data?.id}`,
    });
    setLoadingFinish(false);
    loadData();
  };

  const handleRequestComplementar = async () => {
    setLoadingComplementar(true);
    try {
      await api({
        method: 'post',
        url: '/complementar-inspection',
        data: {
          id_parent_inspection: data?.id,
          id_photos: complementarPhotos.map((i) => i?.id_photo),
          inspectionData: data,
        },
      });
      toast.success('Vistoria complementar solicitada com sucesso!');
      history.push(`/vistoria/${dataId}`);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const handleGenerateReport = async (type) => {
    setLoadingReport(true);

    if (type === 'approve') {
      try {
        await api.post(`/review-inspection`, {
          id: data?.id,
          status: 'accepted',
          inspectionData: data,
          note: data?.note,
        });
        toast.success(`Relatório gerado com sucesso!`);
        loadData();
        setLoadingReport(false);
      } catch (e) {
        setLoadingReport(false);
        toast.error(e?.response?.data?.error);
      }
    }

    if (type === 'reject') {
      try {
        await api.post(`/review-inspection`, {
          id: data?.id,
          status: 'rejected',
          inspectionData: data,
          note: data?.note,
        });
        toast.success(`Relatório gerado com sucesso!`);
        history.push(`/vistoria/${dataId}`);
        setLoadingReport(false);
      } catch (e) {
        setLoadingReport(false);
        toast.error(e?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const isSelected = (id, status) => {
    const photoIndex = data.inspectioPhotos.findIndex(
      (photo) => photo.id === id
    );
    return data.inspectioPhotos[photoIndex].photo_status === status;
  };

  const isChechlistCompleted = (photo, photoChecklist) => {
    const photo_checklist = photoChecklist?.map((i) => i.id);
    let response = [];

    for (const item of photo_checklist) {
      const hasItem = checklists.findIndex(
        (i) => i.id_photo === photo && i.id_checklist === item
      );

      if (hasItem > -1) {
        response.push(true);
      } else {
        response.push(false);
      }
    }

    return response.every((i) => i === true);
  };

  const tooglePhotos = (product) => {
    const list = [...complementarPhotos];

    if (list?.findIndex((listItem) => listItem.id === product?.id) > -1) {
      list?.splice(
        list?.findIndex((listItem) => listItem.id === product?.id),
        1
      );
      setComplementarPhotos(list);
    } else {
      list?.push(product);
      setComplementarPhotos(list);
    }
  };

  const toogleChecklist = (photo, checklist) => {
    const list = [...checklists];

    if (
      list?.findIndex(
        (listItem) =>
          listItem.id_photo === photo?.id &&
          listItem.id_checklist === checklist.id
      ) > -1
    ) {
      list?.splice(
        list?.findIndex(
          (listItem) =>
            listItem.id_photo === photo?.id &&
            listItem.id_checklist === checklist.id
        ),
        1
      );
      setChechlists(list);
    } else {
      list?.push({ id_photo: photo.id, id_checklist: checklist.id });
      setChechlists(list);
    }
  };

  const changePhotoStatus = (id, status) => {
    const photoList = data.inspectioPhotos;
    const photoIndex = photoList.findIndex((photo) => photo.id === id);
    photoList[photoIndex].photo_status = status;
    setData({ ...data, inspectioPhotos: photoList });
  };

  const changePhotoDescription = (id, description) => {
    const photoList = data.inspectioPhotos;
    const photoIndex = photoList.findIndex((photo) => photo.id === id);
    photoList[photoIndex].description = description;
    setData({ ...data, inspectioPhotos: photoList });
  };

  const renderFinishDialog = () => (
    <ConfirmDialog
      open={openFinishDialog}
      onCancel={() => setOpenFinishDialog()}
      content="Tem certeza de que deseja finalizar esta vistoria? Vistorias finalizadas manualmente não poderão receber mais fotos e poderão não salvar todas as informações necessárias."
      title="Finalizar vistoria?"
      loading={loadingFinish}
      onConfirm={() => {
        handleFinishInspection();
        setOpenFinishDialog();
      }}
    />
  );

  const renderTelemetry = () => (
    <Dialog
      maxWidth="md"
      onClose={() => setTelemetryData()}
      open={Boolean(telemetryData)}>
      <DialogArea>
        <h3>Telemetria</h3>
        <p>
          <span>IP da conexão:</span> {telemetryData?.metadata?.split('|')[0]}
        </p>
        <p>
          <span>Capturado por:</span> {telemetryData?.metadata?.split('|')[3]}
        </p>
        <p>
          <span>Data de captura:</span>{' '}
          {moment(telemetryData?.created_at)?.format('DD/MM/YYYY HH:mm:ss')}
        </p>
        <p>
          <span>Local da captura:</span>
        </p>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              width: '400px',
              height: '300px',
              margin: 20,
            }}>
            <ReactStreetview
              apiKey={'AIzaSyCah_ZBdSSl8jM6WFIvDfa47GoCaOeK2tM'}
              streetViewPanoramaOptions={{
                position: {
                  lat: parseFloat(
                    telemetryData?.metadata?.split('|')[2].split(', ')[0]
                  ),
                  lng: parseFloat(
                    telemetryData?.metadata?.split('|')[2].split(', ')[1]
                  ),
                },
                pov: { heading: 100, pitch: 0 },
                zoom: 1,
              }}
            />
          </div>
          <img
            src={telemetryData?.signedUrl}
            style={{
              width: '400px',
              height: '300px',
              marginBottom: 20,
              marginTop: 20,
            }}
          />
        </div>
      </DialogArea>
    </Dialog>
  );

  const renderComplementar = () => (
    <Dialog onClose={() => setOpenComplementar(false)} open={openComplementar}>
      <DialogArea>
        <h2>Vistoria complementar</h2>
        <p>Por favor, escolha as fotos para a vistoria complementar:</p>
        <div
          style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
          {data?.inspectioPhotos
            ?.filter((i) => !i.complementar)
            ?.map((photo) => (
              <Checkbox
                disabled={photo?.photo_status === 'approved'}
                onChange={() => tooglePhotos(photo)}
                checked={
                  complementarPhotos?.findIndex((i) => i.id === photo.id) > -1
                }
                label={`${photo?.photo?.name} (${formatReportStatus(
                  photo?.photo_status
                )})`}
              />
            ))}
        </div>
        <Button
          disabled={loadingComplementar || complementarPhotos?.length < 1}
          height="35px"
          style={{ marginTop: 20 }}
          onClick={handleRequestComplementar}>
          {loadingComplementar ? (
            <ReactLoading type="bars" color="#fff" height={25} width={25} />
          ) : (
            'Solicitar vistoria complementar'
          )}
        </Button>
      </DialogArea>
    </Dialog>
  );

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={Boolean(openDialog)}
      onCancel={() => setOpenDialog()}
      content={`Tem certeza de que deseja ${
        openDialog === 'approve' ? 'aprovar' : 'rejeitar'
      } a vistoria? Esta ação não poderá ser desfeita.`}
      title={`${openDialog === 'approve' ? 'Aprovar' : 'Rejeitar'} vistoria`}
      childComponent={
        openDialog === 'reject' && (
          <>
            <label>Observações:</label>
            <TextArea
              value={data?.note}
              onChange={(e) => setData({ ...data, note: e.target.value })}
              border></TextArea>
          </>
        )
      }
      onConfirm={() => {
        handleGenerateReport(openDialog);
        setOpenDialog();
      }}
    />
  );

  return (
    <Container>
      {renderFinishDialog()}
      {renderTelemetry()}
      {renderComplementar()}
      {renderConfirmDialog()}
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton
              style={{ maerginRight: 5 }}
              onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Relatório de vistoria ({dataId})</h1>
          </TitleArea>

          <section className="main">
            {/* ALERTA VISTORIA COMPLEMENTAR */}
            {hasComplementar && (
              <Alert style={{ marginBottom: 5 }} severity="warning">
                <b>Alerta!</b> Esta vistoria possui fotos de vistorias
                complementares!
              </Alert>
            )}

            {/* ALERTA DURAÇÃO VISTORIA */}
            {moment(data?.end_date).diff(data?.start_date, 'minutes') >
              2 * data?.inspectioPhotos?.length && (
              <Alert style={{ marginBottom: 5 }} severity="warning">
                <b>Atenção!</b> A vistoria demorou{' '}
                {moment(data?.end_date).diff(data?.start_date, 'minutes') -
                  2 * data?.inspectioPhotos?.length}{' '}
                minutos a mais que a média para ser realizada!
              </Alert>
            )}

            {/* ALERTA TROCA DISPOSITIVO */}
            {data?.end_device !== data?.start_device && (
              <Alert style={{ marginBottom: 5 }} severity="error">
                <b>Alerta!</b> Houve troca de dispositivos durante a vistoria!
              </Alert>
            )}

            {/* ALERTA DISPOSITIVO NÃO IDENTIFICADO */}
            {data?.end_device.includes('undefined') && (
              <Alert style={{ marginBottom: 5 }} severity="error">
                <b>Alerta!</b> Não foi possível identificar o dispositivo usado
                na vistoria!
              </Alert>
            )}

            {/* ALERTA COMPUTADOR */}
            {(data?.metadata.includes('Mac OS') ||
              data?.metadata.includes('Windows')) && (
              <Alert style={{ marginBottom: 5 }} severity="error">
                <b>Alerta!</b> As fotos de vistoria foram enviadas por um
                computador!
              </Alert>
            )}

            {/* ALERTA MUDANÇA LOCALIZAÇÃO */}
            {data?.end_location !== data?.start_location && (
              <Alert style={{ marginBottom: 5 }} severity="error">
                <b>Alerta!</b> A vistoria foi iniciada e finalizada em
                localizações diferentes!
              </Alert>
            )}

            <SectionTitle color={primary} title="Informações da vistoria" />

            <div className="info-area">
              <InfoText
                width="25%"
                label="Status"
                text={formatStatusLabel(data?.status)}
              />
              <InfoText
                width="25%"
                label="Nome do cliente"
                text={data?.client_name}
              />
              <InfoText
                width="25%"
                label="CPF do cliente"
                text={formatCPF(data?.client_cpf)}
              />
              <InfoText
                width="25%"
                label="Tipo de objeto"
                text={data?.objectType?.object_type}
              />
              <InfoText
                width="25%"
                label="Identificador"
                text={formatPlate(data?.identificator)}
              />
              <InfoText
                width="25%"
                label="Solicitada por"
                text={data?.author?.name}
              />
              <InfoText
                width="25%"
                label="Tipo de vistoria"
                text={data?.inspectionType?.inspection_type}
              />
              <InfoText
                width="25%"
                label="Data solicitação"
                text={moment(data?.created_at).format('DD/MM/YYYY HH:mm')}
              />
            </div>

            {(data?.status === 'started' ||
              data?.status === 'finished' ||
              data?.status === 'awaiting_report') && (
              <>
                <SectionTitle color={primary} title="Telemetria" />

                <div className="info-area">
                  <InfoText
                    width="25%"
                    label="Horário de início"
                    text={
                      data?.start_date
                        ? moment(data?.start_date).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não iniciada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Horário final"
                    text={
                      data?.end_date
                        ? moment(data?.end_date).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não finalizada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Duração da vistoria"
                    text={
                      data?.start_date
                        ? `${moment(data?.end_date).diff(
                            data?.start_date,
                            'minutes'
                          )} minutos`
                        : 'Não finalizada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Dispositivo utilizado"
                    text={data?.start_device || 'Não registrado'}
                  />
                </div>
              </>
            )}

            {data?.report && (
              <>
                <SectionTitle color={primary} title="Relatório de vistoria" />
                <div className="info-area">
                  <InfoText
                    width="25%"
                    label="Emitido por"
                    text={data?.report?.author?.name}
                  />

                  <InfoText
                    width="25%"
                    label="Emitido em"
                    text={moment(data?.report?.created_at).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                  />
                  <InfoText
                    width="25%"
                    label="Status da vistoria"
                    text={formatReportStatus(data?.report?.status)}
                  />
                  <InfoText
                    width="25%"
                    label="Observações"
                    text={data?.finish_notes || 'Nenhuma observação registrada'}
                  />
                </div>
              </>
            )}
          </section>

          {data?.status !== 'pending' && (
            <section>
              <SectionTitle color={primary} title="Revisar mídias" />

              {data?.inspectioPhotos?.map((photo) => (
                <MediaReview status={photo?.photo_status}>
                  <div className="media-area">
                    {/* PICTURE */}
                    {photo?.photo?.mediatype === 'picture' && (
                      <InspectionImage
                        status={photo?.photo_status}
                        onClick={() => setImageViewer(photo?.signedUrl)}
                        image={photo?.signedUrl}
                        alt="Inspection"
                      />
                    )}

                    {/* VIDEO */}
                    {photo?.photo?.mediatype === 'video' && (
                      <InspectionVideo>
                        <video
                          className="photo"
                          alt="Inspection"
                          controls
                          onClick={() => window.open(photo?.signedUrl)}
                          style={{ width: '100%', height: '100%' }}>
                          <source src={photo?.signedUrl}></source>
                        </video>
                      </InspectionVideo>
                    )}

                    {/* PDF */}
                    {photo?.photo?.mediatype === 'pdf' && (
                      <InspectionPdf
                        onClick={() => window.open(photo?.signedUrl)}>
                        <iframe src={photo?.signedUrl} />
                        <IconButton
                          style={{ position: 'absolute', zIndex: 9999 }}>
                          <TbZoomInAreaFilled color={'#858585'} />
                        </IconButton>
                      </InspectionPdf>
                    )}

                    {/* PICTURE_PDF */}
                    {/* TYPE: PICTURE */}
                    {photo?.photo?.mediatype === 'picture_pdf' &&
                      photo?.photo_url?.split('.')[1] !== 'pdf' && (
                        <InspectionImage
                          status={photo?.photo_status}
                          onClick={() => setImageViewer(photo?.signedUrl)}
                          image={photo?.signedUrl}
                          alt="Inspection"
                        />
                      )}
                    {/* TYPE: PDF */}
                    {photo?.photo?.mediatype === 'picture_pdf' &&
                      photo?.photo_url?.split('.')[1] === 'pdf' && (
                        <InspectionPdf
                          onClick={() => window.open(photo?.signedUrl)}>
                          <iframe src={photo?.signedUrl} />
                          <IconButton
                            style={{
                              position: 'absolute',
                              cursor: 'zoom-in',
                              right: 20,
                              bottom: 0,
                            }}>
                            <TbZoomInAreaFilled />
                          </IconButton>
                        </InspectionPdf>
                      )}

                    {/* Render image Viewer */}
                    <Viewer
                      visible={Boolean(imageViewer)}
                      drag={false}
                      noNavbar
                      noImgDetails
                      changeable={false}
                      showTotal={false}
                      onClose={() => {
                        setImageViewer();
                      }}
                      images={[{ src: imageViewer, alt: '' }]}
                    />
                  </div>

                  <div className="review-area">
                    {photo?.complementar && (
                      <span
                        title="Foto complementar"
                        style={{
                          fontSize: 12,
                          backgroundColor: '#f1c40f',
                          color: '#fff',
                          padding: '5px 7px',
                          borderLeft: '5px solid rgba(255, 255, 255,0.4)',
                        }}>
                        Mídia complementar
                      </span>
                    )}
                    <h1>{photo?.photo?.description}</h1>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        marginTop: 15,
                      }}>
                      Observações
                    </span>
                    <TextArea
                      color={primary}
                      height="80px"
                      disabled={data?.report}
                      value={photo?.description}
                      onChange={(e) =>
                        changePhotoDescription(photo.id, e.target.value)
                      }
                    />

                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 'bold',
                        marginTop: 15,
                        marginBottom: 15,
                      }}>
                      Checklist
                    </span>

                    {photo?.photo?.checklist?.map((item) => (
                      <Checkbox
                        label={item?.question}
                        labelStyle={{ fontSize: 12 }}
                        onChange={() => toogleChecklist(photo, item)}
                      />
                    ))}

                    {(!photo?.photo?.checklist ||
                      photo?.photo?.checklist?.length < 1) && (
                      <small>Nenhum item encontrado</small>
                    )}

                    <div className="review-options">
                      <Button
                        height="30px"
                        width="100px"
                        style={{ marginRight: 10 }}
                        active={isSelected(photo?.id, 'approved')}
                        status="approved"
                        color="#2ecc71"
                        disabled={
                          data?.report ||
                          !isChechlistCompleted(
                            photo.id,
                            photo?.photo?.checklist
                          )
                        }
                        onClick={() =>
                          changePhotoStatus(photo?.id, 'approved')
                        }>
                        <BsClipboardCheck
                          style={{ marginRight: 5 }}
                          size={16}
                        />{' '}
                        Aprovar
                      </Button>
                      <Button
                        height="30px"
                        width="100px"
                        active={isSelected(photo?.id, 'rejected')}
                        status="rejected"
                        color="#e74c3c"
                        style={{ marginRight: 10 }}
                        disabled={data?.report}
                        onClick={() =>
                          changePhotoStatus(photo?.id, 'rejected')
                        }>
                        <BsClipboardX style={{ marginRight: 5 }} size={16} />{' '}
                        Reprovar
                      </Button>
                      <Button
                        height="30px"
                        width="100px"
                        style={{ marginRight: 10 }}
                        onClick={() => setTelemetryData(photo)}>
                        <RiRadarLine style={{ marginRight: 5 }} size={16} />{' '}
                        Telemetria
                      </Button>
                    </div>
                  </div>
                </MediaReview>
              ))}

              {data?.inspectioPhotos?.length < 1 && (
                <p
                  style={{
                    marginLeft: 20,
                    color: '#868686',
                    fontSize: 13,
                    fontWeight: 300,
                  }}>
                  Nenhuma mídia enviada para esta vistoria
                </p>
              )}
            </section>
          )}

          {!data?.report && data?.status === 'awaiting_report' && (
            <section>
              <SectionTitle color={primary} title="Emitir relatório" />

              <div style={{ marginBottom: 20 }}>
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={agree}
                  onChange={() => setAgree(!agree)}
                  style={{ marginLeft: 20 }}
                />
                <label
                  style={{ fontSize: 12, color: '#121212' }}
                  htmlFor="scales">
                  Tenho certeza de que desejo finalizar esta vistoria. Esta ação
                  não poderá ser desfeita.
                </label>
              </div>
              <div className="button-area">
                <Button
                  height="35px"
                  width="200px"
                  style={{ marginRight: 10 }}
                  disabled={
                    loadingReport || !agree || !allApproved || hasPending
                  }
                  loading={loadingReport}
                  onClick={() => setOpenDialog('approve')}
                  color="#2ecc71">
                  <BsClipboardCheck style={{ marginRight: 5 }} size={16} />{' '}
                  Aprovar vistoria
                </Button>
                <Button
                  height="35px"
                  width="200px"
                  style={{ marginRight: 10 }}
                  disabled={loadingReport || !agree || hasPending}
                  onClick={() => setOpenDialog('reject')}
                  color="#e74c3c"
                  loading={loadingReport}>
                  <BsClipboardX style={{ marginRight: 5 }} size={16} /> Reprovar
                  vistoria
                </Button>
                <Button
                  height="35px"
                  width="200px"
                  style={{ marginRight: 10 }}
                  disabled={loadingReport || hasPending}
                  onClick={() => setOpenComplementar(true)}
                  color="#f1c40f"
                  loading={loadingReport}>
                  <BsClipboardData style={{ marginRight: 5 }} size={16} />{' '}
                  Vistoria complementar
                </Button>
              </div>
            </section>
          )}
        </ContentArea>
      )}
    </Container>
  );
}

export default Report;
