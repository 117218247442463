import styled from 'styled-components';

const itemColor = '#f2f5fa';
const textColor = '#707b8a';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${itemColor};
  border-radius: 5px;
  width: 100%;
  height: 50px;
  min-height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  }

  .icon-area {
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 5px;
    width: 10%;
    overflow-x: hidden;
    p {
      white-space: nowrap;
    }
  }

  .arrow-area {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
  }

  span {
    font-size: 9px;
    color: ${textColor};
  }

  p {
    font-size: 12px;
    margin: 0;
    color: ${textColor};
  }
`;

export const Icon = styled.div`
  border-radius: 3px;
  background-color: ${(props) => props.color || '#F39C12'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
`;
