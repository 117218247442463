import React, { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import { StyledButton } from './styles';
import { BiFilter } from 'react-icons/bi';
import { Tooltip } from '@material-ui/core';

function FilterButton({ ...props }) {
  const { primary } = useContext(ThemeContext);

  return (
    <Tooltip {...props} title="Aplicar filtros" placement="bottom">
      <StyledButton color={primary}>
        <BiFilter color="#878787" />
      </StyledButton>
    </Tooltip>
  );
}

export default FilterButton;
