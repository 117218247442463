import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props?.outlined ? 'transparent' : props?.danger ? '#e74c3c' : props.color};
  color: ${(props) => (props?.outlined ? props.color : '#fff')};
  border: ${(props) => (props?.outlined ? `1px solid ${props.color}` : 'none')};
  outline: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  font-size: 15px;
  border-radius: ${(props) => props.radius || '5px'};
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width || '300px'};

  &&:hover {
    opacity: ${(props) => (props?.outlined ? 0.5 : 0.8)};
  }

  &&:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
