/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
import api from 'api';

const UserContext = createContext(0);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const authToken = Cookies.get('vistei-authtoken');

  const loadUserData = async () => {
    const resp = await api('/me');
    setUserData(resp.data);
  };

  useEffect(() => {
    if (authToken) loadUserData();
  }, [authToken]);

  return (
    <UserContext.Provider value={{ userData, setUserData, loadUserData }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
