/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useRef, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import defaultPicture from 'assets/picture-icon.png';
import ManageChecklist from '../ManageChecklists';

import { Device } from 'react-mobile-device-frames';
import {
  SideMenu,
  SectionTitle,
  Input,
  Button,
  Select,
  PdfPreviewer,
} from 'components';

import {
  Container,
  ContentArea,
  TitleArea,
  ImagePreview,
  PhotoDescription,
  PhotoHelpText,
} from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';
import { BiHelpCircle } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import { saveFilesBucket } from 'utils/aws';
import { Tooltip } from '@material-ui/core';
import { formatCapture, formatMedia } from 'utils/formatMedia';

function Update({ history, location }) {
  const dataId = parseInt(location.pathname.split('/tipos-midias/')[1]);
  const [loading, setLoading] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [data, setData] = useState();
  const [objectTypes, setObjectTypes] = useState([]);
  const [picture, setPicture] = useState();
  const [inputRef, setInputRef] = useState();

  const [showManageChecklist, setShowManageChecklist] = useState(false);
  const [selectedChecklists, setSelectedChecklists] = useState([]);

  const { primary, loadingColor } = useContext(ThemeContext);

  const inputPicturRef = useRef();
  const inputVideoRef = useRef();
  const inputPdfRef = useRef();
  const inputPicturePdfRef = useRef();

  const loadData = async () => {
    setLoading(true);
    const resp = await api.post('/all-object-types');
    const respData = await api.get(`/photo/${dataId}`);
    setData({
      photoName: respData?.data?.name,
      photoDescription: respData?.data?.description,
      photoSlug: respData?.data?.slug,
      photoHelpText: respData?.data?.help_text,
      allowUpload: respData?.data?.allow_upload === 1,
      selectedMediaType: respData?.data?.mediatype,
      selectedObjectType: respData?.data?.id_object_type,
    });
    setPicture(respData?.data?.picture);
    setSelectedChecklists(respData?.data?.checklists);
    setObjectTypes(resp?.data);
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoadingUpdate(true);

    try {
      await api.put(`/photo/${dataId}`, {
        photo_type: {
          name: data?.photoName,
          description: data?.photoDescription,
          slug: data?.photoSlug,
          picture,
          help_text: data?.photoHelpText,
          object_type: data?.selectedObjectType,
          allow_upload: data?.allowUpload === 'true',
          mediatype: data?.selectedMediaType,
          checklists: selectedChecklists,
        },
      });

      setLoadingUpdate(false);
      toast.success('Alterações realizadas com sucesso!');
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleChangePhoto = async (img) => {
    setLoadingUpload(true);

    const ext = img?.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: 'vst-examples',
      Body: img,
      Key: `${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        try {
          setPicture(
            `https://vst-examples.s3.us-east-1.amazonaws.com/${data?.file}`
          );
          setLoadingUpload(false);
          return data?.file;
        } catch (e) {
          console.log(err);
          console.log(e);
          setLoadingUpload(false);
        }
      },
    });
  };

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setPicture(URL.createObjectURL(e.target.files[0]));
      handleChangePhoto(e.target.files[0]);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      {showManageChecklist && (
        <ManageChecklist
          open={showManageChecklist}
          onClose={() => setShowManageChecklist(false)}
          currentData={selectedChecklists}
          onFinish={(data) => {
            setSelectedChecklists(data);
            setShowManageChecklist(false);
          }}
        />
      )}
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <section className="main-area">
            <div className="left-area">
              <TitleArea>
                <IconButton
                  style={{ maerginRight: 5 }}
                  onClick={() => history?.goBack()}>
                  <BsArrowLeft size={16} />
                </IconButton>
                <h1>Tipo de mídia</h1>
              </TitleArea>
              <div>
                <SectionTitle color={primary} title=" Editar informações" />

                <div className="form">
                  <Input
                    label="Nome"
                    onFocus={() => setInputRef('photoName')}
                    height="35px"
                    width="380px"
                    value={data?.photoName}
                    onChange={(e) =>
                      setData({ ...data, photoName: e.target.value })
                    }
                  />

                  <Input
                    label="Descrição"
                    height="35px"
                    width="380px"
                    value={data?.photoDescription}
                    onFocus={() => setInputRef('photoDescription')}
                    onChange={(e) =>
                      setData({ ...data, photoDescription: e.target.value })
                    }
                  />

                  <Input
                    label="Prefixo"
                    helpText="O prefixo é um código curto usado para identificar facilmente as fotos da sua vistoria. Ex.: car-placa pode ser usado para fotos placas de automóveis."
                    height="35px"
                    width="380px"
                    value={data?.photoSlug}
                    onFocus={() => setInputRef('photoSlug')}
                    onChange={(e) =>
                      setData({ ...data, photoSlug: e.target.value })
                    }
                  />

                  <Input
                    label="Texto de ajuda"
                    height="35px"
                    width="380px"
                    onFocus={() => setInputRef('photoHelpText')}
                    value={data?.photoHelpText}
                    onChange={(e) =>
                      setData({ ...data, photoHelpText: e.target.value })
                    }
                  />

                  <Select
                    label="Tipo de mídia"
                    height="35px"
                    width="380px"
                    disabled={loading}
                    onFocus={() => setInputRef('selectedMediaType')}
                    value={data?.selectedMediaType}
                    onChange={(e) => {
                      if (
                        e.target.value === 'pdf' ||
                        e.target.value === 'picture_pdf'
                      ) {
                        setData({
                          ...data,
                          allowUpload: true,
                          selectedMediaType: e.target.value,
                        });
                      } else {
                        setData({ ...data, selectedMediaType: e.target.value });
                      }
                    }}>
                    <option>Selecione</option>
                    <option value="picture">Foto</option>
                    <option value="video">Vídeo</option>
                    <option value="pdf">PDF</option>
                    <option value="picture_pdf">Foto ou PDF</option>
                  </Select>

                  <Select
                    label="Tipo de objeto"
                    height="35px"
                    width="380px"
                    disabled={loading}
                    onFocus={() => setInputRef('selectedObjectType')}
                    value={data?.selectedObjectType}
                    onChange={(e) =>
                      setData({ ...data, selectedObjectType: e.target.value })
                    }>
                    <option>Selecione</option>
                    {objectTypes?.map((objectType) => (
                      <option value={objectType.id}>
                        {objectType.object_type}
                      </option>
                    ))}
                  </Select>

                  <Select
                    label="Permitir upload"
                    disabled={
                      data?.selectedMediaType === 'pdf' ||
                      data?.selectedMediaType === 'picture_pdf'
                    }
                    helpText="Permite que o vistoriador carregue esta foto da galeria do dispositivo."
                    height="35px"
                    width="380px"
                    onFocus={() => setInputRef('allowUpload')}
                    value={data?.allowUpload}
                    onChange={(e) =>
                      setData({ ...data, allowUpload: e.target.value })
                    }>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </Select>

                  <div className="custom-input">
                    <label>
                      Mídia de exemplo:
                      <Tooltip title="Tamanho recomendado: 750px x 450px">
                        <BiHelpCircle style={{ marginLeft: 5 }} />
                      </Tooltip>
                    </label>

                    {!picture && (
                      <Button
                        disabled={!data?.selectedMediaType}
                        outlined
                        style={{ marginTop: 10 }}
                        height="35px"
                        width="380px"
                        loading={loadingUpload}
                        onClick={(e) => {
                          e.preventDefault();

                          data?.selectedMediaType === 'picture' &&
                            inputPicturRef.current.click();

                          data?.selectedMediaType === 'video' &&
                            inputVideoRef.current.click();

                          data?.selectedMediaType === 'pdf' &&
                            inputPdfRef.current.click();

                          data?.selectedMediaType === 'picture_pdf' &&
                            inputPicturePdfRef.current.click();
                        }}>
                        Carregar mídia...
                      </Button>
                    )}

                    {picture && (
                      <ImagePreview width="380px">
                        {(data?.selectedMediaType === 'picture' ||
                          data?.selectedMediaType === 'picture_pdf') && (
                          <img src={picture} alt="" />
                        )}

                        {data?.selectedMediaType === 'video' && (
                          <video controls={false} autoPlay loop>
                            <source src={picture}></source>
                          </video>
                        )}

                        {data?.selectedMediaType === 'pdf' && (
                          <PdfPreviewer
                            fileUrl={picture}
                            width={55}
                            height={60}
                          />
                        )}

                        <div>
                          <p>Mídia de exemplo</p>
                          <small>{picture.slice(0, 40)}...</small>
                        </div>
                        <IconButton onClick={() => setPicture()}>
                          <GrClose size={14} />
                        </IconButton>
                      </ImagePreview>
                    )}

                    {/* HIDDEN INPUTS */}

                    <input
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      style={{ display: 'none' }}
                      ref={inputPicturRef}
                      onChange={handleImg}
                    />

                    <input
                      type="file"
                      accept="video/*"
                      style={{ display: 'none' }}
                      ref={inputVideoRef}
                      onChange={handleImg}
                    />

                    <input
                      type="file"
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      ref={inputPdfRef}
                      onChange={handleImg}
                    />

                    <input
                      type="file"
                      accept="image/*, application/pdf"
                      style={{ display: 'none' }}
                      ref={inputPicturePdfRef}
                      onChange={handleImg}
                    />
                  </div>

                  <div className="custom-input">
                    <label>Checklist:</label>

                    <div style={{ marginTop: 5, marginBottom: 10 }}>
                      <small className="checklist-info">
                        {(selectedChecklists === undefined ||
                          selectedChecklists?.length === 0) &&
                          'Nenhum cheklist selecionado.'}
                        {selectedChecklists?.length === 1 &&
                          `${selectedChecklists?.length} cheklist selecionado.`}
                        {selectedChecklists?.length > 1 &&
                          `${selectedChecklists?.length} cheklists selecionados.`}
                      </small>
                    </div>

                    <Button
                      outlined
                      height="35px"
                      width="380px"
                      onClick={() => setShowManageChecklist(true)}>
                      Gerenciar checklists
                    </Button>
                  </div>

                  <Button
                    style={{ marginTop: 30 }}
                    height="35px"
                    width="380px"
                    loading={loadingUpdate}
                    disabled={
                      loadingUpload ||
                      loadingUpdate ||
                      !picture ||
                      !data?.photoName ||
                      !data?.photoDescription ||
                      !data?.photoSlug ||
                      !data?.photoHelpText
                    }
                    onClick={handleUpdate}>
                    Salvar alterações
                  </Button>
                </div>
              </div>
            </div>

            <div className="right-area">
              <Device device="iphone-13-pro">
                <div className="mobile">
                  <PhotoDescription
                    active={inputRef === 'photoDescription'}
                    color={primary}>
                    {data?.photoDescription?.toUpperCase() || 'NOME DA MÍDIA'}
                  </PhotoDescription>

                  {data?.selectedMediaType === 'picture' && (
                    <img
                      src={picture || defaultPicture}
                      alt=""
                      style={{ marginTop: 25, marginBottom: 25, width: '60%' }}
                    />
                  )}

                  {data?.selectedMediaType === 'video' && (
                    <video
                      controls={false}
                      autoPlay
                      loop
                      style={{ height: 200, width: '80%' }}>
                      <source src={picture}></source>
                    </video>
                  )}

                  {data?.selectedMediaType === 'pdf' && (
                    <PdfPreviewer fileUrl={picture} width={160} height={200} />
                  )}
                  <PhotoHelpText
                    active={inputRef === 'photoHelpText'}
                    color={primary}>
                    {data?.photoHelpText || 'Texto de ajuda'}
                  </PhotoHelpText>
                  <Button width="80%" height="35px" style={{ marginTop: 25 }}>
                    {formatCapture(data?.allowUpload)}
                    {formatMedia(data?.selectedMediaType)}
                  </Button>
                </div>
              </Device>
              <small style={{ margin: 0, fontSize: 10 }}>
                Pré-visualização
              </small>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Update;
