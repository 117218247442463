import React from 'react';

import { StyledSectionTitle } from './styles';

function InfoText({ label, color, text, width, ...props }) {
  return (
    <StyledSectionTitle color={color} width={width} {...props}>
      <label>{label}</label>
      <span>{text}</span>
    </StyledSectionTitle>
  );
}

export default InfoText;
