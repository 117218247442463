/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';

import { SideMenu, SectionTitle, Input, Button, Select } from 'components';
import { Container, ContentArea, TitleArea } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';

function Create({ history }) {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [data, setData] = useState();

  const { primary, loadingColor } = useContext(ThemeContext);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    try {
      const resp = await api.post('/inspection/request', {
        id_inspection_type: data?.inspectionType,
        application: data?.application,
        identificator: data?.identificator,
        unique_identificator:
          data?.unique_identificator ||
          `${data?.clientCpf.replace(/[^\d]+/g, '')}/${new Date().getTime()}`,
        client_name: data?.clientName,
        client_cpf: data?.clientCpf.replace(/[^\d]+/g, ''),
        client_phone: data?.clientPhone.replace(/[^\d]+/g, ''),
      });
      setLoadingSubmit(false);
      toast.success('Vistoria solicitada com sucesso!');
      history.push(`/vistoria/${resp?.data?.inspection_code}`);
    } catch (e) {
      setLoadingSubmit(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const loadData = async () => {
    setLoading(true);
    const resp = await api.post('/all-inspection-types');
    setInspectionTypes(resp?.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />

      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}

      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton
              style={{ maerginRight: 5 }}
              onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Solicitar vistoria</h1>
          </TitleArea>
          <section>
            <SectionTitle color={primary} title="Dados da vistoria" />

            <Select
              label="Tipo de vistoria"
              height="35px"
              value={data?.inspectionType}
              onChange={(e) =>
                setData({
                  ...data,
                  inspectionType: e.target.value,
                })
              }>
              <option value="all">Selecione</option>
              {inspectionTypes?.map((item) => (
                <option value={item.id}>
                  {item?.inspection_type} ({item?.object_type?.object_type})
                </option>
              ))}
            </Select>

            <Select
              label="Aplicação"
              height="35px"
              value={data?.application}
              onChange={(e) =>
                setData({
                  ...data,
                  application: e.target.value,
                })
              }>
              <option>Selecione</option>
              <option value="webapp">Web App</option>
            </Select>

            <Input
              label={
                inspectionTypes?.find(
                  (i) => i.id === parseInt(data?.inspectionType)
                )?.object_type?.identificator || 'Identificador'
              }
              height="35px"
              value={data?.identificator}
              onChange={(e) =>
                setData({
                  ...data,
                  identificator: e.target.value?.toUpperCase(),
                })
              }
            />

            <Input
              label="Identificador único"
              helpText="O identificador único é o código que referencia a vistoria ao seu sistema. Caso este campo não seja preenchido, será gerado um identificador único pelo sistema."
              height="35px"
              type="tel"
              value={data?.unique_identificator}
              onChange={(e) =>
                setData({
                  ...data,
                  unique_identificator: e.target.value,
                })
              }
            />

            <Input
              label="Nome do cliente"
              height="35px"
              value={data?.clientName}
              onChange={(e) =>
                setData({
                  ...data,
                  clientName: e.target.value?.toUpperCase(),
                })
              }
            />

            <Input
              label="CPF do cliente"
              height="35px"
              type="tel"
              mask="999.999.999-99"
              value={data?.clientCpf}
              onChange={(e) =>
                setData({
                  ...data,
                  clientCpf: e.target.value,
                })
              }
            />

            <Input
              label="Telefone do cliente"
              height="35px"
              type="tel"
              mask="99 99999-9999"
              value={data?.clientPhone}
              onChange={(e) =>
                setData({
                  ...data,
                  clientPhone: e.target.value,
                })
              }
            />

            <Button
              loading={loadingSubmit}
              onClick={handleSubmit}
              height="35px"
              width="300px"
              style={{ marginTop: 30 }}
              disabled={
                loadingSubmit ||
                !data?.inspectionType ||
                !data?.application ||
                !data?.identificator ||
                !data?.clientName ||
                !data?.clientCpf ||
                !data?.clientPhone
              }>
              Adicionar
            </Button>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Create;
