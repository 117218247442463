import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: ${theme.pageBackground};
  color: ${theme.pageTitle};
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${theme.scrollbarBackground};
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbarColor};
    border-radius: 10px;
  }

  .widget-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .widget {
    background-color: ${theme.widgetBackground};
    padding: 10px 20px;
    margin: 10px;
    width: 100%;
  }

  .widget-mini {
    background-color: ${theme.widgetBackground};
    padding: 10px 20px;
    margin: 10px;
    width: 50%;
  }

  .home-icons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;

    .widget-line {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .widget-mini {
      width: 100%;
    }
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  color: ${theme.pageTitle};

  h1 {
    margin: 0px;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${theme.scrollbarBackground};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbarColor};
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const WidgetCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 20px;
  width: ${(props) => props.width || '50%'};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  transition: all ease-in-out 0.3s;
  background-color: ${theme.widgetBackground};
  margin: 10px;
  border-radius: 3px;

  .title {
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
    color: ${(props) => props.color};
  }

  .description {
    font-size: 14px;
    margin: 0px;
    color: #95a1ac;
    margin-top: 3px;
    width: 100%;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.iconBackground};
    color: ${theme.iconColor};
    height: 45px;
    width: 45px;
    min-height: 45px;
    min-width: 45px;
    border-radius: 5px;
    margin-right: 15px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ChartTooltip = styled.p`
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
`;

export const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 20px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  transition: all ease-in-out 0.3s;
  width: ${(props) => props.width || '20%'};
  background-color: #fff;
  margin: 10px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.4 : 0.6)};
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    margin: 0px;
    color: ${(props) => props.color};
  }

  .description {
    font-size: 12px;
    margin: 0px;
    color: #95a1ac;
    margin-top: 3px;
    width: 90%;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.iconBackground};
    color: ${theme.iconColor};
    height: 42px;
    width: 42px;
    min-height: 42px;
    min-width: 42px;
    border-radius: 5px;
    margin-right: 15px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
