/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import ThemeContext from 'contexts/ThemeContext';
import UserContext from 'contexts/UserContext';
import PermissionContext from 'contexts/PermissionContext';
import api from 'api';
import Skeleton from '@mui/material/Skeleton';

import { formatRoles } from 'utils/formatRoles';
import { Badge } from '@mui/material';
import { Avatar, Menu, Notifications, ConfirmDialog } from 'components';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Container,
  MenuHeader,
  MenuBody,
  MenuItem,
  MenuText,
  LogoImage,
  MenuFooter,
} from './styles';
import { RiMenuUnfoldLine, RiMenuFoldLine } from 'react-icons/ri';
import { BsClipboardCheckFill, BsFillEnvelopePaperFill } from 'react-icons/bs';
import { MdSpaceDashboard, MdHelpCenter } from 'react-icons/md';
import { HiUsers } from 'react-icons/hi';
import { ImExit } from 'react-icons/im';
import { IoIosSwitch } from 'react-icons/io';
import { IoNotificationsSharp } from 'react-icons/io5';
import { HiCodeBracketSquare } from 'react-icons/hi2';
import { useHistory, useLocation } from 'react-router-dom';

function SideMenu() {
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  const { iconLogo, lightLogo, primary } = useContext(ThemeContext);
  const { userData, setUserData } = useContext(UserContext);
  const { userPermissions } = useContext(PermissionContext);

  const location = useLocation();
  const history = useHistory();
  const path = location.pathname;

  const verifyPermission = (id) => {
    if (userPermissions) {
      const foundIndex = userPermissions?.findIndex((i) => i.id_feature === id);
      const permission = userPermissions[foundIndex];
      return Boolean(permission);
    }
  };

  const handleLogout = () => {
    Cookies.remove('vistei-authtoken');
    history.push('/login');
  };

  const loadData = async () => {
    setLoading(true);
    const resp = await api('/me');
    setUserData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    !userData && loadData();
  }, []);

  const menuOptions = [
    {
      label: 'Dashboard',
      url: '/dashboard',
      icon: <MdSpaceDashboard size={18} />,
      selectedCondition: 'dashboard',
      hasPermission: true,
    },
    {
      label: 'Vistorias',
      url: '/vistorias',
      icon: <BsClipboardCheckFill size={16} />,
      selectedCondition: 'vistoria',
      hasPermission: verifyPermission(2),
    },
    {
      label: 'Usuários',
      url: '/usuarios',
      icon: <HiUsers size={18} />,
      selectedCondition: 'usuario',
      hasPermission: verifyPermission(3),
    },
    {
      label: 'Convites',
      url: '/convites',
      icon: <BsFillEnvelopePaperFill size={15} />,
      selectedCondition: 'convites',
      hasPermission: verifyPermission(4),
    },
    {
      label: 'Configurações',
      url: '/configuracoes',
      icon: <IoIosSwitch size={18} />,
      selectedCondition: 'configuracoes',
      hasPermission: true,
    },
    {
      label: 'Desenvolvedor',
      url: '/desenvolvedor',
      icon: <HiCodeBracketSquare size={21} />,
      selectedCondition: 'desenvolvedor',
      hasPermission: verifyPermission(5),
    },
    {
      label: 'Ajuda',
      type: 'link',
      url: 'https://docs.vistei.app/',
      icon: <MdHelpCenter size={21} />,
      selectedCondition: 'ajuda',
      hasPermission: verifyPermission(6),
    },
  ];

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={Boolean(showConfirmLogout)}
      onCancel={() => setShowConfirmLogout()}
      content="Tem certeza de que deseja se deslogar do sistema? "
      title="Logout"
      onConfirm={() => {
        handleLogout();
      }}
    />
  );

  return (
    <>
      {renderConfirmDialog()}
      <Container collapsed={collapsed} color={primary}>
        <MenuHeader collapsed={collapsed}>
          <LogoImage
            src={iconLogo}
            collapsed={collapsed}
            variant="small"
            onClick={() => history.push('/dashboard')}
          />
          <LogoImage
            src={lightLogo}
            collapsed={collapsed}
            variant="fullClear"
            onClick={() => history.push('/dashboard')}
          />
          <Tooltip
            title={
              <>
                <p style={{ margin: 0, fontSize: 12 }}>{userData?.name}</p>
                <p style={{ margin: 0, fontSize: 10 }}>
                  {formatRoles(userData?.role)}
                </p>
              </>
            }
            placement="right"
            disableHoverListener={!collapsed}>
            <div
              className="menu-profile"
              onClick={() => history.push('/meu-perfil')}>
              {loading ? (
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  width={35}
                  height={35}
                />
              ) : (
                <Avatar
                  color={primary}
                  src={userData?.picture}
                  content={userData?.name?.slice(0, 2)}
                />
              )}

              {!collapsed && (
                <div className="menu-info">
                  {loading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={100}
                      height={15}
                      style={{ marginBottom: 5 }}
                    />
                  ) : (
                    <p className="menu-name">{userData?.name?.split(' ')[0]}</p>
                  )}
                  {loading ? (
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={100}
                      height={10}
                    />
                  ) : (
                    <p className="menu-role">{formatRoles(userData?.role)}</p>
                  )}
                </div>
              )}
            </div>
          </Tooltip>
        </MenuHeader>

        <MenuBody collapsed={collapsed}>
          {menuOptions?.map(
            (menu) =>
              menu.hasPermission && (
                <Tooltip
                  key={menu?.label}
                  title={menu?.label}
                  placement="right"
                  disableHoverListener={!collapsed}>
                  <MenuItem
                    onClick={() =>
                      menu?.type === 'link'
                        ? window.open(menu?.url)
                        : history.push(menu?.url)
                    }
                    selected={path.includes(menu?.selectedCondition)}
                    collapsed={collapsed}>
                    {menu?.icon}
                    <MenuText collapsed={collapsed}>{menu?.label}</MenuText>
                  </MenuItem>
                </Tooltip>
              )
          )}
        </MenuBody>

        <MenuFooter collapsed={collapsed}>
          <Tooltip
            title="Notificações"
            placement="right"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}>
            <IconButton className="menu-button">
              <Badge
                variant="dot"
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: 'lightgreen',
                  },
                }}
                invisible={true}>
                <IoNotificationsSharp size={18} color="#fff" />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Logout" placement="right">
            <IconButton
              className="menu-button"
              onClick={() => setShowConfirmLogout(true)}>
              <ImExit size={16} color="#fff" />
            </IconButton>
          </Tooltip>

          <Tooltip
            title={collapsed ? 'Mostrar menu' : 'Ocultar menu'}
            placement="right">
            <IconButton
              className="menu-button"
              onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <RiMenuUnfoldLine size={18} color="#fff" />
              ) : (
                <RiMenuFoldLine size={18} color="#fff" />
              )}
            </IconButton>
          </Tooltip>
        </MenuFooter>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ marginLeft: 50 }}>
          <Notifications />
        </Menu>
      </Container>
    </>
  );
}

export default SideMenu;
