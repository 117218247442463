/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import EmptyAnimation from 'assets/empty-notification.json';
import Lottie from 'react-lottie';
import moment from 'moment';

import { BsFillClipboardHeartFill } from 'react-icons/bs';

import {
  Container,
  NotificationBody,
  NotificationsBox,
  NotificationItem,
} from './styles';

function Notifications() {
  const [data] = useState([
    // {
    //   id: 1,
    //   category: 'inspection',
    //   message: 'Vistoria solicitada',
    //   url: '/vistoria',
    //   created_at: '2023-05-05 17:20:00',
    //   read: false,
    // },
    // {
    //   id: 1,
    //   category: 'inspection',
    //   message: 'Vistoria finalizada',
    //   url: '/vistoria',
    //   created_at: '2023-05-05 17:14:00',
    //   read: true,
    // },
  ]);

  return (
    <>
      <Container>
        <NotificationBody>
          {data?.length < 1 && (
            <>
              <Lottie
                autoplay
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: EmptyAnimation,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
                height={140}
                width={140}
              />
              <p>Nenhuma notificação não lida</p>
            </>
          )}
          {data?.length > 0 && (
            <NotificationsBox>
              <p style={{ fontSize: 14 }}>Notificações</p>
              {data?.map((item, index) => (
                <NotificationItem key={index} read={item?.read}>
                  <Badge
                    invisible={item?.read}
                    color="error"
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}>
                    <div className="icon">
                      <BsFillClipboardHeartFill size={20} />
                    </div>
                  </Badge>
                  <div className="info">
                    <p>{item?.message}</p>
                    <small>{moment(item?.created_at)?.fromNow()}</small>
                  </div>
                </NotificationItem>
              ))}
            </NotificationsBox>
          )}
        </NotificationBody>
      </Container>
    </>
  );
}

export default Notifications;
