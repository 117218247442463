/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';
import { toast } from 'react-toastify';
import { RxCopy } from 'react-icons/rx';
import { BsFillKeyFill } from 'react-icons/bs';
import {
  SideMenu,
  Input,
  Button,
  DataTable,
  ConfirmDialog,
  Select,
  SectionTitle,
} from 'components';
import { Container, ContentArea, TitleArea, DialogBody } from './styles';
import { formatWebhookEvent } from 'utils/formatEvents';
import { Dialog, DialogTitle } from '@mui/material';
import { dataColumns } from './helper';
import { TbTrashX } from 'react-icons/tb';
import { isValidUrl } from 'utils/validateUrl';

function Developer() {
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [apiKey, setApiKey] = useState();
  const [apiSecret, setApiSecret] = useState();

  const [showConfirm, setShowConfirm] = useState();
  const [showConfirmRemove, setShowConfirmRemove] = useState();
  const [showAddWebhook, setShowAddWebhook] = useState();

  const [selectedEvent, setSelectedEvent] = useState();
  const [currentEndpoint, setCurrentEndpoint] = useState();
  const [webhooks, setWebhooks] = useState([]);
  const { primary, loadingColor } = useContext(ThemeContext);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/company`);
    const webhooksResp = await api.get(`/webhooks`);

    setApiKey(resp?.data?.api_key);
    setApiSecret(resp?.data?.api_secret);
    setWebhooks(webhooksResp?.data);
    setLoading(false);
  };

  const handleNewCredentials = async () => {
    setLoadingUpdate(true);
    setShowConfirm();
    try {
      await api.put('/company/new-credentials');
      setLoadingUpdate(false);
      toast.success('Dados alterados com sucesso!');
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleAddWebhook = async () => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'POST',
        url: '/add-webhook',
        data: { event: selectedEvent, endpoint: currentEndpoint },
      });
      setLoadingUpdate(false);
      toast.success('Webhook adicionado com sucesso!');
      setShowAddWebhook();
      setSelectedEvent();
      setCurrentEndpoint();
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleRemoveWebhook = async (id) => {
    setLoadingUpdate(true);
    try {
      await api({
        method: 'POST',
        url: `/remove-webhook/${id}`,
        data: { event: selectedEvent, endpoint: currentEndpoint },
      });
      setLoadingUpdate(false);
      toast.success('Webhook excluído com sucesso!');
      setShowAddWebhook();
      setSelectedEvent();
      setCurrentEndpoint();
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error('Erro ao remover Webhook.Por favor, tente novamente.');
    }
  };

  const dataColumnsButton = [
    ...dataColumns,
    {
      name: '',
      selector: (row) => (
        <IconButton
          style={{ height: 35, width: 35, padding: 0 }}
          onClick={() => setShowConfirmRemove(row)}>
          <TbTrashX size={15} />
        </IconButton>
      ),
      width: '10%',
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={showConfirm}
      onCancel={() => setShowConfirm()}
      content="Tem certeza de que deseja gerar novas credenciais? As chaves API Key e API Secret atuais serão inativadas."
      title="Resetar credenciais?"
      onConfirm={() => {
        handleNewCredentials();
        setShowConfirm();
      }}
    />
  );

  const renderConfirmRemoveWebhookDialog = () => (
    <ConfirmDialog
      open={Boolean(showConfirmRemove)}
      onCancel={() => setShowConfirmRemove()}
      content={`Tem certeza de que deseja remover este webhook para ${formatWebhookEvent(
        showConfirmRemove?.event
      )}?`}
      title="Remover Webhook"
      onConfirm={() => {
        handleRemoveWebhook(showConfirmRemove.id);
        setShowConfirmRemove();
      }}
    />
  );

  const renderAddWebhook = () => (
    <Dialog open={showAddWebhook} onClose={() => setShowAddWebhook()}>
      <DialogTitle>Adicionar Webhook</DialogTitle>
      <DialogBody>
        <Select
          style={{ marginBottom: 15 }}
          label="Evento"
          height="35px"
          width="300px"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}>
          <option value="">Selecione um evento</option>
          <option value="request_inspection" disabled>
            Vistoria solicitada
          </option>
          <option value="request_complementar">
            Vistoria complementar solicitada
          </option>
          <option value="cancel_inspection" disabled>
            Vistoria cancelada
          </option>
          <option value="start_inspection" disabled>
            Vistoria iniciada
          </option>
          <option value="finish_inspection">Vistoria finalizada</option>
          <option value="finish_complementar">Vistoria complementar</option>
          <option value="approve_inspection">Vistoria aprovada</option>
          <option value="reject_inspection">Vistoria rejeitada</option>
        </Select>

        <Input
          label="Endpoint"
          placeholder="https://seuendereco.com/"
          height="35px"
          value={currentEndpoint}
          onChange={(e) => setCurrentEndpoint(e.target.value)}
        />
        {currentEndpoint && !isValidUrl(currentEndpoint) && (
          <small style={{ fontSize: 10, marginTop: 5, color: '#e74c3c' }}>
            Por favor, insira uma URL https válida
          </small>
        )}

        <Button
          height="35px"
          width="300px"
          style={{ marginTop: 15 }}
          onClick={handleAddWebhook}
          disabled={
            !currentEndpoint || !selectedEvent || !isValidUrl(currentEndpoint)
          }>
          {loadingUpdate ? (
            <ReactLoading type="spin" color="#fff" height={25} width={25} />
          ) : (
            'Adicionar'
          )}
        </Button>
      </DialogBody>
    </Dialog>
  );

  return (
    <Container>
      {renderConfirmDialog()}
      {renderConfirmRemoveWebhookDialog()}
      {renderAddWebhook()}
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <h1>Desenvolvedor</h1>
          </TitleArea>

          <section>
            <SectionTitle color={primary} title="Credenciais API" />
            <div className="form">
              <Button
                height="30px"
                width="200px"
                style={{ marginBottom: 25 }}
                onClick={() => setShowConfirm(true)}>
                Resetar credenciais
              </Button>
              <Input
                disabled
                password
                type="password"
                height="35px"
                width="380px"
                label="API Key"
                icon={<BsFillKeyFill />}
                iconButton={<RxCopy size={14} />}
                onIconButtonClick={() => {
                  navigator.clipboard.writeText(apiKey);
                  toast.success('Chave API Key copiada com sucesso!');
                }}
                value={apiKey}
              />
              <Input
                disabled
                password
                type="password"
                height="35px"
                width="380px"
                label="API Secret"
                icon={<BsFillKeyFill />}
                iconButton={<RxCopy size={14} />}
                onIconButtonClick={() => {
                  navigator.clipboard.writeText(apiSecret);
                  toast.success('Chave API Secret copiada com sucesso!');
                }}
                value={apiSecret}
              />
            </div>
          </section>

          <section style={{ marginTop: 35 }}>
            <SectionTitle color={primary} title="Webhooks" />
            <div className="form">
              <Button
                height="30px"
                width="200px"
                style={{ marginBottom: 25 }}
                onClick={() => setShowAddWebhook(true)}>
                Adicionar novo
              </Button>
              <DataTable
                persistTableHead
                responsive
                columns={dataColumnsButton}
                data={webhooks}
                progressPending={loading}
                progressComponent={
                  <div className="loading-area">
                    <ReactLoading
                      color={loadingColor}
                      height={32}
                      width={32}
                      type="spin"
                    />
                  </div>
                }
                noDataComponent={
                  <small style={{ marginTop: 20 }}>
                    Nenhuma vistoria encontrada
                  </small>
                }
              />
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Developer;
