import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : '25px')} !important;
  height: ${(props) =>
    props.height ? `${props.height}px` : '25px'} !important;
  cursor: ${(props) => (props.onClick ? 'zoon-in' : 'default')};
`;

export const Iframe = styled.iframe`
  cursor: ${(props) => (props.onClick ? 'zoon-in' : 'default')};
  border: none;
  border-radius: 3px;
  overflow: hidden !important;
`;
