import React, { useState, useContext } from 'react';
import { Container, Bullet } from './styles';
import ThemeContext from 'contexts/ThemeContext';
import InfoImg from 'assets/Info';

function TextArea({ items, onFinish, ...props }) {
  const { primary } = useContext(ThemeContext);
  const [currentStep, setCurrentStep] = useState(1);

  const currentItem = items[currentStep - 1];

  return (
    <Container {...props}>
      <div className="item">
        <InfoImg />
        <p>{currentItem?.text}</p>
      </div>

      <div className="bullets">
        {items?.map((item, index) => (
          <Bullet
            key={index}
            color={index + 1 === currentStep ? primary : '#eee'}
            onClick={() => setCurrentStep(index + 1)}
          />
        ))}
      </div>

      <div className="buttons">
        {currentStep === 1 && <p></p>}

        {currentStep > 1 && (
          <p onClick={() => setCurrentStep(currentStep - 1)}>Anterior</p>
        )}

        {currentStep < items.length && (
          <p onClick={() => setCurrentStep(currentStep + 1)}>Próximo</p>
        )}

        {currentStep === items.length && <p onClick={onFinish}>Finalizar</p>}
      </div>
    </Container>
  );
}

export default TextArea;
