import { formatWebhookEvent } from 'utils/formatEvents';

export const dataColumns = [
  {
    name: 'Evento',
    selector: (row) => formatWebhookEvent(row?.event),
    width: '20%',
    sortable: true,
  },

  {
    name: 'Endpoint',
    selector: (row) => row?.endpoint,
    sortable: true,
    width: '70%',
  },
];
