import moment from 'moment';
import { formatInspectionStatus } from 'utils/formatInspection';
import { toast } from 'react-toastify';
import { IoCopyOutline } from 'react-icons/io5';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Cód.',
    selector: (row) => (
      <p
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          navigator.clipboard.writeText(row?.inspection_code);
          toast.success('Código da vistoria copiado com sucesso!');
        }}>
        <IoCopyOutline size={10} style={{ marginRight: 5 }} />
        {row?.inspection_code}
      </p>
    ),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Cliente',
    selector: (row) => row?.client_name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Identificador',
    selector: (row) => row?.identificator?.toUpperCase(),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Autor',
    selector: (row) => row?.author?.name,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Empresa parceira',
    selector: (row) => row?.subcompany?.name || '-',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Tipo de vistoria',
    selector: (row) => row?.inspection?.inspection_type,
    width: '10%',
    sortable: true,
  },

  {
    name: 'Status',
    selector: (row) => formatInspectionStatus(row?.status)?.label,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data solicitação',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data vistoria',
    selector: (row) =>
      row?.end_date ? moment(row?.end_date).format('DD/MM/YYYY HH:mm') : '-',
    width: '10%',
    sortable: true,
  },
];
