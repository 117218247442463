/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import ReactLoading from 'react-loading';
import Dialog from '@mui/material/Dialog';
import api from 'api';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import { Input, Button } from 'components';
import { DialogBody, LoadingArea, ChecklistItem } from './styles';
import { BsCircle, BsCheckCircleFill, BsPlusLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

function Create({ open, onClose, onFinish, currentData }) {
  const { loadingColor } = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [dataList, setDataList] = useState();

  const [createChecklist, setCreateChecklist] = useState('');

  const [selectedChecklists, setSelectedChecklists] = useState(
    currentData || []
  );

  const [hasNextPage, setHasNextPage] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState();

  const loadData = async () => {
    setLoading(true);
    const resp = await api.post(
      '/checklists',
      { page: currentPage, take: 10 },
      {
        headers: {
          search,
        },
      }
    );
    setDataList(resp?.data?.response);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage) {
      setLoadingMore(true);

      const resp = await api.post(
        '/checklists',
        { page, take: 10 },
        {
          headers: {
            search,
          },
        }
      );

      const data = dataList;
      const ndata = data.concat(resp?.data?.response);

      setCurrentPage(page);
      setDataList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleCreate = async () => {
    setLoadingCreate(true);
    const currentList = dataList;
    const currentSelectedList = selectedChecklists;

    try {
      const resp = await api.post('/checklist', {
        question: createChecklist,
      });
      currentList.unshift(resp.data);
      currentSelectedList.push(resp.data);
      setDataList(currentList);
      setSelectedChecklists(currentSelectedList);
      setCreateChecklist('');
      setLoadingCreate(false);
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const toogleItems = (product) => {
    const list = [...selectedChecklists];

    if (list?.findIndex((listItem) => listItem.id === product?.id) > -1) {
      list?.splice(
        list?.findIndex((listItem) => listItem.id === product?.id),
        1
      );
      setSelectedChecklists(list);
    } else {
      list?.push(product);
      setSelectedChecklists(list);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogBody>
          <div className="title-area">
            <h1 className="title">Selecionar Checklists</h1>
          </div>

          <div className="search-area">
            <Input
              search
              darkSearch
              handleClean={() => {
                setSearch();
                loadData();
              }}
              height="35px"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadData();
                }
                if (e.key === 'Escape') {
                  setSearch();
                  loadData();
                }
              }}
            />

            <small>ou</small>

            <Input
              placeholder="Adicionar novo"
              height="35px"
              value={createChecklist}
              onChange={(e) => setCreateChecklist(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && createChecklist?.length > 0) {
                  handleCreate();
                }
              }}
            />

            {!loadingCreate && (
              <IconButton
                onClick={handleCreate}
                size="small"
                style={{ marginLeft: 10 }}
                disabled={!createChecklist}>
                <BsPlusLg />
              </IconButton>
            )}

            {loadingCreate && (
              <div style={{ marginLeft: 5 }}>
                <ReactLoading
                  type="spin"
                  color={loadingColor}
                  height={18}
                  width={18}
                />
              </div>
            )}
          </div>

          <>
            {loading && (
              <LoadingArea>
                <ReactLoading
                  type="spin"
                  color={loadingColor}
                  height={30}
                  width={30}
                />
              </LoadingArea>
            )}

            {!loading && (
              <div className="checklist-area">
                {/* SELECTED ITEMS */}
                {selectedChecklists?.map((item) => (
                  <ChecklistItem
                    clickable
                    onClick={() => toogleItems(item)}
                    selected={
                      selectedChecklists?.findIndex((i) => i.id === item.id) >
                      -1
                    }>
                    {selectedChecklists?.findIndex((i) => i.id === item.id) ===
                      -1 && <BsCircle style={{ marginRight: 10 }} />}
                    {selectedChecklists?.findIndex((i) => i.id === item.id) >
                      -1 && (
                      <BsCheckCircleFill
                        style={{ marginRight: 10 }}
                        color="#2ecc71"
                      />
                    )}
                    {item?.question}
                  </ChecklistItem>
                ))}

                {/* LIST ITEMS */}
                {dataList
                  ?.filter(
                    (i) => !selectedChecklists.some((c) => c.id === i.id)
                  )
                  ?.map((item) => (
                    <ChecklistItem
                      clickable
                      onClick={() => toogleItems(item)}
                      selected={
                        selectedChecklists?.findIndex((i) => i.id === item.id) >
                        -1
                      }>
                      {selectedChecklists?.findIndex(
                        (i) => i.id === item.id
                      ) === -1 && <BsCircle style={{ marginRight: 10 }} />}
                      {selectedChecklists?.findIndex((i) => i.id === item.id) >
                        -1 && (
                        <BsCheckCircleFill
                          style={{ marginRight: 10 }}
                          color="#2ecc71"
                        />
                      )}

                      {item?.question}
                    </ChecklistItem>
                  ))}

                {hasNextPage && (
                  <small
                    onClick={() => !loadingMore && handleLoadMore()}
                    className="load-more ">
                    {loadingMore ? (
                      <ReactLoading
                        type="spin"
                        color={loadingColor}
                        height={15}
                        width={15}
                      />
                    ) : (
                      'Carregar mais...'
                    )}
                  </small>
                )}
              </div>
            )}
          </>

          <div style={{ marginTop: 25 }}>
            <Button
              style={{ margin: 5 }}
              height={'35px'}
              disabled={loading}
              onClick={() => onFinish(selectedChecklists)}>
              Aplicar ({selectedChecklists?.length || 0})
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
}

export default Create;
