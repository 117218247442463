import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { StyledSectionTitle } from './styles';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { IoMdTrash } from 'react-icons/io';

function SectionTitle({ title, color, showDelete, onDelete, ...props }) {
  return (
    <StyledSectionTitle color={color} {...props}>
      <div>
        <RxDragHandleDots2
          color="#b8b8b8"
          size={12}
          style={{ marginRight: 2 }}
        />{' '}
        {title}
      </div>
      {showDelete && (
        <IconButton onClick={onDelete}>
          <IoMdTrash size={18} color={'#e74c3c'} />
        </IconButton>
      )}
    </StyledSectionTitle>
  );
}

export default SectionTitle;
