import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #121212;
  border: none;
  outline: none;
  cursor: pointer;
  /* transition: all ease-in-out 0.3s; */
  font-size: 15px;
  border-radius: ${(props) => props.radius || '5px'};
  height: 30px;
  width: 30px;
  margin: 5px 10px 10px 0px;
  background: ${theme.filterButtonColor} !important;

  &&:hover {
    opacity: 0.8;
  }

  &&:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
