/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import {
  SideMenu,
  DataTable,
  Input,
  Button,
  Toolbar,
  ConfirmDialog,
  Tutorial,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  DataArea,
  LoadingArea,
  HelpArea,
} from './styles';
import { dataColumns } from './helper';
import { toast } from 'react-toastify';
import { TbTrashX } from 'react-icons/tb';
import { IconButton, Tooltip, SwipeableDrawer } from '@material-ui/core';
import { BsArrowLeft } from 'react-icons/bs';
import { BiHelpCircle } from 'react-icons/bi';

function List({ history }) {
  const scrollParent = useRef();
  const { loadingColor, primary } = useContext(ThemeContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirm, setShowConfirm] = useState();
  const [showHelp, setShowHelp] = useState(false);

  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState();

  const loadData = async () => {
    setLoading(true);
    setDataList([]);
    const resp = await api.post(
      '/checklists',
      { page: currentPage, take: 20 },
      {
        headers: {
          search,
        },
      }
    );
    setDataList(resp?.data?.response);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage) {
      setLoadingMore(true);

      const resp = await api.post(
        '/checklists',
        { page, take: 20 },
        {
          headers: {
            search,
          },
        }
      );

      const data = dataList;
      const ndata = data.concat(resp?.data?.response);

      setCurrentPage(page);
      setDataList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    try {
      await api.delete(`/checklist/${showConfirm?.id}`);
      toast.success('Checklist excluído com sucesso!');
      setShowConfirm();
      setLoadingDelete(false);
      loadData();
    } catch (e) {
      setLoadingDelete(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={Boolean(showConfirm)}
      onCancel={() => setShowConfirm()}
      content="Tem certeza de que deseja excluir este checklist? Importante: o checklist será removido de todas as mídias às quais estiver vinculado."
      title="Excluir checklist"
      loading={loadingDelete}
      onConfirm={() => {
        handleDelete();
        setShowConfirm();
      }}
    />
  );

  const renderHelp = () => (
    <SwipeableDrawer
      anchor="right"
      open={showHelp}
      onClose={() => setShowHelp(false)}>
      <HelpArea>
        <h1>Ajuda</h1>

        <Tutorial
          onFinish={() => setShowHelp(false)}
          items={[
            {
              text: 'Checklists são itens que deverão ser observados no momento da análise de uma mídia.',
            },
            {
              text: 'Os checklists não impactam no relatório de vistoria, mas auxiliam o analista no momento da análise das mídias enviadas.',
            },
            {
              text: 'Uma mídia só poderá ser aprovada se todos os itens forem marcados no momento da análise.',
            },
            {
              text: 'Você poderá criar quantos checklists forem necessários e isso poderá ser alterado a qualquer momento nas configurações de Tipos de Mídias.',
            },
            {
              text: 'Sempre que excluir um item de checklist, o mesmo será removido automaticamente das mídias às quais estiver vinculado.',
            },
          ]}
        />

        {/* <ol>
          <li>
            Checklists são itens que deverão ser observados no momento da
            análise de uma mídia.
          </li>

          <li>
            Os checklists não impactam no relatório de vistoria. Esta é uma
            ferramenta para direcionar o analista no momento da análise das
            mídias enviadas na vistoria.
          </li>

          <li>
            Uma mídia só poderá ser aprovada se todos os itens forem marcados no
            momento da análise.
          </li>

          <li>
            Você poderá criar quantos checklists forem necessários e isso poderá
            ser alterado a qualquer momento nas configurações de{' '}
            <b>Tipos de mídias</b>.
          </li>

          <li>
            Sempre que excluir um item de checklist, o mesmo será removido
            automaticamente das mídias às quais estiver vinculado.
          </li>
        </ol> */}
      </HelpArea>
    </SwipeableDrawer>
  );

  const deleteDataColumns = [
    ...dataColumns,
    {
      name: '',
      selector: (row) => (
        <Tooltip
          title="Excluir"
          placement="right"
          onClick={() => setShowConfirm(row)}>
          <IconButton>
            <TbTrashX size={15} />
          </IconButton>
        </Tooltip>
      ),
      width: '10%',
      sortable: true,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      {renderConfirmDialog()}
      {renderHelp()}
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <IconButton
            style={{ maerginRight: 5 }}
            onClick={() => history?.goBack()}>
            <BsArrowLeft size={16} />
          </IconButton>
          <h1>Checklists</h1>
        </TitleArea>

        <Toolbar>
          <div>
            <Button
              onClick={() => history.push('/checklist/novo')}
              height="30px"
              width="150px"
              style={{ marginRight: 10 }}>
              Adicionar novo
            </Button>
          </div>
          <div>
            <Input
              search
              handleClean={() => {
                setSearch();
                loadData();
              }}
              height="32px"
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadData();
                }
                if (e.key === 'Escape') {
                  setSearch();
                  loadData();
                }
              }}
            />

            <div style={{ marginLeft: 10 }}>
              <IconButton onClick={() => setShowHelp(true)}>
                <BiHelpCircle size={16} color={primary} />
              </IconButton>
            </div>
          </div>
        </Toolbar>

        <DataArea ref={scrollParent}>
          <InfiniteScroll
            loadMore={() => !loadingMore && handleLoadMore()}
            hasMore={hasNextPage}
            useWindow={false}
            getScrollParent={() => scrollParent?.current}
            initialLoad={false}
            style={{ width: '100%', display: 'flex' }}
            threshold={10}>
            <DataTable
              persistTableHead
              responsive
              columns={deleteDataColumns}
              onRowClicked={(row) => history.push(`/checklist/${row?.id}`)}
              data={dataList}
              highlightOnHover
              pointerOnHover
              progressPending={loading}
              progressComponent={
                <LoadingArea>
                  <ReactLoading
                    color={loadingColor}
                    height={32}
                    width={32}
                    type="spin"
                  />
                </LoadingArea>
              }
              noDataComponent={
                <small style={{ marginTop: 20 }}>
                  Nenhum resultado encontrado
                </small>
              }
            />
          </InfiniteScroll>
        </DataArea>

        {!loading && !loadingMore && (
          <small className="counter-area">
            Mostrando {dataList?.length} de {totalCount}
          </small>
        )}

        {loadingMore && (
          <LoadingArea>
            <ReactLoading
              type="spin"
              color={loadingColor}
              height={25}
              width={25}
            />
          </LoadingArea>
        )}
      </ContentArea>
    </Container>
  );
}

export default List;
