/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import api from 'api';
import { Container, LeftBox, RightBox, Form, FormTitle, Logo } from './styles';
import { Button, Input } from 'components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

function Register({ location }) {
  const invite = location.search?.split('=')[1];

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [inviteData, setInviteData] = useState({});
  const { primary, lightLogo, loadingColor } = useContext(ThemeContext);

  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();

  const loadInvite = async () => {
    const resp = await api.get('/invite', {
      headers: { invite },
    });
    setInviteData(resp.data);
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/user', {
        phone: phone.replace(/[^\d]+/g, ''),
        password,
        inviteToken: invite,
      });

      toast.success('Usuário cadastrado com sucesso!');
      setLoading(false);
      history.push('/login');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  useEffect(() => {
    loadInvite();
  }, [invite]);

  return (
    <Container>
      <RightBox>
        {loading ? (
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        ) : (
          <Form>
            <FormTitle>Registro</FormTitle>
            <Input type="text" disabled value={inviteData?.name} />
            <Input type="text" disabled value={inviteData?.email} />
            <Input
              type="tel"
              placeholder="Telefone"
              mask="(99) 99999-9999"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Input
              password
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              disabled={!phone || !password || !invite}
              onClick={(e) => handleInvite(e)}
              style={{ marginTop: 10 }}>
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={25} width={25} />
              ) : (
                'Cadastrar'
              )}
            </Button>
          </Form>
        )}
      </RightBox>
      <LeftBox color={primary}>
        <Logo src={lightLogo} />
      </LeftBox>
    </Container>
  );
}

export default Register;
