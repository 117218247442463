import React, { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import { StyledButton } from './styles';
import { RiFileExcel2Line } from 'react-icons/ri';
import { Tooltip } from '@material-ui/core';

function ExportButton({ ...props }) {
  const { primary } = useContext(ThemeContext);

  return (
    <Tooltip {...props} title="Exportar para Excel" placement="bottom">
      <StyledButton color={primary}>
        <RiFileExcel2Line color="#878787" size={14} />
      </StyledButton>
    </Tooltip>
  );
}

export default ExportButton;
