import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  height: 400px;
  width: 230px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 5px;
  border: 3px solid #eee;
  .title {
    font-size: 14px;
    font-weight: normal;
    margin-top: 30px;
    width: 80%;
    text-align: center;
    overflow-wrap: break-word;
  }

  .picture {
    height: 150px;
    width: 150px;
  }

  .help {
    font-size: 11px;
    color: #6b6b6b;
    font-weight: 300;
    text-align: center;
    margin-bottom: 30px;
    width: 80%;
    text-align: center;
    overflow-wrap: break-word;
  }
`;

export const ButtonFake = styled.div`
  width: 90%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 12px;
`;
