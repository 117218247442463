import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  h1 {
    margin: 0px;
    margin-left: 10px;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 100%;
  background-color: #fefefe;
  box-sizing: border-box;
  padding: 20px;
  h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
`;
