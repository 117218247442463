/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import PermissionContext from 'contexts/PermissionContext';
import {
  BsBoxSeamFill,
  BsClipboardCheckFill,
  BsFillCollectionPlayFill,
  BsUiChecks,
} from 'react-icons/bs';
import { RiUserSettingsFill } from 'react-icons/ri';
import {
  MdAddBusiness,
  MdStoreMallDirectory,
  MdNotificationsActive,
} from 'react-icons/md';
import { CgDarkMode } from 'react-icons/cg';
import { IoIosKey } from 'react-icons/io';
import { SiCashapp } from 'react-icons/si';

import { SideMenu } from 'components';
import { Container, ContentArea, TitleArea, OptionItem } from './styles';

function Settings({ history }) {
  const { primary } = useContext(ThemeContext);
  const { userPermissions } = useContext(PermissionContext);

  const verifyPermission = (id) => {
    if (userPermissions) {
      const foundIndex = userPermissions?.findIndex((i) => i.id_feature === id);
      const permission = userPermissions[foundIndex];
      return Boolean(permission);
    }
  };

  const options = [
    {
      name: 'Meu perfil',
      description: 'Configure as suas informações pessoais',
      icon: <RiUserSettingsFill size={20} />,
      url: '/meu-perfil',
      hasPermission: true,
    },
    {
      name: 'Tipos de Objetos',
      description: 'Configure os tipos de objetos que poderão ser vistoriados',
      icon: <BsBoxSeamFill size={18} />,
      url: '/tipos-objetos',
      hasPermission: verifyPermission(10),
    },
    {
      name: 'Tipos de Mídias',
      description:
        'Configure as mídias que poderão ser capturadas nas suas vistorias',
      icon: <BsFillCollectionPlayFill size={20} />,
      url: '/tipos-midias',
      hasPermission: verifyPermission(11),
    },

    {
      name: 'Tipos de Vistorias',
      description: 'Configure os tipos de vistorias que poderão ser realizadas',
      icon: <BsClipboardCheckFill size={18} />,
      url: '/tipos-vistorias',
      hasPermission: verifyPermission(12),
    },
    {
      name: 'Checklists',
      description: 'Configure os checklists das mídia que serão capturadas ',
      icon: <BsUiChecks size={23} />,
      url: '/checklists',
      hasPermission: verifyPermission(18),
    },
    {
      name: 'Perfis de acesso',
      description: 'Crie/altere as permissões de acesso do Portal',
      icon: <IoIosKey size={21} />,
      url: '/perfis-de-acesso',
      hasPermission: verifyPermission(14),
    },
    {
      name: 'Minha empresa',
      description: 'Personalize o Portal com a cara do seu negócio',
      icon: <MdStoreMallDirectory size={21} />,
      url: '/minha-empresa',
      hasPermission: verifyPermission(13),
    },
    {
      name: 'Empresas parceiras',
      description: 'Gerencie as informações de empresas parceiras',
      icon: <MdAddBusiness size={21} />,
      url: '/empresas-parceiras',
      hasPermission: verifyPermission(15),
    },
    {
      name: 'Extrato financeiro',
      description: 'Gerencie as informações financeiras da sua empresa',
      icon: <SiCashapp size={18} />,
      url: '/financeiro',
      hasPermission: verifyPermission(17),
    },
    {
      name: 'Notificações',
      description: 'Gerencie as notificações do Portal Vistei',
      icon: <MdNotificationsActive size={21} />,
      url: '',
      hasPermission: true,
    },
    {
      name: 'Modo escuro',
      description: 'Ativar/desativar o modo escuro do Portal',
      icon: <CgDarkMode size={21} />,
      url: '',
      hasPermission: true,
    },
  ];

  return (
    <Container>
      <SideMenu />

      <ContentArea>
        <TitleArea>
          <h1>Configurações</h1>
        </TitleArea>

        <section>
          <div className="options-line">
            {options?.map(
              (option) =>
                option.hasPermission && (
                  <OptionItem
                    disabled={!option?.url}
                    color={primary}
                    onClick={() => option?.url && history.push(option?.url)}>
                    <div className="icon">{option?.icon}</div>
                    <div>
                      <p className="title">{option?.name}</p>
                      <p className="description">{option?.description}</p>
                    </div>
                  </OptionItem>
                )
            )}
          </div>
        </section>
      </ContentArea>
    </Container>
  );
}

export default Settings;
