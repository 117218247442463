import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: #fafbfe;
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  input[type='color'] {
    opacity: 0;
  }

  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }

  .horizontal {
    display: flex;
    flex-direction: row;
  }

  .right-area {
    width: 70%;
  }

  .left-area {
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;
  }

  @media (max-width: 1300px) {
    .right-area {
      display: none;
    }
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  h1 {
    margin: 0px;
    margin-left: 10px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  label {
    font-size: 12px;
    color: #878787;
    width: 40%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  padding-top: 0px;
  min-width: 500px;
`;

export const ColorPicker = styled.div`
  width: ${(props) => props.width || '300px'};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  margin: 10px 0px;
  box-sizing: border-box;
  padding: 5px 10px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(100, 100, 100, 0.1) 0px 7px 29px 0px;
  }

  .color {
    width: 40px;
    height: 40px;
    background-color: ${(props) => props.color};
    border-radius: 5px;
  }

  .info {
    margin-left: 10px;
  }

  p {
    font-size: 12px;
    margin: 0px;
    color: #868686;
  }

  small {
    font-size: 10px;
    margin: 0px;
    color: #868686;
  }
`;

export const ImagePreview = styled.div`
  width: ${(props) => props.width || '300px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  /* box-shadow: rgba(100, 100, 100, 0.1) 0px 7px 29px 0px; */
  margin: 20px 0px;
  box-sizing: border-box;
  padding: 5px 10px;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  &:hover {
    box-shadow: rgba(100, 100, 100, 0.1) 0px 7px 29px 0px;
  }

  div {
    padding-left: 10px;
  }

  p {
    font-size: 12px;
    margin: 0px;
    color: #868686;
  }

  small {
    font-size: 10px;
    margin: 0px;
    color: #868686;
  }

  img {
    width: 100px;
    height: 60px;
    padding: 10px;
    border-radius: 5px;
    object-fit: contain;
  }
`;
