import moment from 'moment';
import { formatInviteStatus } from 'utils/formatInspection';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row?.name,
    width: '20%',
    sortable: true,
  },
  {
    name: 'E-mail',
    selector: (row) => row?.email || 'Não informado',
    width: '20%',
    sortable: true,
  },
  {
    name: 'Perfil',
    selector: (row) => row?.user_role?.name || 'Não informado',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => formatInviteStatus(row?.status),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Cód. Convite',
    selector: (row) => row?.token,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data do envio',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
];
