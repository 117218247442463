import React, { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';

function Info({ items, onFinish, ...props }) {
  const { primary } = useContext(ThemeContext);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" // Adicione o atributo xmlns aqui
      xmlnsXlink="http://www.w3.org/1999/xlink" // Adicione o atributo xmlnsXlink aqui, se necessário
      version="1.1"
      width={200}
      height={200}
      x="0"
      y="0"
      viewBox="0 0 36 36"
      xmlSpace="preserve">
      <g>
        <path
          fill="#EFEFEF"
          d="M31.833 27.623c0-6.917 3.583-11.667 3.583-15S33.5 2.29 18.167 2.29.583 11.707.583 15.373c0 17.167 31.25 24.5 31.25 12.25z"
          data-original="#efefef"></path>
        <path
          fill="#A4AFC1"
          d="M17.5 5c-.827 0-1.5-.673-1.5-1.5S16.673 2 17.5 2s1.5.673 1.5 1.5S18.327 5 17.5 5zm0-2c-.275 0-.5.225-.5.5s.225.5.5.5.5-.225.5-.5-.225-.5-.5-.5z"
          data-original="#a4afc1"></path>
        <path
          fill="#F3F3F1"
          d="M27.25 28.25H8.75a2 2 0 0 1-2-2v-15h22.5v15a2 2 0 0 1-2 2z"
          data-original="#f3f3f1"></path>
        <path
          fill={primary}
          d="M29.25 11.25H6.75v-1.5a2 2 0 0 1 2-2h18.5a2 2 0 0 1 2 2z"
          data-original={primary}></path>
        <path
          fill="#D5DBE1"
          d="M9.25 26.25v-15h-2.5v15a2 2 0 0 0 2 2h2.5a2 2 0 0 1-2-2z"
          data-original="#d5dbe1"></path>
        <path
          fill="rgba(0,0,0,0.1)"
          d="M11 7.75H8.75a2 2 0 0 0-2 2v1.5H9v-1.5a2 2 0 0 1 2-2z"
          data-original="rgba(0,0,0,0.1)"></path>
        <path
          d="M18 17c-.827 0-1.5-.673-1.5-1.5S17.173 14 18 14s1.5.673 1.5 1.5S18.827 17 18 17zm0-1.501v.002l.75-.001zM18.75 26h-1.5v-5.5H16V19h2a.75.75 0 0 1 .75.75z"
          fill="#000000"
          data-original="#000000"></path>
        <path
          d="M15.5 24.5h5V26h-5z"
          fill="#000000"
          data-original="#000000"></path>
        <path
          d="M27.25 29H8.75A2.752 2.752 0 0 1 6 26.25V9.75A2.752 2.752 0 0 1 8.75 7h18.5A2.752 2.752 0 0 1 30 9.75v16.5A2.752 2.752 0 0 1 27.25 29zM8.75 8.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25V9.75c0-.689-.561-1.25-1.25-1.25z"
          fill="#000000"
          data-original="#000000"></path>
        <path
          d="M6.75 10.5h22.5V12H6.75zM2 27.5h32V29H2z"
          fill="#000000"
          data-original="#000000"></path>
      </g>
    </svg>
  );
}

export default Info;
