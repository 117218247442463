import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background: ${(props) => props.color};
  box-sizing: border-box;
  transition: all linear 0.3s;
  position: relative;
`;

export const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  width: 100%;
  min-height: 400px;
  height: 100px;
  box-sizing: border-box;
  padding: 15px;

  p {
    font-size: 12px;
    color: #c1ced6;
  }

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const NotificationsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  p {
    color: #121212;
  }
`;

export const NotificationItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fefefe;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: all ease-in-out 0.4s;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.iconBackground};
    color: ${theme.iconColor};
    height: 45px;
    width: 45px;
    min-height: 45px;
    min-width: 45px;
    border-radius: 5px;
    margin: 0px;
  }

  .info {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
  }
  p {
    font-size: 14px;
    margin: 0px;
    margin-top: 5px;
    color: #121212;
    font-weight: ${(props) => (props.read ? 'normal' : 'bold')};
  }

  small {
    color: ${theme.iconColor} !important;
    font-size: 10px;
    margin: 0px;
  }

  &:hover {
    box-shadow: rgba(100, 100, 100, 0.07) 0px 7px 29px 0px;
  }
`;
