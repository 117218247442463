import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-width: ${(props) => (props.selected ? "2px" : "1px")};
  border-color: ${(props) => (props.selected ? "#0b439e" : "#c9c3c3")};
  color: ${(props) => (props.selected ? "#4c4c4c" : "#a2a2a2")};
  padding: 12px 27px;
  margin-right: 15px;
  cursor: pointer;
  border-radius: 8px;
  width: 110px;
  span {
    color: ${(props) => (props.selected ? "#0b439e" : "#a2a2a2")};
  }

  transition: all ease-in-out 0.3s;

  &&:hover {
    opacity: 0.7;
  }
`;
