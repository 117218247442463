import React, { useState, useContext } from 'react';
import api from 'api';
import PasswordStrengthBar from 'react-password-strength-bar';
import ThemeContext from 'contexts/ThemeContext';
import {
  Container,
  LeftBox,
  RightBox,
  Form,
  FormTitle,
  ForgotText,
  Logo,
  Divider,
} from './styles';
import { Button, Input } from 'components';
import { toast } from 'react-toastify';

function RecoverPassword({ history }) {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState();
  const [recoveryCode, setRecoveryCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [loading, setLoading] = useState(false);
  const { lightLogo, primary } = useContext(ThemeContext);

  const handleRecover = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/user/requestCode', { email });
      setLoading(false);
      setStep(step + 1);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/user/password/new', {
        recoveryCode,
        password: newPassword,
      });
      setLoading(false);
      toast.success('Senha alterada com sucesso');
      history.push('/login');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  return (
    <Container>
      <RightBox>
        <Form>
          {step === 1 && (
            <>
              <FormTitle>Recuperar senha</FormTitle>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="E-mail"
              />
              <Button
                disabled={!email}
                loading={loading}
                onClick={(e) => handleRecover(e)}
                style={{ marginTop: 10 }}>
                Recuperar
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <FormTitle>Criar nova senha</FormTitle>
              <Input
                value={recoveryCode}
                onChange={(e) => setRecoveryCode(e.target.value)}
                type="text"
                placeholder="Código de recuperação"
              />
              <Input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                password
                type="password"
                placeholder="Nova senha"
              />
              {newPassword && (
                <PasswordStrengthBar
                  style={{ width: 300, fontSize: 10 }}
                  password={newPassword}
                  minLength={6}
                  shortScoreWord="Senha muito curta"
                  scoreWords={[
                    'Senha fraca',
                    'Senha fraca',
                    'Senha OK',
                    'Senha OK',
                    'Senha forte',
                  ]}
                />
              )}

              <Button
                disabled={!recoveryCode || !newPassword}
                style={{ marginTop: 10 }}
                loading={loading}
                onClick={(e) => handleResetPassword(e)}>
                Alterar senha
              </Button>
            </>
          )}
          <Divider />
          <ForgotText href="/login">Voltar para login</ForgotText>
        </Form>
      </RightBox>
      <LeftBox color={primary}>
        <Logo src={lightLogo} />
      </LeftBox>
    </Container>
  );
}

export default RecoverPassword;
