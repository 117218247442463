/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import UserContext from 'contexts/UserContext';
import PermissionContext from 'contexts/PermissionContext';
import api from 'api';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsiveLine } from '@nivo/line';
import {
  BsClipboardCheckFill,
  BsFillCollectionPlayFill,
  BsFillClipboardHeartFill,
  BsFillClipboardPlusFill,
} from 'react-icons/bs';
import { RiUserSettingsFill } from 'react-icons/ri';
import { IoIosSwitch } from 'react-icons/io';
import { MdHelpCenter } from 'react-icons/md';

import { SideMenu, SectionTitle } from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  WidgetCard,
  ChartTooltip,
  OptionItem,
} from './styles';
import { getGreeting } from 'utils/greeting';

function Dashboard({ history }) {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const { primary, loadingColor } = useContext(ThemeContext);
  const { userData } = useContext(UserContext);
  const { userPermissions } = useContext(PermissionContext);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get('/dashboard');
    setData(resp?.data);
    setLoading(false);
  };

  const verifyPermission = (id) => {
    if (userPermissions) {
      const foundIndex = userPermissions?.findIndex((i) => i.id_feature === id);
      const permission = userPermissions[foundIndex];
      return Boolean(permission);
    }
  };

  // CHARTS DATA

  const statusData = [
    {
      status: 'Ag. vistoria',
      value: data?.inspectionStatus?.pending,
      color: primary,
    },
    {
      status: 'Iniciadas',
      value: data?.inspectionStatus?.started,
      color: primary,
    },
    {
      status: 'Ag. complement.',
      value: data?.inspectionStatus?.awaiting_complementar,
      color: primary,
    },
    {
      status: 'Ag. análise',
      value: data?.inspectionStatus?.awaiting_report,
      color: primary,
    },
    {
      status: 'Finalizadas',
      value: data?.inspectionStatus?.finished,
      color: primary,
    },
    {
      status: 'Canceladas',
      value: data?.inspectionStatus?.canceled,
      color: primary,
    },
    {
      status: 'Expiradas',
      value: data?.inspectionStatus?.expired,
      color: primary,
    },
  ];

  const typesData = data?.inspectionTypes?.map(
    (i) =>
      (i = {
        id: `[${i.id}] ${i.name}`,
        name: i.name,
        value: i.inspections || 0,
        color: primary,
      })
  );

  const dataPie = [
    {
      id: 'Aprovadas',
      label: 'Aprovadas',
      value: data?.approvalStatus?.approved,
      color: '#2ecc71',
    },
    {
      id: 'Rejeitadas',
      label: 'Rejeitadas',
      value: data?.approvalStatus?.rejected,
      color: '#e74c3c',
    },
  ];

  const dataLine = [
    {
      id: 'Vistorias',
      color: primary,
      data: [
        {
          x: data?.monthInspections[0]?.month,
          y: data?.monthInspections[0]?.inspections,
        },
        {
          x: data?.monthInspections[1]?.month,
          y: data?.monthInspections[1]?.inspections,
        },
        {
          x: data?.monthInspections[2]?.month,
          y: data?.monthInspections[2]?.inspections,
        },
      ],
    },
  ];

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          color: '#FFFFFF',
          fontSize: '35px',
        }}>
        {total}
      </text>
    );
  };

  const cards = [
    {
      value: data?.totalInspections?.toLocaleString('pt-BR') || 0,
      description: 'vistorias solicitadas',
      icon: <BsFillClipboardHeartFill size={20} />,
    },
    {
      value: data?.complementarInspections?.toLocaleString('pt-BR') || 0,
      description: 'vistorias complementares',
      icon: <BsFillClipboardPlusFill size={20} />,
    },
    {
      value:
        data?.inspectionStatus?.awaiting_report?.toLocaleString('pt-BR') || 0,
      description: 'aguardando análise',
      icon: <BsClipboardCheckFill size={20} />,
    },
    {
      value: data?.totalMedias?.toLocaleString('pt-BR') || 0,
      description: 'mídias capturadas',
      icon: <BsFillCollectionPlayFill size={20} />,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const options = [
    {
      name: 'Meu perfil',
      description: 'Configure as suas informações pessoais',
      icon: <RiUserSettingsFill size={20} />,
      url: '/meu-perfil',
      hasPermission: true,
    },
    {
      name: 'Configurações',
      description: 'Gerencie as configurações do sistema',
      icon: <IoIosSwitch size={18} />,
      url: '/configuracoes',
      hasPermission: true,
    },
    {
      name: 'Ajuda e suporte',
      description: 'Aprenda a utilizar o sistema',
      icon: <MdHelpCenter size={21} />,
      url: 'https://docs.vistei.app/',
      hasPermission: true,
      type: 'link',
    },
  ];

  const hasPermission = verifyPermission(16);

  return (
    <Container>
      <SideMenu />

      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <h1>
              {getGreeting()}, {userData?.name?.split(' ')[0]}!
            </h1>
          </TitleArea>
          {hasPermission && (
            <>
              <div className="widget-line">
                {cards?.slice(0, 5)?.map((card) => (
                  <WidgetCard
                    width={`${(100 / cards?.length - 5) * 100}%`}
                    color={primary}>
                    <div className="icon">{card.icon}</div>
                    <div>
                      <p className="title">{card?.value}</p>
                      <p className="description">{card?.description}</p>
                    </div>
                  </WidgetCard>
                ))}
              </div>

              <div className="widget-line">
                <div className="widget-mini">
                  <SectionTitle title="Status de vistorias" color={primary} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 250,
                    }}>
                    <ResponsiveBar
                      data={statusData}
                      indexBy="status"
                      margin={{ top: 50, right: 30, bottom: 50, left: 0 }}
                      padding={0.3}
                      valueScale={{ type: 'linear' }}
                      indexScale={{ type: 'band', round: true }}
                      colors={{ datum: 'data.color' }}
                      enableGridX={false}
                      enableGridY={false}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                      }}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 32,
                      }}
                      axisLeft={null}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor={{
                        from: 'color',
                        modifiers: [['brighter', 2.5]],
                      }}
                      tooltip={(point) => (
                        <ChartTooltip>
                          {`${
                            point.indexValue
                          }: ${point.formattedValue.toLocaleString('pt-BR')}`}
                        </ChartTooltip>
                      )}
                    />
                  </div>
                </div>

                <div className="widget-mini">
                  <SectionTitle
                    title="Aprovação de vistorias"
                    color={primary}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 200,
                    }}>
                    <ResponsivePie
                      data={dataPie}
                      margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                      innerRadius={0.6}
                      padAngle={0.7}
                      activeOuterRadiusOffset={8}
                      colors={{ datum: 'data.color' }}
                      arcLinkLabelsSkipAngle={10}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsThickness={2}
                      layers={['arcs', 'arcLabels', CenteredMetric]}
                      tooltip={(point) => (
                        <ChartTooltip>
                          {`${
                            point.datum.label
                          }: ${point.datum.formattedValue.toLocaleString(
                            'pt-BR'
                          )}`}
                        </ChartTooltip>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="widget-line">
                <div className="widget-mini">
                  <SectionTitle
                    title={'Histórico trimestral'}
                    color={primary}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 200,
                    }}>
                    <ResponsiveLine
                      enableArea
                      curve="monotoneX"
                      data={dataLine}
                      margin={{ top: 10, right: 20, bottom: 20, left: 20 }}
                      xScale={{ type: 'point' }}
                      enableGridX={false}
                      enableGridY={false}
                      enableSlices={'x'}
                      axisBottom={{
                        orient: 'top',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                      }}
                      axisLeft={null}
                      pointSize={13}
                      useMesh={true}
                      colors={{ datum: 'color' }}
                      tooltip={(point) => (
                        <ChartTooltip>
                          {`${
                            point.datum.label
                          }: ${point.datum.formattedValue.toLocaleString(
                            'pt-BR'
                          )}`}
                        </ChartTooltip>
                      )}
                    />
                  </div>
                </div>

                <div className="widget-mini">
                  <SectionTitle title="Vistorias por tipo" color={primary} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 250,
                    }}>
                    {typesData?.length > 0 ? (
                      <ResponsiveBar
                        data={typesData}
                        layout="horizontal"
                        groupMod="grouped"
                        margin={{ top: 0, right: 0, bottom: 20, left: 220 }}
                        padding={0.5}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        colors={{ datum: 'data.color' }}
                        enableGridX={false}
                        enableGridY={true}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={{
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                        }}
                        labelTextColor={{
                          from: 'color',
                          modifiers: [['brighter', 2.5]],
                        }}
                        tooltip={(point) => (
                          <ChartTooltip>
                            {`${
                              point.indexValue
                            }: ${point.formattedValue.toLocaleString('pt-BR')}`}
                          </ChartTooltip>
                        )}
                      />
                    ) : (
                      <p
                        style={{
                          fontSize: 14,
                          opacity: 0.4,
                          width: '100%',
                          textAlign: 'center',
                          marginTop: 50,
                        }}>
                        Nenhuma informação para exibir
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {!hasPermission && (
            <div className="home-icons">
              <SectionTitle title="Que tal começar por aqui?" color={primary} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {options?.map(
                  (option) =>
                    option.hasPermission && (
                      <OptionItem
                        disabled={!option?.url}
                        color={primary}
                        onClick={() =>
                          option?.url &&
                          (option?.type === 'link'
                            ? window.open(option?.url)
                            : history.push(option?.url))
                        }>
                        <div className="icon">{option?.icon}</div>
                        <div>
                          <p className="title">{option?.name}</p>
                          <p className="description">{option?.description}</p>
                        </div>
                      </OptionItem>
                    )
                )}
              </div>
            </div>
          )}
        </ContentArea>
      )}
    </Container>
  );
}

export default Dashboard;
