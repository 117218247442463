/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';

import ThemeContext from 'contexts/ThemeContext';
import PermissionContext from 'contexts/PermissionContext';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CsvDownload from 'react-json-to-csv';
import api from 'api';
import {
  SideMenu,
  DataTable,
  Input,
  FilterButton,
  Button,
  Select,
  Toolbar,
  ExportButton,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  DataArea,
  LoadingArea,
  FilterArea,
} from './styles';
import { dataColumns } from './helper';
import { socket } from 'services/socket';

function List({ history, location }) {
  const scrollParent = useRef();
  const { primary, loadingColor } = useContext(ThemeContext);
  const { userPermissions } = useContext(PermissionContext);

  const [showFilters, setShowFilters] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState();
  const [status, setStatus] = useState(
    new URLSearchParams(location.search).get('status')
  );
  const [exportFilters, setExportFilters] = useState({});
  const [exportData, setExportData] = useState();

  const loadData = async () => {
    setLoading(true);
    setDataList([]);
    const resp = await api.post(
      '/inspections',
      { page: currentPage, take: 20 },
      {
        headers: {
          status,
          search,
        },
      }
    );
    setDataList(resp?.data?.inspections);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage) {
      setLoadingMore(true);

      const resp = await api.post(
        '/inspections',
        { page, take: 10 },
        {
          headers: {
            status,
            search,
          },
        }
      );

      const data = dataList;
      const ndata = data.concat(resp?.data?.inspections);

      setCurrentPage(page);
      setDataList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleExport = async () => {
    setLoadingExport(true);
    setExportData();
    const resp = await api({
      method: 'post',
      url: '/export-inspections',
      data: {
        status,
        startDate: exportFilters.startDate,
        endDate: exportFilters.endDate,
      },
    });
    setExportData(resp?.data);
    setLoadingExport(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderFilters = () => (
    <SwipeableDrawer
      anchor="right"
      open={showFilters}
      onClose={() => setShowFilters(false)}>
      <FilterArea>
        <h1>Filtros</h1>

        <Select
          label="Status"
          width="250px"
          value={status}
          onChange={(e) =>
            setStatus(e.target.value === 'all' ? undefined : e.target.value)
          }>
          <option value="all">Todos</option>
          <option value="pending">Aguardando vistoria</option>
          <option value="awaiting_complementar">
            Aguardando vistoria complementar
          </option>
          <option value="awaiting_report">Aguardando relatório</option>
          <option value="started">Iniciada</option>
          <option value="expired">Expirada</option>
          <option value="canceled">Cancelada</option>
          <option value="finished">Finalizada</option>
        </Select>

        <Button
          onClick={() => {
            setShowFilters(false);
            loadData();
          }}
          style={{ marginTop: 20 }}
          width="250px">
          Aplicar
        </Button>
      </FilterArea>
    </SwipeableDrawer>
  );

  const renderExport = () => (
    <SwipeableDrawer
      anchor="right"
      open={showExport}
      onClose={() => {
        setShowExport(false);
        setExportData();
      }}>
      <FilterArea>
        <h1>Exportar informações</h1>

        <Select
          label="Status"
          width="250px"
          value={status}
          onChange={(e) =>
            setStatus(e.target.value === 'all' ? undefined : e.target.value)
          }>
          <option value="all">Todos</option>
          <option value="pending">Aguardando vistoria</option>
          <option value="awaiting_complementar">
            Aguardando vistoria complementar
          </option>
          <option value="awaiting_report">Aguardando relatório</option>
          <option value="started">Iniciada</option>
          <option value="expired">Expirada</option>
          <option value="canceled">Cancelada</option>
          <option value="finished">Finalizada</option>
        </Select>

        <Input
          width="250px"
          label="Data início"
          mask="99/99/9999"
          value={exportFilters?.startDate}
          onChange={(e) =>
            setExportFilters({ ...exportFilters, startDate: e.target.value })
          }
        />

        <Input
          width="250px"
          label="Data fim"
          mask="99/99/9999"
          value={exportFilters?.endDate}
          onChange={(e) =>
            setExportFilters({ ...exportFilters, endDate: e.target.value })
          }
        />

        {!exportData && (
          <Button
            loading={loadingExport}
            onClick={handleExport}
            style={{ marginTop: 20 }}
            width="250px">
            Exportar
          </Button>
        )}

        {exportData && (
          <CsvDownload
            data={exportData}
            style={{
              height: 40,
              width: 250,
              margin: 0,
              marginTop: 20,
              cursor: 'pointer',
              borderRadius: 5,
              border: 'none',
              color: 'white',
              backgroundColor: primary,
            }}
            filename={`vistorias_${new Date().toLocaleDateString()}.csv`}>
            Baixar
          </CsvDownload>
        )}
      </FilterArea>
    </SwipeableDrawer>
  );

  socket.on('newInspection', (msg) => {
    loadData();
  });

  const verifyPermission = (id) => {
    if (userPermissions) {
      const foundIndex = userPermissions?.findIndex((i) => i.id_feature === id);
      const permission = userPermissions[foundIndex];
      return Boolean(permission);
    }
  };

  return (
    <Container>
      {renderFilters()}
      {renderExport()}
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <h1>Vistorias</h1>
        </TitleArea>
        <Toolbar>
          <div>
            {verifyPermission(7) && (
              <Button
                onClick={() => history.push('/vistorias/nova')}
                height="30px"
                width="150px"
                style={{ marginRight: 10 }}>
                Solicitar vistoria
              </Button>
            )}
          </div>
          <div>
            <FilterButton onClick={() => setShowFilters(true)} />
            <ExportButton onClick={() => setShowExport(true)} />
            <Input
              search
              handleClean={() => {
                setSearch();
                loadData();
              }}
              height="32px"
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadData();
                }
                if (e.key === 'Escape') {
                  setSearch();
                  loadData();
                }
              }}
            />
          </div>
        </Toolbar>
        <DataArea ref={scrollParent}>
          <InfiniteScroll
            loadMore={() => !loadingMore && handleLoadMore()}
            hasMore={hasNextPage}
            useWindow={false}
            getScrollParent={() => scrollParent?.current}
            initialLoad={false}
            style={{ width: '100%', display: 'flex' }}
            threshold={10}>
            <DataTable
              onRowClicked={(row) =>
                history.push(`/vistoria/${row?.inspection_code}`)
              }
              persistTableHead
              responsive
              columns={dataColumns}
              data={dataList}
              highlightOnHover
              pointerOnHover
              progressPending={loading}
              progressComponent={
                <LoadingArea>
                  <ReactLoading
                    color={loadingColor}
                    height={32}
                    width={32}
                    type="spin"
                  />
                </LoadingArea>
              }
              noDataComponent={
                <small style={{ marginTop: 20 }}>
                  Nenhuma vistoria encontrada
                </small>
              }
            />
          </InfiniteScroll>
        </DataArea>
        {!loading && !loadingMore && (
          <small className="counter-area">
            Mostrando {dataList?.length} de {totalCount}
          </small>
        )}
        {loadingMore && (
          <LoadingArea>
            <ReactLoading
              type="spin"
              color={loadingColor}
              height={25}
              width={25}
            />
          </LoadingArea>
        )}
      </ContentArea>
    </Container>
  );
}

export default List;
