import React, { useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import BaseSwitch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

function Switch({ ...props }) {
  const { secondary } = useContext(ThemeContext);

  const CustomSwitch = withStyles({
    switchBase: {
      color: '#FFFFFF',
      '&$checked': {
        color: secondary,
      },
      '&$checked + $track': {
        backgroundColor: secondary,
      },
    },
    checked: {},
    track: {},
  })(BaseSwitch);

  return <CustomSwitch {...props} />;
}

export default Switch;
