import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: #fafbfe;
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  .custom-input {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    label {
      font-size: 11px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .main-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .left-area {
    width: 50%;
  }

  .right-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }

    p {
      font-weight: 300;
      color: #242424;
      font-size: 14px;
      text-align: center;
    }
  }

  .mobile {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;
    .right-area {
      display: none;
    }
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  h1 {
    margin: 0px;
    margin-left: 10px;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 100%;
  background-color: #fefefe;
  box-sizing: border-box;
  padding: 20px;
  h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
`;

export const ImagePreview = styled.div`
  width: ${(props) => props.width || '300px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  box-shadow: rgba(100, 100, 100, 0.1) 0px 7px 29px 0px;
  margin: 20px 0px;
  box-sizing: border-box;
  padding: 5px 10px;
  overflow: hidden;

  div {
    padding-left: 10px;
  }

  p {
    font-size: 12px;
    margin: 0px;
    color: #868686;
  }

  small {
    font-size: 10px;
    margin: 0px;
    color: #868686;
  }

  img {
    height: 60px;
    border-radius: 5px;
  }
`;

export const FeaturesArea = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 12px;
  }

  h3 {
    font-size: 14px;
  }

  .permission-section {
    margin-top: -15px;
  }
`;

export const PermissionSection = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 12px 0px;
  }

  .checkbox-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .label-group {
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;

    label {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-left: 5px;
      transition: all ease-in-out 0.3s;
      font-size: 13px;
    }
    small {
      font-size: 12px;
      color: #aab1b5;
      margin-top: 3px;
    }
  }
`;
