import { createGlobalStyle } from 'styled-components';
import { theme } from 'styles/colorPalette';
import './fontProvider.css';

export const GlobalStyle = createGlobalStyle`
  html{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    background-color: #fefefe;
    color: #121212;   
  }

  section {
    width: 100%;
    background-color: ${theme.widgetBackground};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    padding: 10px 30px 30px 30px;
  }

  .loading-area{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    width: 100%;
  }

  .counter-area {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 10px;
    text-align: center;
    color: #bdc3c7;
    padding-top: 15px;
  }
`;
