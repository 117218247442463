/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import PermissionContext from 'contexts/PermissionContext';
import ReactStreetview from 'react-streetview';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@mui/material/Alert';
import VideoThumbnail from 'react-video-thumbnail';
import api from 'api';
import moment from 'moment';

import { toast } from 'react-toastify';
import {
  SideMenu,
  SectionTitle,
  Button,
  InfoText,
  Toolbar,
  ConfirmDialog,
  PdfPreviewer,
  DataTable,
} from 'components';
import { Container, ContentArea, TitleArea, MediaItem } from './styles';
import { BsArrowLeft, BsClipboardCheck } from 'react-icons/bs';
import { SiWhatsapp } from 'react-icons/si';
import { IoCopyOutline } from 'react-icons/io5';
import { MdOpenInNew } from 'react-icons/md';
import {
  formatStatusLabel,
  formatReportStatus,
  formatInspectionStatus,
} from 'utils/formatInspection';
import { Stepper, Step, StepLabel } from '@mui/material';
import { formatMediaIcon } from 'utils/formatMedia';
import { formatCPF, formatPlate } from 'react-data-formatter';

function Details({ history, location }) {
  const dataId = location.pathname.split('/vistoria/')[1];
  const [loading, setLoading] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [data, setData] = useState();
  const [showFinishDialog, setShowFinishDialog] = useState();

  const { primary, loadingColor } = useContext(ThemeContext);
  const { userPermissions } = useContext(PermissionContext);

  const steps = [
    { label: 'Aguardando vistoria', status: 'pending' },
    { label: 'Captura iniciada', status: 'started' },
    { label: 'Captura finalizada', status: 'awaiting_report' },
    {
      label: 'Aguardando vistoria complementar',
      status: 'awaiting_complementar',
    },
    { label: 'Aguardando relatório', status: 'awaiting_report' },
    { label: 'Vistoria concluída', status: 'finished' },
  ];

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/inspection/${dataId}`);
    setData(resp?.data);
    setLoading(false);
  };

  const handleQuotationLink = (type) => {
    const quotationUrl = `${process.env.REACT_APP_V_URL}/${data?.inspection_code}`;
    const whatsAppMessage = `Olá, ${
      data?.author?.name?.split(' ')[0]
    }! Aqui está o link para sua vistoria: ${quotationUrl}`;
    const whatsAppLink = `https://api.whatsapp.com/send?phone=${data?.author?.phone}&text=${whatsAppMessage}`;

    if (type === 'whatsapp') {
      window.open(whatsAppLink, '_blank');
    }

    if (type === 'copy') {
      navigator.clipboard.writeText(quotationUrl);
      toast.success('Link copiado com sucesso!');
    }
  };

  const handleFinishInspection = async () => {
    setLoadingFinish(true);
    await api({
      method: 'put',
      url: `/force-finish-inspection/${data?.id}`,
    });
    setLoadingFinish(false);
    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderFinishDialog = () => (
    <ConfirmDialog
      open={showFinishDialog}
      onCancel={() => setShowFinishDialog()}
      content="Tem certeza de que deseja finalizar esta vistoria? Vistorias finalizadas manualmente não poderão receber mais fotos e poderão não salvar todas as informações necessárias."
      title="Finalizar vistoria?"
      loading={loadingFinish}
      onConfirm={() => {
        handleFinishInspection();
        setShowFinishDialog();
      }}
    />
  );

  const verifyPermission = (id) => {
    if (userPermissions) {
      const foundIndex = userPermissions?.findIndex((i) => i.id_feature === id);
      const permission = userPermissions[foundIndex];
      return Boolean(permission);
    }
  };

  return (
    <Container>
      {renderFinishDialog()}
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton
              style={{ maerginRight: 5 }}
              onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Detalhes da vistoria ({dataId})</h1>
          </TitleArea>

          <section className="main">
            {data?.parent_inspection && (
              <Alert severity="warning">Vistoria complementar!</Alert>
            )}

            <div className="stepper-area">
              <Stepper
                sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: primary, // circle color (COMPLETED)
                  },
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      color: '#121212', // Just text label (COMPLETED)
                    },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: primary, // circle color (ACTIVE)
                  },
                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: primary, // Just text label (ACTIVE)
                    },
                  '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                    fill: '#fff', // circle's number (ACTIVE)
                  },
                }}
                activeStep={steps.findIndex((i) => i.status === data?.status)}
                alternativeLabel>
                {steps
                  ?.filter((s) =>
                    data?.status !== 'awaiting_complementar'
                      ? s.status !== 'awaiting_complementar'
                      : s.status
                  )
                  ?.map((step) => {
                    return (
                      <Step key={step?.label}>
                        <StepLabel>{step?.label}</StepLabel>
                      </Step>
                    );
                  })}
              </Stepper>
            </div>

            <Toolbar style={{ marginBottom: 30 }}>
              <div>
                <Button
                  onClick={() => handleQuotationLink('copy')}
                  height="35px"
                  width="160px"
                  style={{ marginRight: 10 }}>
                  <IoCopyOutline style={{ marginRight: 10 }} /> Copiar link
                </Button>
                <Button
                  onClick={() => handleQuotationLink('whatsapp')}
                  height="35px"
                  width="200px"
                  style={{ marginRight: 10 }}>
                  <SiWhatsapp style={{ marginRight: 10 }} /> Enviar para
                  WhatsApp
                </Button>
                {verifyPermission(9) && (
                  <Button
                    onClick={() => setShowFinishDialog(true)}
                    height="35px"
                    width="200px"
                    disabled={data?.status !== 'started'}
                    style={{ marginRight: 10 }}>
                    <BsClipboardCheck style={{ marginRight: 10 }} /> Finalizar
                    vistoria
                  </Button>
                )}
              </div>
            </Toolbar>

            {!data?.parent_inspection &&
              data?.status === 'awaiting_complementar' && (
                <>
                  <SectionTitle
                    color={primary}
                    title="Vistorias complementares"
                  />
                  <DataTable
                    style={{ marginBottom: 20 }}
                    persistTableHead
                    responsive
                    columns={[
                      {
                        name: 'Código da vistoria',
                        selector: (row) => (
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_V_URL}/${row?.inspection_code}`
                              );
                              toast.success(
                                'Código da vistoria copiado com sucesso!'
                              );
                            }}>
                            <IoCopyOutline
                              size={10}
                              style={{ marginRight: 5 }}
                            />
                            {row?.inspection_code}
                          </p>
                        ),
                        width: '10%',
                        sortable: true,
                      },

                      {
                        name: 'Status',
                        selector: (row) =>
                          formatInspectionStatus(row?.status)?.label,
                        width: '20%',
                        sortable: true,
                      },
                      {
                        name: 'Data solicitação',
                        selector: (row) =>
                          moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
                        width: '20%',
                        sortable: true,
                      },
                      {
                        name: 'Data vistoria',
                        selector: (row) =>
                          row?.end_date
                            ? moment(row?.end_date).format('DD/MM/YYYY HH:mm')
                            : '-',
                        width: '20%',
                        sortable: true,
                      },
                    ]}
                    data={data?.complementarInspections}
                    highlightOnHover
                    progressPending={loading}
                  />
                </>
              )}

            <SectionTitle color={primary} title="Informações da vistoria" />

            <div className="info-area">
              <InfoText
                width="25%"
                label="Tipo"
                text={
                  data?.parent_inspection
                    ? 'Vistoria complementar'
                    : 'Vistoria inicial '
                }
              />
              {data?.parent_inspection && (
                <InfoText
                  width="25%"
                  label="Vistoria mãe"
                  text={
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        margin: 0,
                        color: primary,
                      }}
                      onClick={() =>
                        window.open(`/vistoria/${data?.parent_inspection}`)
                      }>
                      <MdOpenInNew style={{ marginRight: 5 }} />
                      {data?.parent_inspection}
                    </p>
                  }
                />
              )}
              <InfoText
                width="25%"
                label="Empresa parceira"
                text={data?.partnerCompany?.name || '-'}
              />
              <InfoText
                width="25%"
                label="Status"
                text={formatStatusLabel(data?.status)}
              />

              <InfoText
                width="25%"
                label="Tipo de vistoria"
                text={data?.inspectionType?.inspection_type}
              />
              <InfoText
                width="25%"
                label="Nome do cliente"
                text={data?.client_name}
              />
              <InfoText
                width="25%"
                label="CPF do cliente"
                text={formatCPF(data?.client_cpf)}
              />
              <InfoText
                width="25%"
                label="Tipo de objeto"
                text={data?.objectType?.object_type}
              />
              <InfoText
                width="25%"
                label="Identificador"
                text={formatPlate(data?.identificator)}
              />
              <InfoText
                width="25%"
                label="Solicitada por"
                text={data?.author?.name}
              />
              <InfoText
                width="25%"
                label="Tipo de vistoria"
                text={data?.inspectionType?.inspection_type}
              />
              {/* <InfoText
                width="25%"
                label="Aplicação"
                text={formatApplication(data?.application)}
              /> */}
              <InfoText
                width="25%"
                label="Data solicitação"
                text={moment(data?.created_at).format('DD/MM/YYYY HH:mm')}
              />
            </div>

            {(data?.status === 'started' ||
              data?.status === 'finished' ||
              data?.status === 'awaiting_report') && (
              <>
                <SectionTitle color={primary} title="Telemetria" />

                <div className="info-area">
                  <InfoText
                    width="25%"
                    label="Horário de início"
                    text={
                      data?.start_date
                        ? moment(data?.start_date).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não iniciada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Horário final"
                    text={
                      data?.end_date
                        ? moment(data?.end_date).format('DD/MM/YYYY HH:mm:ss')
                        : 'Não finalizada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Duração da vistoria"
                    text={
                      data?.end_date
                        ? `${moment(data?.end_date).diff(
                            data?.start_date,
                            'minutes'
                          )} minutos`
                        : 'Não finalizada'
                    }
                  />
                  <InfoText
                    width="25%"
                    label="Dispositivo utilizado"
                    text={data?.start_device || 'Não registrado'}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '600px',
                      marginBottom: 20,
                      marginTop: 20,
                    }}>
                    <small
                      style={{
                        paddingBottom: 10,
                        fontSize: 11,
                        fontWeight: 'bold',
                      }}>
                      Localização
                    </small>
                    <ReactStreetview
                      apiKey={'AIzaSyCah_ZBdSSl8jM6WFIvDfa47GoCaOeK2tM'}
                      streetViewPanoramaOptions={{
                        position: {
                          lat: parseFloat(data?.start_location?.split(', ')[0]),
                          lng: parseFloat(data?.start_location?.split(', ')[1]),
                        },
                        pov: { heading: 100, pitch: 0 },
                        zoom: 0.5,
                      }}
                    />
                  </div>
                </div>

                <SectionTitle color={primary} title="Mídias enviadas" />

                <div className="info-area">
                  {data?.inspectioPhotos
                    ?.filter((i) => !i.complementar)
                    ?.map((photo) => (
                      <>
                        {photo?.photo?.mediatype === 'pdf' && (
                          <MediaItem
                            onClick={() => window.open(photo?.signedUrl)}>
                            <PdfPreviewer
                              onClick={() => window.open(photo?.signedUrl)}
                              fileUrl={photo?.signedUrl}
                              width={120}
                              height={150}
                            />
                          </MediaItem>
                        )}
                        {photo?.photo?.mediatype === 'video' && (
                          <MediaItem
                            onClick={() => window.open(photo?.signedUrl)}>
                            <VideoThumbnail
                              videoUrl={photo?.signedUrl}
                              width={120}
                              height={150}
                            />
                          </MediaItem>
                        )}
                        {photo?.photo?.mediatype === 'picture' && (
                          <MediaItem>
                            <img
                              onClick={() => window.open(photo?.signedUrl)}
                              className="media"
                              src={formatMediaIcon(
                                photo?.photo?.mediatype,
                                photo?.signedUrl,
                                photo?.photo_url
                              )}
                              alt="Inspection"
                            />
                          </MediaItem>
                        )}
                      </>
                    ))}
                </div>

                <SectionTitle color={primary} title="Relatório de vistoria" />

                <div className="info-area">
                  {data?.report && (
                    <>
                      <InfoText
                        width="25%"
                        label="Emitido por"
                        text={data?.report?.author?.name}
                      />
                      <InfoText
                        width="25%"
                        label="Status do relatório"
                        text={formatReportStatus(data?.report?.status)}
                      />

                      <InfoText
                        width="25%"
                        label="Observações"
                        text={
                          data?.finish_notes || 'Nenhuma observação encontrada'
                        }
                      />
                    </>
                  )}
                </div>

                <div className="button-area">
                  {!data?.hasReport && verifyPermission(8) && (
                    <Button
                      height="35px"
                      style={{ marginRight: 5 }}
                      onClick={() =>
                        history.push(
                          `/relatorio-vistoria/${data?.inspection_code}`
                        )
                      }
                      disabled={data?.status !== 'awaiting_report'}>
                      Emitir relatório
                    </Button>
                  )}

                  {data?.hasReport && (
                    <Button
                      height="35px"
                      style={{ marginRight: 5 }}
                      onClick={() =>
                        history.push(
                          `/relatorio-vistoria/${data?.inspection_code}`
                        )
                      }>
                      Visualizar
                    </Button>
                  )}
                </div>
              </>
            )}
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Details;
