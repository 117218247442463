import React, { useState, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MaskedInput from 'react-input-mask';
import NumberFormat from 'react-number-format';
import ThemeContext from 'contexts/ThemeContext';
import { Tooltip } from '@material-ui/core';
import { BiHelpCircle } from 'react-icons/bi';
import { StyledInput, StyledLabel } from './styles';
import { RxEyeOpen, RxEyeClosed } from 'react-icons/rx';
import { BiSearch } from 'react-icons/bi';
import { GrFormClose } from 'react-icons/gr';

function Input({
  search,
  darkSearch,
  icon,
  password,
  type,
  placeholder,
  mask,
  numeric,
  price,
  value,
  onChange,
  width,
  height,
  radius,
  disabled,
  handleClean,
  onKeyDown,
  label,
  iconButton,
  onIconButtonClick,
  helpText,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = showPassword ? 'text' : numeric ? 'tel' : type;
  const { primary } = useContext(ThemeContext);

  return (
    <>
      {label && (
        <StyledLabel>
          {`${label}:`}
          {helpText && (
            <Tooltip
              style={{ padding: 0, margin: 0 }}
              title={helpText}
              placement="bottom">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <BiHelpCircle style={{ marginLeft: 3 }} />
              </div>
            </Tooltip>
          )}
        </StyledLabel>
      )}
      <StyledInput
        color={primary}
        width={width}
        height={height}
        radius={radius}
        darkSearch={darkSearch}
        search={search}>
        {search && (
          <div>
            <BiSearch />
          </div>
        )}
        {icon && <div style={{ color: '#868686' }}>{icon}</div>}
        {numeric ? (
          <NumberFormat
            {...rest}
            color={primary}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            prefix={price ? 'R$ ' : ''}
            thousandSeparator="."
            placeholder={search ? 'Buscar...' : placeholder}
            decimalSeparator=","
            decimalScale={price && 2}
            disabled={disabled}
            fixedDecimalScale={price}
          />
        ) : (
          <MaskedInput
            {...rest}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder={search ? 'Buscar...' : placeholder}
            autoComplete="new-password"
            mask={mask}
            maskChar={null}
            disabled={disabled}
            type={inputType}
          />
        )}
        {password && (
          <IconButton
            style={{ height: 35 || height, width: 35 || width, padding: 0 }}
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <RxEyeOpen size={14} style={{ opacity: 0.5 }} />
            ) : (
              <RxEyeClosed size={14} style={{ opacity: 0.5 }} />
            )}
          </IconButton>
        )}
        {iconButton && (
          <IconButton
            style={{ height: 35 || height, width: 35 || width, padding: 0 }}
            onClick={onIconButtonClick}>
            {iconButton}
          </IconButton>
        )}
        {search && value && (
          <IconButton onClick={handleClean} size="small">
            <GrFormClose size={15} color="#878787" />
          </IconButton>
        )}
      </StyledInput>
    </>
  );
}

export default Input;
