import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  aspect-ratio: 16 / 9;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fafbfe;
  height: 250px;
  box-sizing: border-box;
  margin-top: 10px;
  border-radius: 5px;
`;

export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 5%;
  background-color: ${(props) => props.color};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-top: 15px;
`;

export const Icon = styled.div`
  width: 13px;
  height: 13px;
  margin-bottom: 5px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.solid ? '#fff' : 'rgba(255, 255, 255, 0.2)'};
`;

export const PageContent = styled.div`
  height: 80%;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px;
  margin: 20px;
`;

export const Text = styled.div`
  height: ${(props) => props.height || '15px'};
  width: ${(props) => props.width || '120px'};
  background-color: ${(props) => (props.light ? '#fafafa' : '#f7f7f7')};
  margin: 5px 0px;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f9fd;
  width: 100%;
  height: 25px;
  border-radius: 5px;
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0px 10px;
`;

export const LoadingArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 14px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  padding: 0px;
  font-size: 6px;
  color: #fff;
`;
