import styled from 'styled-components';
import { theme } from 'styles/colorPalette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: #fafbfe;
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  input[type='color'] {
    opacity: 0;
  }

  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }

  .horizontal {
    display: flex;
    flex-direction: row;
  }

  .right-area {
    width: 70%;
  }

  .left-area {
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .widget-area {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 1000px) {
    .widget-area {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  section {
    p {
      font-size: 14px;
    }

    .extract-text {
      font-size: 13px;
      color: #95a1ac;
      padding-left: 20px;
      span {
        color: black;
      }
    }
  }

  small {
    font-size: 10px;
    color: #95a1ac;
    width: 100%;
    text-align: right;
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;
  }

  @media (max-width: 1300px) {
    .right-area {
      display: none;
    }
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  h1 {
    margin: 0px;
    margin-left: 10px;
  }
`;

export const WidgetCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 20px;
  width: ${(props) => props.width || '50%'};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  transition: all ease-in-out 0.3s;
  background-color: ${theme.widgetBackground};
  margin: 10px;
  border-radius: 3px;

  .title {
    font-size: 24px;
    font-weight: 500;
    margin: 0px;
    color: ${(props) => props.color};
  }

  .description {
    font-size: 14px;
    margin: 0px;
    color: #95a1ac;
    margin-top: 3px;
    width: 100%;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.iconBackground};
    color: ${theme.iconColor};
    height: 45px;
    width: 45px;
    min-height: 45px;
    min-width: 45px;
    border-radius: 5px;
    margin-right: 15px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }
`;
