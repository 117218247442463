import React from 'react';
import CustomDataTable from 'react-data-table-component';

function DataTable({ ...props }) {
  const customStyles = {
    rows: {
      style: {
        fontSize: '11px',
        borderColor: '#f5f9fd',
      },
      highlightOnHoverStyle: {
        transitionDuration: '0.5s',
        transitionProperty: 'background-color',
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
    },
    cells: {
      style: {
        fontSize: '11px',
      },
    },
  };

  return <CustomDataTable customStyles={customStyles} {...props} />;
}

export default DataTable;
