import styled from 'styled-components';

export const DialogBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 30px;
  padding-bottom: 30px;

  .title-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .title {
    font-size: 20px;
  }

  .checklist-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow-y: scroll;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 10px;
    }
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    font-size: 11px;
    margin-bottom: 10px;
    cursor: pointer;
    font-style: normal;
    font-size: 12px;
    transition: all ease-in-out 0.3s;
    padding: 12px;
    &:hover {
      opacity: 0.7;
    }
  }

  .search-area {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    small {
      margin: 0px 10px;
    }
  }

  label {
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  small {
    margin-top: 30px;
    font-size: 10px;
    color: #868686;
    font-style: italic;
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
`;

export const ChecklistItem = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => props.clickable && 'pointer'};
  transition: all ease-in-out 0.2s;
  box-sizing: border-box;
  padding: 10px 10px;
  font-size: 13px;
  border-bottom: 1px solid #f2f2f2;
`;
