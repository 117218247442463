import styled from 'styled-components';
// const inputColor = '#fff';
const inputColor = '#f5f9fd';

export const StyledTextArea = styled.textarea`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${inputColor};
  border: ${(props) => (props.border ? '1px solid #eee' : 'none')};
  outline: none;
  margin: 10px 0px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 15px;
  border-radius: ${(props) => props.radius || '5px'};
  height: ${(props) => props.height || '120px'};
  width: ${(props) => props.width || '350px'};
  resize: none;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;

  &:focus {
    color: ${(props) => props.color};
  }

  &:disabled {
    background-color: #f5f9fd;
    color: #a8a8a8;
  }
`;
