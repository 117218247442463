/* eslint-disable jsx-a11y/alt-text */
import moment from 'moment';
import { formatPhone } from 'react-data-formatter';
import { Avatar } from 'components';

export const dataColumns = [
  {
    name: '',
    selector: (row) => (
      <Avatar src={row?.picture} content={row?.name?.slice(0, 2)} size={25} />
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row?.name,
    width: '20%',
    sortable: true,
  },
  {
    name: 'E-mail',
    selector: (row) => row?.email || 'Não informado',
    width: '20%',
    sortable: true,
  },
  {
    name: 'Telefone',
    selector: (row) => formatPhone(row?.phone) || 'Não informado',
    width: '10%',
    sortable: true,
  },
  {
    name: 'Perfil',
    selector: (row) => row?.user_role?.name || 'Não informado',
    width: '10%',
    sortable: true,
  },

  {
    name: 'Status',
    selector: (row) => (row?.active ? 'Ativo' : 'Inativo'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
];
