import React from 'react';
import { Container } from './styles';

function Item({ children, icon, selected }) {

  return <Container selected={selected} icon={icon}>
    <span selected={selected}>{icon}</span>&nbsp;{children}
  </Container>
}

export default Item;