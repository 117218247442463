import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: hidden;
  background-color: #fafbfe;
`;

export const ContentArea = styled.div`
  display: flex;
  margin: 20px 30px;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  padding-right: 10px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
    border-radius: 10px;
  }

  section {
    margin-bottom: 12px;
  }

  .main {
    width: 100%;
  }

  .title-area {
    font-size: 13px;
  }

  .body-area {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .button-area {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 30px;
    padding-left: 20px;
  }

  .info-area {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 20px;
  }

  .media {
    height: 150px;
    width: 120px;
    margin: 0px 15px 15px 0px;
    border-radius: 3;
    object-fit: cover;
    border: 1px solid #eee;
    border-radius: 5px;
    transition: all ease-in-out 0.3s;
    cursor: zoom-in;
    &:hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 1000px) {
    margin: 5px;
    padding: 10px;

    .button-area {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const LoadingArea = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const TitleArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-top: 30px;
  h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    margin-left: 10px;
  }
`;

export const DataArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65vh !important;
  max-height: 65vh !important;
  overflow: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 10px;
  }

  .partnerIcon {
    height: 20px;
    width: 20px;
    border-radius: 2px;
  }

  @media (max-width: 1000px) {
    margin-top: 20px;
    padding: 20px;
  }
`;

export const FilterArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  height: 100%;
  background-color: #fefefe;
  box-sizing: border-box;
  padding: 20px;
  h1 {
    font-size: 20px;
    margin-bottom: 30px;
  }
  label {
    width: 100%;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-transform: uppercase;
    padding-left: 10px;
  }
`;

export const SwitchGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: ${(props) => props.width || '300px'};
  background-color: #f5f9fd;
  height: 40px;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 5px;

  p {
    font-size: 12px;
    color: #242424;
    width: 99%;
  }
`;

export const InspectionImage = styled.div`
  height: 350px;
  max-height: 350px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: zoom-in;
  transition: all ease-in-out 0.3s;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 900px) {
    height: 250px;
  }
`;

export const InspectionVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  max-height: 350px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  background-color: #fff;
  cursor: zoom-in;
`;

export const MediaReview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-left: 6px solid #eee;
  border-left: ${(props) => props.status === 'approved' && '6px solid #2ecc71'};
  border-left: ${(props) => props.status === 'rejected' && '6px solid #e74c3c'};
  border-left: ${(props) =>
    props.status === 'fraud_suspect' && '6px solid #e74c3c'};
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px 20px;
  margin-bottom: 20px;
  transition: all ease-in-out 0.3s;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);

  .media-area {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .review-area {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 16px;
      margin: 10px 0px;
      font-weight: 300;
    }

    small {
      color: #bfbfbf;
      font-style: italic;
    }
  }

  .review-options {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const DialogArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 50px;
  span {
    font-weight: bold;
  }
  p {
    margin: 5px 0px;
    font-size: 14px;
  }
`;

export const InspectionPdf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  max-height: 350px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  background-color: #fff;
  cursor: zoom-in;
  border: none;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;
