/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';

import {
  SideMenu,
  SectionTitle,
  Input,
  Select,
  Button,
  Switch,
} from 'components';
import { Container, ContentArea, TitleArea } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';

function Update({ history, location }) {
  const userId = parseInt(location.pathname.split('/usuarios/')[1]);

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [data, setData] = useState();
  const [rolesList, setRolesList] = useState([]);
  const { primary, loadingColor } = useContext(ThemeContext);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/user/${userId}`);
    setData(resp?.data);

    const respRoles = await api.get('/list-roles');
    setRolesList(respRoles?.data?.data);

    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoadingUpdate(true);

    try {
      await api.put(`/user/${userId}`, {
        name: data?.name,
        email: data?.emai,
        phone: data?.phone,
        role: data?.id_role,
        active: data?.active,
      });
      setLoadingUpdate(false);
      toast.success('Alterações realizadas com sucesso!');
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Usuário</h1>
          </TitleArea>

          <section>
            <SectionTitle color={primary} title="Dados do usuário" />

            <div className="form">
              <Input
                height="35px"
                width="380px"
                label="Nome"
                value={data?.name}
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value.toUpperCase(),
                  })
                }
              />

              <Input
                height="35px"
                width="380px"
                label="E-mail"
                value={data?.email}
                onChange={(e) =>
                  setData({
                    ...data,
                    email: e.target.value.toUpperCase(),
                  })
                }
              />

              <Input
                height="35px"
                width="380px"
                label="Telefone"
                mask="99 99999-9999"
                value={data?.phone}
                onChange={(e) =>
                  setData({
                    ...data,
                    phone: e.target.value.toUpperCase(),
                  })
                }
              />

              <Select
                label="Perfil de acesso"
                height="35px"
                width="380px"
                value={data?.id_role}
                onChange={(e) =>
                  setData({
                    ...data,
                    id_role: e.target.value.toUpperCase(),
                  })
                }>
                {rolesList?.map((role) => (
                  <option value={role?.id}>{role?.name}</option>
                ))}
              </Select>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <small style={{ fontSize: 11 }}>Ativo</small>
                <Switch
                  checked={data?.active === 1 ? true : false}
                  onChange={(e) =>
                    setData({
                      ...data,
                      active: data?.active ? 0 : 1,
                    })
                  }
                  color="primary"
                />
              </div>

              <Button
                loading={loadingUpdate}
                onClick={handleUpdate}
                height="35px"
                width="380px"
                style={{ marginTop: 30 }}
                disabled={
                  loadingUpdate ||
                  !data?.name ||
                  !data?.email ||
                  !data?.phone ||
                  !data?.role
                }>
                Salvar alterações
              </Button>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Update;
