import React from 'react';
import { BsClipboardCheckFill } from 'react-icons/bs';
import { StyledInspectionBadge } from './styles';

function InspectionBadge({
  code,
  complementar,
  color,
  colorComplementar,
  width,
  height,
  ...props
}) {
  return (
    <StyledInspectionBadge
      color={color}
      width={width}
      height={height}
      {...props}>
      <BsClipboardCheckFill style={{ marginRight: 5 }} />
      {code}
    </StyledInspectionBadge>
  );
}

export default InspectionBadge;
