import styled from 'styled-components';

export const StyledInspectionBadge = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => `${props.width}` || '60px'};
  height: ${(props) => `${props.height}` || '20px'};
  font-size: 16px;
  font-weight: normal;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.complementar ? props.colorComplementar : props.color || '#ddd'};
  color: #fff;
  margin-left: 10px;
  border-radius: 3px;
  padding: 5px 10px;
`;
