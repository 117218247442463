/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createContext } from 'react';

import api from 'api';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const company = window.location.hostname.split('.vistei')[0];

  const [theme, setTheme] = useState(null);

  const loadTheme = async () => {
    try {
      const response = await api(`/company-theme/${company}`);

      const themeLoaded = {
        ...JSON.parse(response?.data?.company_theme),
        ...JSON.parse(response?.data?.company_logo),
        name: response?.data?.company_name,
      };

      setTheme(themeLoaded);

      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');

      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = themeLoaded?.iconLogo;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = `${themeLoaded?.name} - Vistei`;
    } catch (error) {
      const themeLoaded = {
        primary: '#535bf2',
        secondary: '#535bf2',
        loadingColor: '#535bf2',
        defaultLogo:
          'https://vst-company.s3.amazonaws.com/vistei/vistei-logo-new.png',
        iconLogo: 'https://www.vistei.app/favicon.png',
        lightLogo:
          'https://vst-company.s3.amazonaws.com/vistei/vistei-logo-light.png',
        name: 'Vistei',
      };

      setTheme(themeLoaded);
    }
  };

  useEffect(() => {
    loadTheme();
  }, []);

  return (
    <ThemeContext.Provider value={{ ...theme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

// primary: '#535bf2',
// secondary: '#535bf2',
// ternary: '#535bf2',
// defaultLogo:
//   'https://vst-company.s3.amazonaws.com/vistei/vistei-logo-new.png',
// iconLogo: 'https://www.vistei.app/favicon.png',
// lightLogo:
//   'https://vst-company.s3.amazonaws.com/vistei/vistei-logo-light.png',
// name: 'Vistei',
