/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from 'react';
import api from 'api';

const PermissionContext = createContext(0);

export const PermissionProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState(null);
  const [loadingPermissions, setLoadingPermissions] = useState(false);

  const loadUserPermissions = async () => {
    setLoadingPermissions(true);
    const resp = await api('/user-permissions');
    setUserPermissions(resp.data);
    setLoadingPermissions(false);
  };

  useEffect(() => {
    loadUserPermissions();
  }, []);

  return (
    <PermissionContext.Provider
      value={{ userPermissions, loadingPermissions, loadUserPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContext;
