/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import api from 'api';
import moment from 'moment';

// import { formatCurrency } from 'react-data-formatter';
// import { formatBrNumber } from 'utils/formatNumber';
import { formatCurrency, formatBrNumber } from 'dev-js-toolbox';

import { SideMenu, SectionTitle, Button, Toolbar } from 'components';
import { Container, ContentArea, TitleArea, WidgetCard } from './styles';
import { IconButton } from '@material-ui/core';
import { FaFileInvoiceDollar, FaChartLine } from 'react-icons/fa';
import { TbArrowsDownUp } from 'react-icons/tb';
import {
  BsFillClipboardCheckFill,
  BsFillClipboardDataFill,
  BsArrowLeft,
  BsArrowRight,
} from 'react-icons/bs';

function Financial({ history }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [selectedMonth, setSelectedMonth] = useState(
    moment().startOf('month').format()
  );

  const { primary, loadingColor } = useContext(ThemeContext);

  const loadData = async () => {
    setLoading(true);
    const resp = await api({
      method: 'POST',
      url: `/financial`,
      data: { date: selectedMonth },
    });
    setData(resp?.data);
    setLoading(false);
  };

  const handleLastMonth = () => {
    setSelectedMonth(
      moment(selectedMonth).subtract(1, 'month').startOf('month').format()
    );
  };

  const handleNextMonth = () => {
    setSelectedMonth(
      moment(selectedMonth).add(1, 'month').startOf('month').format()
    );
  };

  useEffect(() => {
    loadData();
  }, [selectedMonth]);

  return (
    <Container>
      <SideMenu />
      {
        <ContentArea>
          <TitleArea>
            <IconButton
              style={{ maerginRight: 5 }}
              onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Financeiro</h1>
          </TitleArea>

          <Toolbar>
            <div>
              <Button
                height="30px"
                width="150px"
                onClick={handleLastMonth}
                disabled={loading}>
                <BsArrowLeft style={{ marginRight: 10 }} />
                Mês anterior
              </Button>
              <Button
                onClick={handleNextMonth}
                height="30px"
                width="150px"
                style={{ marginLeft: 15 }}
                disabled={
                  loading ||
                  moment(selectedMonth).startOf('month').format() ===
                    moment().startOf('month').format()
                }>
                Mês seguinte
                <BsArrowRight style={{ marginLeft: 10 }} />
              </Button>
            </div>
          </Toolbar>

          {loading && (
            <div className="loading-area">
              <ReactLoading
                type="spin"
                color={loadingColor}
                height={45}
                width={45}
              />
            </div>
          )}

          {!loading && (
            <section>
              <SectionTitle
                color={primary}
                title={moment(selectedMonth).format('MMMM/YYYY').toUpperCase()}
              />

              <div className="widget-area">
                <WidgetCard width={'250px'} color={primary}>
                  <div className="icon">
                    <BsFillClipboardDataFill size={20} />
                  </div>
                  <div>
                    <p className="title">
                      {formatBrNumber(data?.requestedInitialInspections || 0)}
                    </p>
                    <p className="description">Vistorias solicitadas</p>
                  </div>
                </WidgetCard>

                <WidgetCard width={'250px'} color={primary}>
                  <div className="icon">
                    <BsFillClipboardCheckFill size={20} />
                  </div>
                  <div>
                    <p className="title">
                      {formatBrNumber(data?.finishedInitialInspections || 0)}
                    </p>
                    <p className="description">Vistorias finalizadas</p>
                  </div>
                </WidgetCard>

                <WidgetCard width={'250px'} color={primary}>
                  <div className="icon">
                    <FaChartLine e size={20} />
                  </div>
                  <div>
                    <p className="title">
                      {formatCurrency(data?.projectedInspectionsValue || 0)}
                    </p>
                    <p className="description">Valor (projeção)</p>
                  </div>
                </WidgetCard>

                <WidgetCard width={'250px'} color={primary}>
                  <div className="icon">
                    <FaFileInvoiceDollar size={20} />
                  </div>
                  <div>
                    <p className="title">
                      {formatCurrency(data?.finishedInspectionsValue || 0)}
                    </p>
                    <p className="description">Valor atual</p>
                  </div>
                </WidgetCard>

                <WidgetCard width={'250px'} color={primary}>
                  <div className="icon">
                    <TbArrowsDownUp size={20} />
                  </div>
                  <div>
                    <p className="title">
                      {data?.lastMonthVariation > 0 && '+'}
                      {data?.lastMonthVariation * 100}%
                    </p>
                    <p className="description">Mês anterior</p>
                  </div>
                </WidgetCard>
              </div>
            </section>
          )}

          {!loading && (
            <section>
              <SectionTitle color={primary} title="Detalhes" />

              <p className="extract-text">
                Vistorias iniciais solicitadas:{' '}
                <span>
                  {formatBrNumber(data?.requestedInitialInspections || 0)}
                </span>
              </p>
              <p className="extract-text">
                Vistorias iniciais finalizadas:{' '}
                <span>
                  {formatBrNumber(data?.finishedInitialInspections || 0)}
                </span>
              </p>

              <p className="extract-text">
                Vistorias complementares solicitadas:{' '}
                <span>
                  {formatBrNumber(data?.requestedComplementarInspections || 0)}
                </span>
              </p>
              <p className="extract-text">
                Vistorias complementares finalizadas:{' '}
                <span>
                  {formatBrNumber(data?.finishedComplementarInspections || 0)}
                </span>
              </p>

              <p className="extract-text">
                Total vistorias solicitadas:{' '}
                <span>
                  {formatBrNumber(
                    data?.requestedInitialInspections +
                      data?.requestedComplementarInspections || 0
                  )}
                </span>
              </p>
              <p className="extract-text">
                Total vistorias finalizadas:{' '}
                <span>
                  {formatBrNumber(
                    data?.finishedInitialInspections +
                      data?.finishedComplementarInspections || 0
                  )}
                </span>
              </p>

              {moment(selectedMonth).startOf('month').format() ===
                moment().startOf('month').format() && (
                <p className="extract-text">
                  Projetado mês selecionado:{' '}
                  <span>
                    {formatCurrency(data?.projectedInspectionsValue || 0)}
                  </span>
                </p>
              )}
              <p className="extract-text">
                Realizado mês selecionado:{' '}
                <span>
                  {formatCurrency(data?.finishedInspectionsValue || 0)}
                </span>
              </p>

              {moment(selectedMonth).startOf('month').format() !==
                moment().startOf('month').format() && (
                <p className="extract-text">
                  Valor final:{' '}
                  <span>
                    {formatCurrency(data?.finishedInspectionsValue || 0)}
                  </span>
                </p>
              )}
            </section>
          )}
        </ContentArea>
      }
    </Container>
  );
}

export default Financial;
