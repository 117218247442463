/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import PasswordStrengthBar from 'react-password-strength-bar';
import api from 'api';

import {
  SideMenu,
  Avatar,
  Input,
  Button,
  SectionTitle,
  Menu,
} from 'components';
import { Container, ContentArea, TitleArea, MenuItem } from './styles';
import { toast } from 'react-toastify';
import { CiLock } from 'react-icons/ci';
import { TbPhotoMinus, TbPhotoPlus, TbPhotoEdit } from 'react-icons/tb';
import { saveFilesBucket } from 'utils/aws';
import { formatRoles } from 'utils/formatRoles';

function UserProfile({ history }) {
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const { primary, loadingColor } = useContext(ThemeContext);
  const fileInputRef = useRef();

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get('/me');
    setData(resp?.data);
    setEditData(resp?.data);
    setLoading(false);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoadingChangePassword(true);
    try {
      await api.post('/user/password/change', {
        oldPassword: password,
        newPassword,
      });
      setPassword('');
      setNewPassword('');
      setLoadingChangePassword(false);
      toast.success('Senha alterada com sucesso');
    } catch (e) {
      setLoadingChangePassword(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleUpdateInfo = async () => {
    setLoadingUpdate(true);
    try {
      await api.put(`/user/${data?.id}`, {
        name: editData?.name,
        email: editData?.email,
        phone: editData?.phone,
        picture: editData?.picture,
      });
      toast.success('Dados alterados com sucesso');
      setLoadingUpdate(false);
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleChangePhoto = async (img) => {
    setAnchorEl(null);
    setLoadingUpload(true);

    const ext = img?.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: 'vst-pictures',
      Body: img,
      Key: `${data?.id}/${new Date().getTime()}.${ext}`,
      onEnd: async (err, data) => {
        try {
          setEditData({
            ...editData,
            picture: `https://vst-pictures.s3.amazonaws.com/${data?.file}`,
          });
          setLoadingUpload(false);
          return data?.file;
        } catch (e) {
          console.log(err);
          console.log(e);
          setLoadingUpload(false);
        }
      },
    });
  };

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setEditData({
        ...editData,
        picture: URL.createObjectURL(e.target.files[0]),
      });
      handleChangePhoto(e.target.files[0]);
    }
  };

  const handleRemovePhoto = async (img) => {
    setEditData({
      ...editData,
      picture: null,
    });
    setAnchorEl(null);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Avatar
                src={editData?.picture}
                editable
                loading={loadingUpload}
                content={data?.name?.slice(0, 2)}
                size={60}
                color={primary}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => fileInputRef.current.click()}>
                  {editData?.picture ? (
                    <TbPhotoEdit style={{ marginRight: 5 }} />
                  ) : (
                    <TbPhotoPlus style={{ marginRight: 5 }} />
                  )}
                  {editData?.picture ? 'Alterar foto' : 'Adicionar foto'}
                </MenuItem>
                {editData?.picture && (
                  <MenuItem onClick={handleRemovePhoto}>
                    <TbPhotoMinus style={{ marginRight: 5 }} />
                    Remover foto
                  </MenuItem>
                )}
              </Menu>

              <div style={{ marginLeft: 20 }}>
                <h1 style={{ fontSize: 26 }}>{data?.name}</h1>
                <p style={{ margin: 0, fontSize: 14, color: '#868686' }}>
                  {formatRoles(data?.role)}
                </p>
              </div>
            </div>
          </TitleArea>

          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleImg}
          />

          <section>
            <SectionTitle color={primary} title="Informações pessoais" />

            <div className="form">
              <Input
                height="35px"
                label="Nome completo"
                value={editData?.name}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    name: e.target.value?.toUpperCase(),
                  })
                }
              />
              <Input
                height="35px"
                label="E-mail"
                value={editData?.email}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    email: e.target.value?.toUpperCase(),
                  })
                }
              />
              <Input
                height="35px"
                label="Telefone"
                value={editData?.phone}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    email: e.target.value?.toUpperCase(),
                  })
                }
              />
              <Button
                height="35px"
                style={{ marginTop: 20 }}
                loading={loadingUpdate}
                onClick={handleUpdateInfo}>
                Alterar informações
              </Button>
            </div>
          </section>

          <section style={{ marginTop: 35 }}>
            <SectionTitle color={primary} title="Alterar senha" />
            <div className="form">
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                height="35px"
                password
                type="password"
                label="Senha atual"
                placeholder="Insira a senha atual"
                icon={<CiLock />}
              />
              <Input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                height="35px"
                password
                type="password"
                label="Nova senha"
                placeholder="Insira a nova senha"
                icon={<CiLock />}
              />
              {newPassword && (
                <PasswordStrengthBar
                  style={{ width: 300, fontSize: 10 }}
                  password={newPassword}
                  minLength={6}
                  shortScoreWord="Senha muito curta"
                  scoreWords={[
                    'Senha fraca',
                    'Senha fraca',
                    'Senha OK',
                    'Senha OK',
                    'Senha forte',
                  ]}
                />
              )}

              <Button
                height="35px"
                disabled={loadingChangePassword || !password || !newPassword}
                style={{ marginTop: 20 }}
                loading={loadingChangePassword}
                onClick={handleChangePassword}>
                Alterar senha
              </Button>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default UserProfile;
