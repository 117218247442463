/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import api from 'api';
import {
  SideMenu,
  DataTable,
  Input,
  Button,
  Toolbar,
  ConfirmDialog,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  DataArea,
  LoadingArea,
} from './styles';
import { dataColumns } from './helper';
import { toast } from 'react-toastify';
import { TbTrashX } from 'react-icons/tb';
import { BiDuplicate } from 'react-icons/bi';
import { IconButton, Tooltip } from '@material-ui/core';
import { BsArrowLeft } from 'react-icons/bs';

function List({ history }) {
  const scrollParent = useRef();
  const { loadingColor } = useContext(ThemeContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasNextPage, setHasNextPage] = useState();
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState();
  const [showConfirm, setShowConfirm] = useState();

  const loadData = async () => {
    setLoading(true);
    setDataList([]);
    const resp = await api.post(
      '/photos',
      { page: currentPage, take: 20 },
      {
        headers: {
          search,
        },
      }
    );
    setDataList(resp?.data);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage) {
      setLoadingMore(true);

      const resp = await api.post(
        '/photos',
        { page, take: 10 },
        {
          headers: {
            search,
          },
        }
      );

      const data = dataList;
      const ndata = data.concat(resp?.data);

      setCurrentPage(page);
      setDataList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    try {
      await api.delete(`/photo/${showConfirm?.id}`);
      setLoadingDelete(false);
      toast.success('Tipo de mídia excluído com sucesso!');
      loadData();
    } catch (e) {
      setLoadingDelete(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={Boolean(showConfirm)}
      onCancel={() => setShowConfirm()}
      content="Tem certeza de que deseja excluir este tipo de mídia? "
      title="Excluir tipo de mídia"
      loading={loadingDelete}
      onConfirm={() => {
        handleDelete();
        setShowConfirm();
      }}
    />
  );

  const deleteDataColumns = [
    ...dataColumns,
    {
      name: '',
      selector: (row) => (
        <>
          <Tooltip
            title="Criar a partir do modelo"
            placement="right"
            onClick={() =>
              history.push({
                pathname: `/tipos-midias/novo`,
                state: { mediaData: row },
              })
            }>
            <IconButton>
              <BiDuplicate size={15} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Excluir"
            placement="right"
            onClick={() => setShowConfirm(row)}>
            <IconButton>
              <TbTrashX size={15} />
            </IconButton>
          </Tooltip>
        </>
      ),
      width: '10%',
      sortable: true,
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      {renderConfirmDialog()}
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <IconButton
            style={{ maerginRight: 5 }}
            onClick={() => history?.goBack()}>
            <BsArrowLeft size={16} />
          </IconButton>
          <h1>Tipos de mídias</h1>
        </TitleArea>
        <Toolbar>
          <div>
            <Button
              onClick={() => history.push('/tipos-midias/novo')}
              height="30px"
              width="150px"
              style={{ marginRight: 10 }}>
              Adicionar novo
            </Button>
          </div>
          <div>
            <Input
              search
              handleClean={() => {
                setSearch();
                loadData();
              }}
              height="32px"
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadData();
                }
                if (e.key === 'Escape') {
                  setSearch();
                  loadData();
                }
              }}
            />
          </div>
        </Toolbar>
        <DataArea ref={scrollParent}>
          <InfiniteScroll
            loadMore={handleLoadMore}
            hasMore={hasNextPage}
            useWindow={false}
            getScrollParent={() => scrollParent?.current}
            initialLoad={false}
            style={{ width: '100%', display: 'flex' }}
            threshold={10}>
            <DataTable
              onRowClicked={(row) => history.push(`/tipos-midias/${row?.id}`)}
              persistTableHead
              responsive
              columns={deleteDataColumns}
              data={dataList}
              highlightOnHover
              pointerOnHover
              progressPending={loading}
              progressComponent={
                <LoadingArea>
                  <ReactLoading
                    color={loadingColor}
                    height={32}
                    width={32}
                    type="spin"
                  />
                </LoadingArea>
              }
              noDataComponent={
                <small style={{ marginTop: 20 }}>
                  Nenhum resultado encontrado
                </small>
              }
            />
          </InfiniteScroll>
        </DataArea>

        {loadingMore && (
          <LoadingArea>
            <ReactLoading
              type="spin"
              color={loadingColor}
              height={25}
              width={25}
            />
          </LoadingArea>
        )}
      </ContentArea>
    </Container>
  );
}

export default List;
