import styled from 'styled-components';

export const BaseAvatar = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  height: ${(props) => (props.size ? `${props.size}px` : '35px')};
  width: ${(props) => (props.size ? `${props.size}px` : '35px')};
  color: ${(props) => props.color};
  transition: all ease-in-out 0.3s;
  font-size: ${(props) => (props.size ? `${props.size / 3}px` : '14px')};
  font-weight: bold;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  cursor: ${(props) => (props.editable ? 'pointer' : 'default')};
`;

export const Picture = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const EditArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 30%;
  width: 100%;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 5px 5px;
  color: #fff;
  font-size: 9px;
  font-weight: 400;
  transition: all ease-in-out 0.3s;
  opacity: 0;

  ${BaseAvatar}:hover & {
    opacity: 1;
  }
`;
