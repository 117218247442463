/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  Container,
  SideMenu,
  Icon,
  PageContent,
  Text,
  Toolbar,
  LoadingArea,
  Button,
} from './styles';
import ReactLoading from 'react-loading';

function ScreenPreview({
  primary = '#535bf2',
  secondary = '#535bf2',
  loadingColor = '#535bf2',
  ...props
}) {
  return (
    <Container {...props}>
      <SideMenu color={primary}>
        <Icon />
        <Icon solid />
        <Icon />
        <Icon />
        <Icon />
        <Icon />
        <Icon />
        <Icon />
        <Icon />
      </SideMenu>
      <PageContent>
        <Text />
        <Toolbar>
          <Button color={secondary}>Button Label</Button>
        </Toolbar>
        <LoadingArea>
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={12}
            width={12}
          />
        </LoadingArea>
      </PageContent>
    </Container>
  );
}

export default ScreenPreview;
