/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import api from 'api';

import { SideMenu, SectionTitle, Input, Select, Button } from 'components';
import { Container, ContentArea, TitleArea } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';
import { IconButton } from '@material-ui/core';

function Create({ history }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState('');
  const { primary } = useContext(ThemeContext);

  const handleInvite = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/user/invite', { name, email, role });

      toast.success('Usuário convidado com sucesso!');
      setLoading(false);
      history.push('/convites');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const loadData = async () => {
    const resp = await api.get('/list-roles');
    setRolesList(resp.data.data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <IconButton
            style={{ maerginRight: 5 }}
            onClick={() => history?.goBack()}>
            <BsArrowLeft size={16} />
          </IconButton>
          <h1>Convidar usuário</h1>
        </TitleArea>
        <section>
          <SectionTitle color={primary} title="Dados do usuário" />

          <div className="form">
            <Input
              height="35px"
              width="380px"
              label="Nome"
              value={name}
              onChange={(e) => setName(e.target.value.toUpperCase())}
            />
            <Input
              height="35px"
              width="380px"
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value?.toLowerCase())}
            />
            <Select
              height="35px"
              width="380px"
              label="Perfil de acesso"
              value={role}
              onChange={(e) => setRole(e.target.value)}>
              <option value="">Selecione o perfil</option>
              {rolesList?.map((role) => (
                <option value={role?.id}>
                  {role?.name} ({role?.description})
                </option>
              ))}
            </Select>

            <Button
              loading={loading}
              onClick={(e) => handleInvite(e)}
              height="35px"
              width="380px"
              style={{ marginTop: 30 }}
              disabled={loading || !name || !email || !role}>
              Convidar
            </Button>
          </div>
        </section>
      </ContentArea>
    </Container>
  );
}

export default Create;
