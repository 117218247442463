import React from 'react';
import ReactLoading from 'react-loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from 'components';

function ConfirmDialog({
  open,
  onClose,
  title,
  content,
  onConfirm,
  onCancel,
  childComponent,
  loading,
}) {
  return (
    <Dialog open={open} onClose={onClose} onCancel={onClose}>
      <div style={{ padding: 10 }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
        {childComponent && <DialogContent>{childComponent}</DialogContent>}
        <DialogActions>
          <Button
            onClick={() => onCancel()}
            height="30px"
            width="120px"
            outlined>
            Cancelar
          </Button>
          <Button
            disabled={loading}
            onClick={() => onConfirm()}
            height="30px"
            width="120px">
            {loading ? (
              <ReactLoading type="spin" color="#fff" height={25} width={25} />
            ) : (
              'Confirmar'
            )}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default ConfirmDialog;
