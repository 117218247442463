/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';

import { SideMenu, SectionTitle, Input, Button } from 'components';
import { Container, ContentArea, TitleArea } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';

function Create({ history }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { primary } = useContext(ThemeContext);

  const handleCreateObjectType = async () => {
    setLoading(true);
    try {
      await api.post('/object-type/new', {
        object_type: data?.objectType,
        identificator: data?.identificator,
      });
      setLoading(false);
      history.push('/tipos-objetos');
      toast.success('Tipo de objeto criado com sucesso!');
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data?.error);
    }
  };

  return (
    <Container>
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <IconButton
            style={{ maerginRight: 5 }}
            onClick={() => history?.goBack()}>
            <BsArrowLeft size={16} />
          </IconButton>
          <h1>Tipo de objeto</h1>
        </TitleArea>
        <section>
          <SectionTitle color={primary} title="Adicionar novo" />

          <div className="form">
            <Input
              height="35px"
              width="380px"
              label="Tipo de objeto"
              value={data?.objectType}
              onChange={(e) =>
                setData({ ...data, objectType: e.target.value.toUpperCase() })
              }
            />

            <Input
              height="35px"
              width="380px"
              label="Identificador"
              helpText="Identificador é o tipo de informação única que identificará um objeto vistoriado. Ex.: Para automóveis a placa é um possível identificador."
              value={data?.identificator}
              onChange={(e) =>
                setData({
                  ...data,
                  identificator: e.target.value?.toUpperCase(),
                })
              }
            />

            <Button
              loading={loading}
              onClick={handleCreateObjectType}
              height="35px"
              width="380px"
              style={{ marginTop: 30 }}
              disabled={loading || !data?.objectType || !data?.identificator}>
              Adicionar
            </Button>
          </div>
        </section>
      </ContentArea>
    </Container>
  );
}

export default Create;
