import moment from 'moment';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Tipo de objeto',
    selector: (row) => row?.object_type?.toUpperCase(),
    width: '20%',
    sortable: true,
  },
  {
    name: 'Identificador',
    selector: (row) => row?.identificator?.toUpperCase(),
    width: '20%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '40%',
    sortable: true,
  },
];
