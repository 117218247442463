import moment from 'moment';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row?.question,
    width: '40%',
    sortable: true,
  },

  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '40%',
    sortable: true,
  },
];
