/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useRef, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import api from 'api';

import { toast } from 'react-toastify';
import { GrClose } from 'react-icons/gr';
import { BsArrowLeft } from 'react-icons/bs';
import {
  SideMenu,
  Input,
  SectionTitle,
  Button,
  ScreenPreview,
  Toolbar,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  ImagePreview,
  ColorPicker,
} from './styles';
import { IconButton } from '@material-ui/core';
import { saveFilesBucket } from 'utils/aws';

function Create({ history }) {
  const [data, setData] = useState();
  const [companyData, setCompanyData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const { primary, loadingColor } = useContext(ThemeContext);

  const colorPicker = useRef();
  const colorPicker2 = useRef();
  const colorPicker3 = useRef();

  const fileInputRef = useRef();
  const fileInputRef2 = useRef();
  const fileInputRef3 = useRef();

  const handleCreateCompany = async () => {
    setLoadingCreate(true);

    try {
      const resp = await api.post('/partner-company', {
        name: data?.name,
        description: data?.description,
        active: 1,
        company_logo: JSON.stringify(data?.logos),
        company_theme: JSON.stringify(data?.theme),
      });
      setLoadingCreate(false);
      toast.success('Empresa parceira cadastrada com sucesso!');
      history.push(`/empresa-parceira/${resp?.data?.id}`);
    } catch (e) {
      setLoadingCreate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const handleChangePhoto = async (img, type) => {
    const ext = img?.name.replaceAll(' ', '').toLowerCase().split('.').pop();

    saveFilesBucket({
      Bucket: 'vst-company',
      Body: img,
      Key: `${
        companyData?.subdomain
      }/subcompanies/logo/${new Date().getTime()}.${ext}`,
      onEnd: async (err, imgData) => {
        try {
          setData({
            ...data,
            logos: {
              ...data?.logos,
              [type]: `https://vst-company.s3.us-east-1.amazonaws.com/${imgData?.file}`,
            },
          });

          setLoadingUpload(false);
          return imgData?.file;
        } catch (e) {
          toast.error('Erro ao enviar imagem. Por favor, tente novamente.');
          console.log(err);
          console.log(e);
          setLoadingUpload(false);
        }
      },
    });
  };

  const handleImg = async (e, type) => {
    if (e.target.files[0]) {
      setLoadingUpload(true);
      setData({
        ...data,
        logos: {
          ...data?.logos,
          [type]: URL.createObjectURL(e.target.files[0]),
        },
      });
      handleChangePhoto(e.target.files[0], type);
    }
  };

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/my-company`);
    setCompanyData(resp?.data);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />

      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}

      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton
              style={{ maerginRight: 5 }}
              onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Empresa parceira</h1>
          </TitleArea>

          <Toolbar>
            <Button
              loading={loadingCreate}
              onClick={handleCreateCompany}
              height="30px"
              width="150px">
              Salvar alterações
            </Button>
          </Toolbar>

          <section>
            <SectionTitle color={primary} title="Dados cadastrais" />
            <div>
              <Input
                height="35px"
                value={data?.name}
                width="380px"
                label="Nome da empresa"
                onChange={(e) =>
                  setData({
                    ...data,
                    name: e.target.value,
                  })
                }
              />
              <Input
                height="35px"
                value={data?.description}
                width="380px"
                label="Descrição"
                onChange={(e) =>
                  setData({
                    ...data,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </section>

          <section style={{ marginTop: 25 }}>
            <SectionTitle color={primary} title="Identidade visual" />
            <div className="form horizontal">
              <div className="left-area">
                <label>Cor primária</label>
                <ColorPicker
                  color={data?.theme?.primary}
                  onClick={() => {
                    colorPicker.current.click();
                  }}>
                  <div className="color" />
                  <div className="info">
                    <p>Cor selecionada</p>
                    <small>{data?.theme?.primary}</small>
                  </div>
                  <input
                    className="color-picker"
                    ref={colorPicker}
                    type="color"
                    value={data?.theme?.primary}
                    onChange={(e) => {
                      setData({
                        ...data,
                        theme: { ...data?.theme, primary: e.target.value },
                      });
                    }}
                  />
                </ColorPicker>

                <label>Cor secundária</label>
                <ColorPicker
                  color={data?.theme?.secondary}
                  onClick={() => {
                    colorPicker2.current.click();
                  }}>
                  <div className="color" />
                  <div className="info">
                    <p>Cor selecionada</p>
                    <small>{data?.theme?.secondary}</small>
                  </div>
                  <input
                    ref={colorPicker2}
                    className="color-picker"
                    type="color"
                    value={data?.theme?.secondary}
                    onChange={(e) =>
                      setData({
                        ...data,
                        theme: { ...data?.theme, secondary: e.target.value },
                      })
                    }
                  />
                </ColorPicker>

                <label>Cor carregamento</label>
                <ColorPicker
                  color={data?.theme?.loadingColor}
                  onClick={() => {
                    colorPicker3.current.click();
                  }}>
                  <div className="color" />
                  <div className="info">
                    <p>Cor selecionada</p>
                    <small>{data?.theme?.loadingColor}</small>
                  </div>
                  <input
                    ref={colorPicker3}
                    className="color-picker"
                    type="color"
                    value={data?.theme?.loadingColor}
                    onChange={(e) =>
                      setData({
                        ...data,
                        theme: { ...data?.theme, loadingColor: e.target.value },
                      })
                    }
                  />
                </ColorPicker>
              </div>
              <div className="right-area">
                <label style={{ fontSize: 10 }}>Pré-visualização:</label>
                <ScreenPreview
                  primary={data?.theme?.primary}
                  secondary={data?.theme?.secondary}
                  loadingColor={data?.theme?.loadingColor}
                />
              </div>
            </div>
          </section>

          <section style={{ marginTop: 25 }}>
            <SectionTitle color={primary} title="Logotipos" />
            <div className="form">
              <>
                <label>Logotipo (para fundos claros):</label>
                {data?.logos?.defaultLogo ? (
                  <ImagePreview width="380px">
                    <img src={data?.logos?.defaultLogo} alt="" />
                    <div>
                      <p>Imagem selecionada</p>
                      <small>{data?.logos?.defaultLogo?.slice(0, 40)}...</small>
                    </div>
                    <IconButton
                      onClick={() =>
                        setData({
                          ...data,
                          logos: { ...data?.logos, defaultLogo: '' },
                        })
                      }>
                      <GrClose size={14} />
                    </IconButton>
                  </ImagePreview>
                ) : (
                  <>
                    <Button
                      outlined
                      style={{ marginTop: 10, marginBottom: 20 }}
                      height="35px"
                      width="380px"
                      loading={loadingUpload}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef.current.click();
                      }}>
                      Carregar imagem...
                    </Button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={(e) => handleImg(e, 'defaultLogo')}
                    />
                  </>
                )}
              </>

              <>
                <label>Logotipo (para fundos escuros):</label>
                {data?.logos?.lightLogo ? (
                  <ImagePreview width="380px">
                    <img
                      src={data?.logos?.lightLogo}
                      alt=""
                      style={{ backgroundColor: primary }}
                    />
                    <div>
                      <p>Imagem selecionada</p>
                      <small>{data?.logos?.lightLogo?.slice(0, 40)}...</small>
                    </div>
                    <IconButton
                      onClick={() =>
                        setData({
                          ...data,
                          logos: { ...data?.logos, lightLogo: '' },
                        })
                      }>
                      <GrClose size={14} />
                    </IconButton>
                  </ImagePreview>
                ) : (
                  <>
                    <Button
                      outlined
                      style={{ marginTop: 10, marginBottom: 20 }}
                      height="35px"
                      width="380px"
                      loading={loadingUpload}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef2.current.click();
                      }}>
                      Carregar imagem...
                    </Button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: 'none' }}
                      ref={fileInputRef2}
                      onChange={(e) => handleImg(e, 'lightLogo')}
                    />
                  </>
                )}
              </>

              <>
                <label>Logotipo (apenas ícone):</label>
                {data?.logos?.iconLogo ? (
                  <ImagePreview width="380px">
                    <img src={data?.logos?.iconLogo} alt="" />
                    <div>
                      <p>Imagem selecionada</p>
                      <small>{data?.logos?.iconLogo?.slice(0, 40)}...</small>
                    </div>
                    <IconButton
                      onClick={() =>
                        setData({
                          ...data,
                          logos: { ...data?.logos, iconLogo: '' },
                        })
                      }>
                      <GrClose size={14} />
                    </IconButton>
                  </ImagePreview>
                ) : (
                  <>
                    <Button
                      outlined
                      style={{ marginTop: 10, marginBottom: 20 }}
                      height="35px"
                      width="380px"
                      loading={loadingUpload}
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef3.current.click();
                      }}>
                      Carregar imagem...
                    </Button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: 'none' }}
                      ref={fileInputRef3}
                      onChange={(e) => handleImg(e, 'iconLogo')}
                    />
                  </>
                )}
              </>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Create;
