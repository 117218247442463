import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.3s;

  .item {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 50%;
    }

    p {
      font-family: 'Inter', sans-serif;
      text-align: center;
      transition: all ease-in-out 0.3s;
      color: #535d76;
      font-size: 14px;
      min-height: 70px;
    }
  }

  .bullets {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px;
  }

  .buttons {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.justify || 'space-between'};
    margin-top: 25px;

    p {
      cursor: pointer;
      font-size: 12px;
      transition: all ease-in-out 0.3s;
      color: #a2a2a2;
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Bullet = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: ${(props) => props.color || '#eee'};
  margin-right: 15px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
`;
