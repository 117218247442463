import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: ${(props) => (props.collapsed ? '60px' : '290px')};
  background: ${(props) => props.color};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: ${(props) => (props.collapsed ? '30px 0px' : '30px 20px')};
  transition: all linear 0.3s;
  position: relative;
  p {
    margin: 0;
  }

  @media (max-width: 1000px) {
    width: 50px;
    /* padding: 30px 0px; */
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'space-between')};
  align-items: center;
  color: #fff;
  width: 80%;

  .menu-profile {
    display: flex;
    flex-direction: row;
    align-items: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
    justify-content: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
    margin: 0px;
    margin-top: ${(props) => (props.collapsed ? '0px' : '10px')};
    margin-bottom: ${(props) => (props.collapsed ? '10px' : '5px')};
    padding: 0px;
    width: 100%;
    padding-top: ${(props) => !props.collapsed && '20px'};
    padding-bottom: ${(props) => !props.collapsed && '20px'};
    /* border-top: ${(props) =>
      !props.collapsed && `1px solid rgba(255, 255, 255, 0.1)`};
    border-bottom: ${(props) =>
      !props.collapsed && `1px solid rgba(255, 255, 255, 0.1)`}; */
    transition: all ease-in-out 0.3s;

    img {
      cursor: pointer;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .menu-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;

    p {
      margin: 0;
    }
  }

  .menu-name {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }

  .menu-role {
    color: #fff;
    font-size: 11px;
    font-weight: 300;
  }

  @media (max-width: 1000px) {
    justify-content: center;
    p {
      display: none;
    }
    .menu-button {
      display: none;
    }
  }
`;

export const LogoImage = styled.img`
  height: ${(props) => (props.collapsed ? '25px' : '20px')};
  width: ${(props) => (props.collapsed ? '25px' : 'auto')};
  margin-bottom: 20px;
  display: ${(props) =>
    props.variant === 'small' && !props.collapsed && 'none'};

  display: ${(props) =>
    props.variant === 'fullClear' && props.collapsed && 'none'};
  object-fit: contain;
  cursor: pointer;
`;

export const MenuBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.collapsed ? 'center' : 'space-between')};
  justify-content: flex-start;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.collapsed ? 'center' : 'flex-start')};
  align-items: center;
  height: 35px;
  width: ${(props) => (props.collapsed ? '35px' : '100%')};
  padding: ${(props) => (props.collapsed ? '0px' : '0px 20px')};
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
  background: ${(props) => props.selected && 'rgba(255, 255, 255, 0.2)'};
  text-decoration: none;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  @media (max-width: 1000px) {
    justify-content: center;
    width: 35px;
    padding: 0px;
  }
`;

export const MenuText = styled.p`
  display: ${(props) => (props.collapsed ? 'none' : 'flex')};
  padding-left: 15px;
  color: #fff;
  white-space: nowrap;
  transition: all ease-in-out 0.3s;
  left: 0;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const MenuFooter = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.collapsed ? 'column' : 'row-reverse')};
  justify-content: ${(props) => (props.collapsed ? 'center' : 'space-between')};
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  position: absolute;
  bottom: 10px;

  @media (max-width: 1000px) {
    justify-content: center;
    p {
      display: none;
    }
    .menu-button {
      display: none;
    }
  }
`;
