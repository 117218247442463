/* eslint-disable jsx-a11y/alt-text */
import moment from 'moment';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Tipo',
    selector: (row) => (row?.id_company ? 'Privado' : 'Público'),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row?.name,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row?.description,
    width: '25%',
    sortable: true,
  },
  {
    name: 'Permissões',
    selector: (row) => row.features?.length,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
];
