/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Routes from './routes';
import { GlobalStyle } from 'styles/globalStyle';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'contexts/ThemeContext';
import { AuthProvider } from 'contexts/AuthContext';
import { UserProvider } from 'contexts/UserContext';
import { PermissionProvider } from 'contexts/PermissionContext';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <div className="App">
      {
        <AuthProvider>
          <UserProvider>
            <ThemeProvider>
              <PermissionProvider>
                <ToastContainer
                  theme="light"
                  position="top-right"
                  hideProgressBar
                  draggable
                  newestOnTop
                />
                <GlobalStyle />
                <Routes />
              </PermissionProvider>
            </ThemeProvider>
          </UserProvider>
        </AuthProvider>
      }
    </div>
  );
};

export default App;
