import React from 'react';
import './style.css'
import logoImg from 'assets/logo-symbol.png'

function WhatsApp({ helpText, picture }) {

  return (<>
    <div class="page">
      <div class="marvel-device nexus5">
        <div class="top-bar"></div>
        <div class="sleep"></div>
        <div class="volume"></div>
        <div class="camera"></div>
        <div class="screen">
          <div class="screen-container">
            <div class="status-bar">
              <div class="time"></div>
              <div class="battery">
                <i class="zmdi zmdi-battery"></i>
              </div>
              <div class="network">
                <i class="zmdi zmdi-network"></i>
              </div>
              <div class="wifi">
                <i class="zmdi zmdi-wifi-alt-2"></i>
              </div>
              <div class="star">
                <i class="zmdi zmdi-star"></i>
              </div>
            </div>
            <div class="chat">
              <div class="chat-container">
                <div class="user-bar">
                  <div class="back">
                    <i class="zmdi zmdi-arrow-left"></i>
                  </div>
                  <div class="avatar">
                    <img src={logoImg} alt="Avatar" />
                  </div>
                  <div class="name">
                    <span>Vistei</span>
                    <span class="status">online</span>
                  </div>
                  <div class="actions more">
                    <i class="zmdi zmdi-more-vert"></i>
                  </div>
                  <div class="actions attachment">
                    <i class="zmdi zmdi-attachment-alt"></i>
                  </div>
                  <div class="actions">
                    <i class="zmdi zmdi-phone"></i>
                  </div>
                </div>
                <div class="conversation">
                  <div class="conversation-container">
                    <div class="message received">
                      <img class="message image" src={picture} alt="" /> <br />
                      <span id="random">{helpText}</span>
                      <span class="metadata"><span class="time"></span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export default WhatsApp;