import styled from 'styled-components';
const inputColor = '#f5f9fd';

export const StyledSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;
  background-color: ${inputColor};
  margin: 5px 0px 10px 0px;
  padding-left: 10px;
  box-sizing: border-box;
  font-size: 13px;
  border-radius: ${(props) => props.radius || '5px'};
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width || '300px'};

  select {
    height: 100%;
    width: 95%;
    border: none;
    outline: none;
    color: #868686;
    font-size: 12px;
    background-color: transparent;
    &:focus {
      color: ${(props) => props.color};
    }
  }
`;

export const StyledLabel = styled.span`
  font-size: 11px;
  color: #121212;
  margin: 0px;
  margin-left: 2px;
  margin-bottom: 5px;
  width: 100%;
  text-align: left;
`;
