import styled from 'styled-components';

export const StyledSectionTitle = styled.h3`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.color};
  border-bottom: ${(props) => props.border && '1px solid #f7f7f7'};
  padding-bottom: ${(props) => props.border && '10px'};
`;
