import React from 'react';
import { StyledSelect, StyledLabel } from './styles';

function Input({ children, height, width, label, color, ...props }) {
  return (
    <>
      {label && <StyledLabel>{`${label}:`}</StyledLabel>}
      <StyledSelect height={height} width={width} {...props}>
        <select color={color} {...props}>
          {children}
        </select>
      </StyledSelect>
    </>
  );
}

export default Input;
