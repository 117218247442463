/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import defaultPicture from 'assets/picture-icon.png';
import ThemeContext from 'contexts/ThemeContext';
import { Container, ButtonFake } from './styles';

function PhotoTypePreview({ description, helpText, picture, ...props }) {
  const { primary } = useContext(ThemeContext);

  return (
    <Container {...props}>
      <h1 className="title">{description || 'Descrição'}</h1>
      <img className="picture" src={picture || defaultPicture} />
      <p className="help">{helpText || 'Texto de ajuda'}</p>
      <ButtonFake color={primary}>Tirar foto</ButtonFake>
    </Container>
  );
}

export default PhotoTypePreview;
