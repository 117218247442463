/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';

import { SideMenu, SectionTitle, Input, Button } from 'components';
import { Container, ContentArea, TitleArea } from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';

function Update({ history, location }) {
  const dataId = parseInt(location.pathname.split('/tipos-objetos/')[1]);

  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [data, setData] = useState();
  const { primary, loadingColor } = useContext(ThemeContext);

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/object-type/${dataId}`);
    setData(resp?.data);
    setLoading(false);
  };

  const handleUpdateObjectType = async () => {
    setLoadingUpdate(true);

    try {
      await api.put(`/object-type/${dataId}`, {
        object_type: data?.object_type,
        identificator: data?.identificator,
      });
      setLoadingUpdate(false);
      toast.success('Alterações realizadas com sucesso!');
      loadData();
    } catch (e) {
      setLoadingUpdate(false);
      toast.error(e?.response?.data?.error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <TitleArea>
            <IconButton onClick={() => history?.goBack()}>
              <BsArrowLeft size={16} />
            </IconButton>
            <h1>Tipo de objeto</h1>
          </TitleArea>

          <section>
            <SectionTitle color={primary} title="Editar informações" />

            <div className="form">
              <Input
                height="35px"
                width="380px"
                label="Tipo de objeto"
                value={data?.object_type}
                onChange={(e) =>
                  setData({
                    ...data,
                    object_type: e.target.value.toUpperCase(),
                  })
                }
              />

              <Input
                height="35px"
                width="380px"
                label="Identificador"
                helpText="Identificador é o tipo de informação única que identificará um objeto vistoriado. Ex.: Para automóveis a placa é um possível identificador."
                value={data?.identificator}
                onChange={(e) =>
                  setData({
                    ...data,
                    identificator: e.target.value?.toUpperCase(),
                  })
                }
              />

              <Button
                loading={loadingUpdate}
                onClick={handleUpdateObjectType}
                height="35px"
                width="380px"
                style={{ marginTop: 30 }}
                disabled={
                  loadingUpdate || !data?.object_type || !data?.identificator
                }>
                Salvar alterações
              </Button>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Update;
