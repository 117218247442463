import React from 'react';
import BaseMenu from '@mui/material/Menu';

function Menu({ children, ...props }) {
  return (
    <BaseMenu
      {...props}
      PaperProps={{
        style: {
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
        },
      }}>
      {children}
    </BaseMenu>
  );
}

export default Menu;
