import { Container, Iframe } from './styles';

function PdfPreview({ fileUrl, width, height, onClick }) {
  return (
    <Container width={width} height={height} onClick={onClick}>
      <Iframe
        src={fileUrl}
        title="Visualizador de PDF"
        width={'100%'}
        height={'100%'}
        onClick={onClick}
      />
    </Container>
  );
}

export default PdfPreview;
