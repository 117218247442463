import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 30px;
  div {
    height: 200px;
    width: 269px;
    background-size: cover;
  }
  h1 {
    font-size: 26px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #fff;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormTitle = styled.h1`
  color: #121212;
  font-size: 24px;
  margin-bottom: 22px;
`;

export const ForgotText = styled.a`
  font-size: 15px;
  color: #949494;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  margin-bottom: 15px;
  margin-top: 15px;
  &&:hover {
    opacity: 0.75;
  }
`;

export const SignupText = styled.p`
  margin-top: 30px;
  font-size: 18px;
  color: #949494;
`;

export const SignupLink = styled.a`
  margin-top: 30px;
  font-size: 17px;
  color: ${(props) => props.color};
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  &&:hover {
    opacity: 0.75;
  }
`;

export const Logo = styled.img`
  width: 160px;
  margin-left: 20px;
`;

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  width: 100%;
  margin: 6px 0px;
  margin-top: 20px;
`;
