import React from 'react';
import moment from 'moment';
import { Container, Icon } from './styles';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { BsClipboardCheck } from 'react-icons/bs';
import { formatInspectionStatus } from 'utils/formatInspection';

function InspectionItem({ inspection }) {
  return (
    <Container>
      <div className="icon-area">
        <Icon color={formatInspectionStatus(inspection?.status)?.color}>
          <BsClipboardCheck color="#fff" />
        </Icon>
      </div>
      <div className="column" style={{ width: '8%' }}>
        <span>Cód. vistoria</span>
        <p>{inspection?.inspection_code}</p>
      </div>
      <div className="column" style={{ width: '16%' }}>
        <span>Cliente</span>
        <p>{inspection?.client_name}</p>
      </div>
      <div className="column">
        <span>Tipo de vistoria</span>
        <p>{inspection?.tb_inspection_type?.inspection_type}</p>
      </div>
      <div className="column" style={{ width: '15%' }}>
        <span>Status</span>
        <p>{formatInspectionStatus(inspection?.status)?.label}</p>
      </div>
      <div className="column" style={{ width: '15%' }}>
        <span>Identificador</span>
        <p>{inspection?.identificator}</p>
      </div>
      <div className="column" style={{ width: '15%' }}>
        <span>Solicitada em</span>
        <p>{moment(inspection?.created_at).format('DD/MM/YYYY HH:mm')}</p>
      </div>
      <div className="column" style={{ width: '10%' }}>
        <span>Realizada em</span>
        <p>
          {inspection?.end_date
            ? moment(inspection?.end_date).format('DD/MM/YYYY HH:mm')
            : '-'}
        </p>
      </div>
      <div className="arrow-area">
        <HiOutlineChevronRight size={15} color="#B6C5DB" />
      </div>
    </Container>
  );
}

export default InspectionItem;
