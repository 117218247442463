/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import ThemeContext from 'contexts/ThemeContext';
import IconButton from '@material-ui/core/IconButton';
import api from 'api';

import { SideMenu, SectionTitle, Input, Button } from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  FeaturesArea,
  PermissionSection,
} from './styles';
import { toast } from 'react-toastify';
import { BsArrowLeft } from 'react-icons/bs';
import { formatRoleCategory } from 'utils/userCategory';

function Create({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [data, setData] = useState({ features: [] });
  const [featuresData, setFeaturesData] = useState();
  const { primary, loadingColor } = useContext(ThemeContext);

  const categories = [
    ...new Set(featuresData?.map((feature) => feature.category)),
  ]
    .filter((i) => i !== 'user')
    .sort();

  const loadData = async () => {
    setLoading(true);
    const resp = await api.get(`/list-features`);
    setFeaturesData(resp.data);
    setLoading(false);
  };

  const handleCreate = async () => {
    setLoadingCreate(true);
    try {
      const resp = await api.post('/role', {
        ...data,
      });
      toast.success('Perfil de acesso criado com sucesso!');
      setLoadingCreate(false);
      history.push(`/perfil-de-acesso/${resp?.data?.id}`);
    } catch (e) {
      toast.error(e?.response?.data?.error);
      setLoading(false);
    }
  };

  const handleChangeFeature = async (feature) => {
    const isChecked = data?.features?.findIndex((i) => i === feature) > -1;
    const currentFeaturesList = [...data?.features];
    if (isChecked) {
      currentFeaturesList.splice(
        currentFeaturesList.findIndex((listItem) => listItem === feature),
        1
      );
      setData({ ...data, features: currentFeaturesList });
    } else {
      currentFeaturesList.push(feature);
      setData({ ...data, features: currentFeaturesList });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <SideMenu />
      {loading && (
        <div className="loading-area">
          <ReactLoading
            type="spin"
            color={loadingColor}
            height={45}
            width={45}
          />
        </div>
      )}
      {!loading && (
        <ContentArea>
          <section>
            <TitleArea>
              <IconButton
                style={{ maerginRight: 5 }}
                onClick={() => history?.goBack()}>
                <BsArrowLeft size={16} />
              </IconButton>
              <h1>Perfil de acesso</h1>
            </TitleArea>
            <div>
              <SectionTitle color={primary} title="Adicionar novo" />

              <div className="form">
                <Input
                  label="Nome"
                  height="35px"
                  width="380px"
                  value={data?.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />

                <Input
                  label="Descrição"
                  height="35px"
                  width="380px"
                  value={data?.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />

                <SectionTitle color={primary} title="Selecionar permissões" />

                <FeaturesArea>
                  {categories?.map((category) => (
                    <PermissionSection>
                      <h3>{formatRoleCategory(category)}</h3>
                      <div className="items-list">
                        {featuresData
                          ?.filter((f) => f.category === category)
                          ?.map((feature) => (
                            <div className="checkbox-item">
                              <input
                                className="label"
                                type="checkbox"
                                id={feature?.id}
                                name={feature?.id}
                                checked={
                                  data?.features?.findIndex(
                                    (i) => i === feature.id
                                  ) > -1
                                }
                                onChange={() =>
                                  handleChangeFeature(feature?.id)
                                }
                              />
                              <div className="label-group">
                                <label for={feature?.id}>
                                  {feature?.name}
                                  <small>{feature?.description}</small>
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </PermissionSection>
                  ))}
                </FeaturesArea>

                <Button
                  style={{ marginTop: 30 }}
                  height="35px"
                  width="380px"
                  loading={loadingCreate}
                  disabled={
                    loadingCreate ||
                    !data?.name ||
                    !data?.description ||
                    data?.features.length < 1
                  }
                  onClick={handleCreate}>
                  Adicionar
                </Button>
              </div>
            </div>
          </section>
        </ContentArea>
      )}
    </Container>
  );
}

export default Create;
