import styled from 'styled-components';

export const StyledSectionTitle = styled.h3`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${props.width}` || '25%'};
  min-width: 250px;
  color: ${(props) => props.color};

  label {
    margin: 0px;
    font-size: 11px;
    font-weight: 500;
    color: #121212;
  }
  span {
    margin: 0px;
    font-size: 14px;
    font-weight: 300;
    color: #424242;
    margin-top: 5px;
  }

  @media (max-width: 1000px) {
    width: ${(props) => `${props.width}` || '100%'};
  }
`;
