import pdfIcon from 'assets/pdf-icon.png';
import videoIcon from 'assets/video-icon.png';

export const formatCapture = (allowUpload) => {
  if (allowUpload === 'true' || allowUpload === true) {
    return 'Capturar ou carregar';
  } else {
    return 'Capturar';
  }
};

export const formatMedia = (mediaType) => {
  if (mediaType === 'picture') return ' foto';
  if (mediaType === 'video') return ' vídeo';
  if (mediaType === 'pdf') return ' PDF';
  if (mediaType === 'picture_pdf') return ' foto/PDF';
};

export const formatMediaIcon = (mediaType, media, url) => {
  if (mediaType === 'picture') return media;
  if (mediaType === 'video') return videoIcon;
  if (mediaType === 'pdf') return pdfIcon;
  if (mediaType === 'picture_pdf')
    return url?.substr(-3) === 'pdf' ? pdfIcon : media;
};
