const lightTheme = {
  pageBackground: '#fafbfe',
  pageListBackground: '#fff',
  pageTitle: '#121212',
  scrollbarBackground: '#fff',
  scrollbarColor: '#f5f5f5',
  widgetBackground: '#fff',
  filterBackground: '#fefefe',
  filterColor: '#121212',
  tableBackground: '#fafbfe',
  tableColor: '#121212',
  toolbarBackground: '#f5f9fd',
  iconBackground: '#f3f6f9',
  iconColor: '#909fac',
  inputColor: '#f5f9fd',
  inputLabel: '#121212',
  inputSearchColor: '#fff',
  filterButtonColor: '#fff',
  exportButtonColor: '#fff',
  chart: {
    color1: '#45aaf2',
    color2: '#a55eea',
    color3: '#fed330',
    color4: '#0fb9b1',
    color5: '#26de81',
    color6: '#26de81',
    color7: '#26de81',
  },
};

const darkTheme = {
  pageBackground: '#121212',
  pageListBackground: '#191919',
  pageTitle: '#f5f5f5',
  scrollbarBackground: '#fff',
  scrollbarColor: '#f5f5f5',
  widgetBackground: '#191919',
  filterBackground: '#191919',
  filterColor: '#fff',
  tableBackground: '#121212',
  tableBackgroundHighlight: '#191919',
  tableColor: '#fafbfe',
  toolbarBackground: '#252525',
  iconBackground: '#303030',
  iconColor: '#707e8a',
  inputLabel: '#eee',
  inputColor: '#252525',
  inputSearchColor: '#121212',
  filterButtonColor: '#121212',
  exportButtonColor: '#121212',
  chart: {
    color1: '#45aaf2',
    color2: '#a55eea',
    color3: '#fed330',
    color4: '#0fb9b1',
    color5: '#26de81',
  },
};

const selectedTheme = 'lightTheme';
export const theme = selectedTheme === 'darkTheme' ? darkTheme : lightTheme;
