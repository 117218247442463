/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import ThemeContext from 'contexts/ThemeContext';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroller';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import api from 'api';
import {
  SideMenu,
  DataTable,
  Input,
  FilterButton,
  Button,
  Select,
  Toolbar,
  ConfirmDialog,
} from 'components';
import {
  Container,
  ContentArea,
  TitleArea,
  DataArea,
  LoadingArea,
  FilterArea,
} from './styles';
import { dataColumns } from './helper';
import { IconButton, Tooltip } from '@material-ui/core';
import { TbTrashX } from 'react-icons/tb';
import { toast } from 'react-toastify';

function List({ history }) {
  const scrollParent = useRef();
  const { loadingColor } = useContext(ThemeContext);

  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showConfirm, setShowConfirm] = useState();

  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState();
  const [status, setStatus] = useState();
  const [role, setRole] = useState();

  const loadData = async () => {
    setLoading(true);
    setDataList([]);
    const resp = await api.post(
      '/users',
      { page: currentPage, take: 20 },
      {
        headers: {
          role,
          status,
          search,
        },
      }
    );
    setDataList(resp?.data?.users);
    setTotalCount(resp?.data?.totalCount);
    setHasNextPage(resp?.data?.hasNextPage);
    setLoading(false);
  };

  const handleLoadMore = async () => {
    const page = currentPage + 1;

    if (hasNextPage) {
      setLoadingMore(true);

      const resp = await api.post(
        '/users',
        { page, take: 10 },
        {
          headers: {
            role,
            status,
            search,
          },
        }
      );

      const data = dataList;
      const ndata = data.concat(resp?.data?.users);

      setCurrentPage(page);
      setDataList(ndata);
      setHasNextPage(resp?.data?.hasNextPage);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const deleteDataColumns = [
    ...dataColumns,
    {
      name: '',
      selector: (row) => (
        <Tooltip
          title="Excluir"
          placement="right"
          onClick={() => setShowConfirm(row)}>
          <IconButton>
            <TbTrashX size={15} />
          </IconButton>
        </Tooltip>
      ),
      width: '10%',
      sortable: true,
    },
  ];

  const renderFilters = () => (
    <SwipeableDrawer
      anchor="right"
      open={showFilters}
      onClose={() => setShowFilters(false)}>
      <FilterArea>
        <h1>Filtros</h1>

        <Select
          label="Perfil"
          width="250px"
          value={role}
          onChange={(e) =>
            setRole(e.target.value === 'all' ? undefined : e.target.value)
          }>
          <option value="all">Todos</option>
          <option value="admin">Administrador</option>
          <option value="user">Usuário</option>
        </Select>

        <Select
          label="Status"
          width="250px"
          value={status}
          onChange={(e) =>
            setStatus(e.target.value === 'all' ? undefined : e.target.value)
          }>
          <option value="all">Todos</option>
          <option value={1}>Ativo</option>
          <option value={0}>Inativo</option>
        </Select>

        <Button
          onClick={() => {
            setShowFilters(false);
            loadData();
          }}
          style={{ marginTop: 20 }}
          width="250px">
          Aplicar
        </Button>
      </FilterArea>
    </SwipeableDrawer>
  );

  const handleDelete = async () => {
    setLoadingDelete(true);

    try {
      await api.delete(`/user/${showConfirm?.id}`);
      setLoadingDelete(false);
      toast.success('Usuário excluído com sucesso!');
      loadData();
    } catch (e) {
      setLoadingDelete(false);
      toast.error(e?.response?.data?.error);
    }
  };

  const renderConfirmDialog = () => (
    <ConfirmDialog
      open={Boolean(showConfirm)}
      onCancel={() => setShowConfirm()}
      content="Tem certeza de que deseja excluir este usuário?"
      title="Excluir usuário"
      loading={loadingDelete}
      onConfirm={() => {
        handleDelete();
        setShowConfirm();
      }}
    />
  );

  return (
    <Container>
      {renderFilters()}
      {renderConfirmDialog()}
      <SideMenu />
      <ContentArea>
        <TitleArea>
          <h1>Usuários</h1>
        </TitleArea>
        <Toolbar>
          <div />
          <div>
            <FilterButton onClick={() => setShowFilters(true)} />
            <Input
              search
              handleClean={() => {
                setSearch();
                loadData();
              }}
              height="32px"
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadData();
                }
                if (e.key === 'Escape') {
                  setSearch();
                  loadData();
                }
              }}
            />
          </div>
        </Toolbar>
        <DataArea ref={scrollParent}>
          <InfiniteScroll
            loadMore={handleLoadMore}
            hasMore={hasNextPage}
            useWindow={false}
            getScrollParent={() => scrollParent?.current}
            initialLoad={false}
            style={{ width: '100%', display: 'flex' }}
            threshold={10}>
            <DataTable
              onRowClicked={(row) => history.push(`/usuarios/${row?.id}`)}
              persistTableHead
              responsive
              columns={deleteDataColumns}
              data={dataList}
              highlightOnHover
              pointerOnHover
              progressPending={loading}
              progressComponent={
                <LoadingArea>
                  <ReactLoading
                    color={loadingColor}
                    height={32}
                    width={32}
                    type="spin"
                  />
                </LoadingArea>
              }
              noDataComponent={
                <small style={{ marginTop: 20 }}>
                  Nenhum resultado encontrado
                </small>
              }
            />
          </InfiniteScroll>
        </DataArea>
        {!loading && !loadingMore && (
          <small className="counter-area">
            Mostrando {dataList?.length} de {totalCount}
          </small>
        )}
        {loadingMore && (
          <LoadingArea>
            <ReactLoading
              type="spin"
              color={loadingColor}
              height={25}
              width={25}
            />
          </LoadingArea>
        )}
      </ContentArea>
    </Container>
  );
}

export default List;
