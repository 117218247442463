/* eslint-disable jsx-a11y/alt-text */
import VideoThumbnail from 'react-video-thumbnail';
import moment from 'moment';
import { PdfPreviewer } from 'components';
import { formatMedia, formatMediaIcon } from 'utils/formatMedia';
import { capitalize } from 'utils/strings';

export const dataColumns = [
  {
    name: '',
    selector: (row) => (
      <>
        {row?.mediatype === 'picture' && (
          <img
            style={{
              height: 25,
              width: 25,
              borderRadius: 3,
              objectFit: 'cover',
            }}
            src={formatMediaIcon(row?.mediatype, row?.picture)}
          />
        )}
        {row?.mediatype === 'video' && (
          <div
            style={{
              height: 25,
              width: 25,
              borderRadius: 3,
              objectFit: 'cover',
            }}>
            <VideoThumbnail videoUrl={row?.picture} width={25} height={25} />
          </div>
        )}
        {row?.mediatype === 'pdf' && (
          <div
            style={{
              height: 25,
              width: 25,
              borderRadius: 3,
              objectFit: 'cover',
            }}>
            <PdfPreviewer fileUrl={row?.picture} />
          </div>
        )}
      </>
    ),
    width: '5%',
    sortable: true,
  },
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row?.name,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Descrição',
    selector: (row) => row?.description,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Tipo de objeto',
    selector: (row) => row?.objectType?.object_type,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Tipo de mídia',
    selector: (row) => capitalize(formatMedia(row?.mediatype).trim()),
    width: '10%',
    sortable: true,
  },
  {
    name: 'Permite upload',
    selector: (row) => (row?.allow_upload ? 'Sim' : 'Não'),
    width: '15%',
    sortable: true,
  },
  {
    name: 'Checklists',
    selector: (row) => row?.checklists?.length,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '10%',
    sortable: true,
  },
];
