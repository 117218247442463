/* eslint-disable jsx-a11y/alt-text */
import moment from 'moment';

export const dataColumns = [
  {
    name: 'ID',
    selector: (row) => row?.id,
    width: '5%',
    sortable: true,
  },
  {
    name: 'Nome',
    selector: (row) => row?.inspection_type,
    width: '20%',
    sortable: true,
  },
  {
    name: 'Tipo de objeto',
    selector: (row) => row?.object_type.object_type,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Mídias',
    selector: (row) => row?.photos?.length,
    width: '10%',
    sortable: true,
  },
  {
    name: 'Tempo médio vistoria',
    selector: (row) =>
      `${row?.photos?.length * 2} ${
        row?.photos?.length * 2 > 0 ? ' minutos' : ' minuto'
      }`,
    width: '15%',
    sortable: true,
  },
  {
    name: 'Data cadastro',
    selector: (row) => moment(row?.created_at).format('DD/MM/YYYY HH:mm'),
    width: '25%',
    sortable: true,
  },
];
